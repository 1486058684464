import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonDefaults } from '@windsim/core/configs';
import { BasicRoutes } from '@windsim/core/enums';
import { ProjectAccessPolicy } from '@windsim/core/models';
import { AuthenticationService, AuthorizationService, LoggingService, StorageService } from '@windsim/core/services';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserProjectRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly authorizationService: AuthorizationService,
    private readonly loggingService: LoggingService,
    private readonly storageService: StorageService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const projectId = route.paramMap.get('projectId');
    const clientId = this.storageService.getGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY);

    if (projectId && clientId) {
      this.authorizationService.projectPartitionKey = { clientId, projectId };
      return this.authorizationService.userProjectPolicy.pipe(
        take(1),
        map((policy: ProjectAccessPolicy) => {
          return policy.viewProject;
        }),
        first(),
      );
    }
    this.router
      .navigate([BasicRoutes.Login], { queryParams: { returnUrl: state.url } })
      .then()
      .catch((err) => this.loggingService.consoleLogError(err));
    this.authenticationService
      .logout()
      .then()
      .catch((err) => this.loggingService.consoleLogError(err));
    return false;
  }
}
