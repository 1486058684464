import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PostmanCollection, PostmanCollectionInfo, PostmanCollectionItem } from '@windsim-core/models';
import { CommonDefaults } from '@windsim/core/configs';
import { RequestMethod } from '@windsim/core/enums/request-method.enum';
import { StorageService } from '@windsim/core/services/storage.service';
import { UtilitiesService } from '@windsim/core/services/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class DevToolsService {
  constructor(private router: Router, private readonly storageService: StorageService) {}

  public createPostmanCollection(collectionInfo: PostmanCollectionInfo, collectionItems: PostmanCollectionItem[]): PostmanCollection {
    const info = collectionInfo;
    // eslint-disable-next-line no-underscore-dangle
    info._postman_id = UtilitiesService.generateGuid();
    info.schema = 'https://schema.getpostman.com/json/collection/v2.1.0/collection.json';

    return {
      info,
      item: collectionItems,
    };
  }

  public createPostmanCollectionItem(
    name: string,
    endpoint: string,
    rawPayload: string,
    requestMethod: RequestMethod,
  ): PostmanCollectionItem {
    const url = new URL(endpoint);
    const path = url.pathname.split('/').filter((p) => p !== '');
    const protocol = url.protocol.replace(':', '');
    const standardPort = protocol === 'https' ? '443' : '80';
    const port = url.port === '' ? standardPort : url.port;
    const token = this.storageService.getGlobalValue(CommonDefaults.TOKEN_STORAGE_KEY);
    return {
      name,
      request: {
        auth: {
          type: 'bearer',
          bearer: [
            {
              key: 'token',
              value: token,
              type: 'string',
            },
          ],
        },
        method: requestMethod,
        header: [],
        body: {
          mode: 'raw',
          raw: rawPayload,
          options: {
            raw: {
              language: 'json',
            },
          },
        },
        url: {
          raw: url.origin,
          protocol: url.protocol.replace(':', ''),
          host: [url.hostname],
          port,
          path,
        },
      },
      response: undefined,
    };
  }
}
