import { Injectable } from '@angular/core';
import { GeoFileHeader, GeoFilePart, GeoFileParts, GeoFilePointers } from '@windsim-model/models/geo-file.model';
import { MeshData, Node, Polygon } from '@windsim-model/models/mesh-data.model';
import { ModelFilesService } from '@windsim-model/services/model-files.service';
import { ProjectPartitionKey } from '@windsim/core/models';
import { ArrayService } from '@windsim/core/services/array.service';
import { UtilitiesService } from '@windsim/core/services/utilities.service';
import { map } from 'rxjs/operators';
import { Vector3 } from 'three';

@Injectable({
  providedIn: 'root',
})
export class BaseLoaderService {
  constructor(
    protected arrayService: ArrayService,
    protected utilitiesService: UtilitiesService,
    protected modelFilesService: ModelFilesService,
  ) {}

  private static getPointers(header: GeoFileHeader): GeoFilePointers {
    const nodesLines = Math.ceil((header.numberOfNodes * 3) / 6);
    const polygonLines = Math.ceil(header.numberOfConnections / 10);

    return {
      nodesDataStartLine: 3,
      nodesDataEndLine: nodesLines + 2,
      nodesLines,
      polygonLines,
      polygonsDataStartLine: nodesLines + 3,
      polygonsDataEndLine: nodesLines + polygonLines + 2,
    };
  }

  public static joinNodesWithScala(nodes: Node[], scala: number[]) {
    nodes.forEach((node: Node, i: number) => {
      node.z = scala[i];
    });
  }

  private static getNodesChunk(nodes, pointer): Node[] {
    const temp: Node[] = [];
    temp.push(
      { x: nodes[pointer], y: nodes[pointer + 1], z: nodes[pointer + 2] },
      { x: nodes[pointer + 3], y: nodes[pointer + 4], z: nodes[pointer + 5] },
      { x: nodes[pointer + 6], y: nodes[pointer + 7], z: nodes[pointer + 8] },
      { x: nodes[pointer + 9], y: nodes[pointer + 10], z: nodes[pointer + 11] },
      { x: nodes[pointer + 12], y: nodes[pointer + 13], z: nodes[pointer + 14] },
      { x: nodes[pointer + 15], y: nodes[pointer + 16], z: nodes[pointer + 17] },
      { x: nodes[pointer + 18], y: nodes[pointer + 19], z: nodes[pointer + 20] },
      { x: nodes[pointer + 21], y: nodes[pointer + 22], z: nodes[pointer + 23] },
      { x: nodes[pointer + 24], y: nodes[pointer + 25], z: nodes[pointer + 26] },
      { x: nodes[pointer + 27], y: nodes[pointer + 28], z: nodes[pointer + 29] },

      { x: nodes[pointer + 30], y: nodes[pointer + 31], z: nodes[pointer + 32] },
      { x: nodes[pointer + 33], y: nodes[pointer + 34], z: nodes[pointer + 35] },
      { x: nodes[pointer + 36], y: nodes[pointer + 37], z: nodes[pointer + 38] },
      { x: nodes[pointer + 39], y: nodes[pointer + 40], z: nodes[pointer + 41] },
      { x: nodes[pointer + 42], y: nodes[pointer + 43], z: nodes[pointer + 44] },
      { x: nodes[pointer + 45], y: nodes[pointer + 46], z: nodes[pointer + 47] },
      { x: nodes[pointer + 48], y: nodes[pointer + 49], z: nodes[pointer + 50] },
      { x: nodes[pointer + 51], y: nodes[pointer + 52], z: nodes[pointer + 53] },
      { x: nodes[pointer + 54], y: nodes[pointer + 55], z: nodes[pointer + 56] },
      { x: nodes[pointer + 57], y: nodes[pointer + 58], z: nodes[pointer + 59] },

      { x: nodes[pointer + 60], y: nodes[pointer + 61], z: nodes[pointer + 62] },
      { x: nodes[pointer + 63], y: nodes[pointer + 64], z: nodes[pointer + 65] },
      { x: nodes[pointer + 66], y: nodes[pointer + 67], z: nodes[pointer + 68] },
      { x: nodes[pointer + 69], y: nodes[pointer + 70], z: nodes[pointer + 71] },
      { x: nodes[pointer + 72], y: nodes[pointer + 73], z: nodes[pointer + 74] },
      { x: nodes[pointer + 75], y: nodes[pointer + 76], z: nodes[pointer + 77] },
      { x: nodes[pointer + 78], y: nodes[pointer + 79], z: nodes[pointer + 80] },
      { x: nodes[pointer + 81], y: nodes[pointer + 82], z: nodes[pointer + 83] },
      { x: nodes[pointer + 84], y: nodes[pointer + 85], z: nodes[pointer + 86] },
      { x: nodes[pointer + 87], y: nodes[pointer + 88], z: nodes[pointer + 89] },

      { x: nodes[pointer + 90], y: nodes[pointer + 91], z: nodes[pointer + 92] },
      { x: nodes[pointer + 93], y: nodes[pointer + 94], z: nodes[pointer + 95] },
      { x: nodes[pointer + 96], y: nodes[pointer + 97], z: nodes[pointer + 98] },
      { x: nodes[pointer + 99], y: nodes[pointer + 100], z: nodes[pointer + 101] },
      { x: nodes[pointer + 102], y: nodes[pointer + 103], z: nodes[pointer + 104] },
      { x: nodes[pointer + 105], y: nodes[pointer + 106], z: nodes[pointer + 107] },
      { x: nodes[pointer + 108], y: nodes[pointer + 109], z: nodes[pointer + 110] },
      { x: nodes[pointer + 111], y: nodes[pointer + 112], z: nodes[pointer + 113] },
      { x: nodes[pointer + 114], y: nodes[pointer + 115], z: nodes[pointer + 116] },
      { x: nodes[pointer + 117], y: nodes[pointer + 118], z: nodes[pointer + 119] },
    );
    return temp;
  }

  private static getPolygons(lines: string[], pointers: GeoFilePointers): Polygon[] {
    const polygonsLines = lines.slice(pointers.polygonsDataStartLine - 1, pointers.polygonsDataEndLine);
    const polygons = polygonsLines.join().trim().split(/\s+/);

    let tmp: number[] = [];
    const polygonsArray: Polygon[] = [];

    for (let i = 0; i < polygons.length; i += 4) {
      tmp.push(UtilitiesService.parseFloat(polygons[i]));
      tmp.push(UtilitiesService.parseFloat(polygons[1 + i]));
      tmp.push(UtilitiesService.parseFloat(polygons[2 + i]));
      tmp.push(Math.abs(UtilitiesService.parseFloat(polygons[3 + i])));
      polygonsArray.push(tmp);
      tmp = [];
    }
    return polygonsArray;
  }

  public parseXYElement(logfileContent: string): number[] {
    const lines = logfileContent.split(/\r?\n/);
    const xy: number[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith('antall_elementx (CFD)      =')) {
        xy.push(UtilitiesService.parseFloat(line.split('=')[1].trim()));
      }
      if (line.startsWith('antall_elementy (CFD)      =')) {
        xy.push(UtilitiesService.parseFloat(line.split('=')[1].trim()));
      }
    }
    return xy;
  }

  public parseGeo(geoFileContent: string): GeoFileParts {
    const lines = geoFileContent.split(/\r?\n/);
    const header = this.getHeader(lines);
    const parts = this.getParts(lines);
    const pointers = BaseLoaderService.getPointers(header);
    const nodes = this.getNodes(lines, pointers);
    const polygons = BaseLoaderService.getPolygons(lines, pointers);

    return {
      header,
      parts,
      pointers,
      nodes,
      connections: polygons,
    };
  }

  private getHeader(file: string[]): GeoFileHeader {
    const header = file[0].trim();
    const headerValues = header.split(/\s+/).map((v) => UtilitiesService.parseFloat(v));

    return {
      numberOfParts: headerValues[0],
      numberOfNodes: headerValues[1],
      numberOfPolygons: headerValues[2],
      numberOfConnections: headerValues[3],
    };
  }

  private getParts(file: string[]): GeoFilePart[] {
    const parts = file[1].trim();
    const partsValues = parts.split(/\s+/).map((v) => UtilitiesService.parseFloat(v));
    if (partsValues.length > 0) {
      const geoFileParts: GeoFilePart[] = [];
      for (let i = 0; i < partsValues.length; i += 2) {
        geoFileParts.push({ begin: partsValues[i], end: partsValues[i + 1] });
      }
      return geoFileParts;
    }
    return [];
  }

  spliceIntoChunks(inputArray: number[], chunkSize): Vector3[] {
    const output = [];
    while (inputArray.length > 0) {
      const chunk = inputArray.splice(0, chunkSize);
      const vector = new Vector3(chunk[0], chunk[1], chunk[2]);
      output.push(vector);
    }
    return output;
  }

  private getNodes(lines: string[], pointers: GeoFilePointers): Node[] {
    const arr = lines.slice(2, pointers.nodesDataEndLine);
    const nodes: Node[] = [];

    const t = arr
      .join()
      .trim()
      .split(/\s+/)
      .map((v) => UtilitiesService.parseExponential(v));

    const chunkSize = 40;
    const numberOfNodeValues = 3;
    const oddChunks = (t.length / numberOfNodeValues) % chunkSize > 0;

    const iterations = oddChunks ? Math.floor(t.length / numberOfNodeValues / chunkSize) : t.length / numberOfNodeValues / chunkSize;

    for (let i = 0; i < iterations; i++) {
      const chunkPointer = i * chunkSize * numberOfNodeValues;
      const chunk = BaseLoaderService.getNodesChunk(t, chunkPointer);
      nodes.push(...chunk);
    }
    if (oddChunks) {
      const pointer = iterations * numberOfNodeValues * chunkSize;
      for (let i = pointer; i < t.length; i += 3) {
        nodes.push({ x: t[i], y: t[i + 1], z: t[i + 2] });
      }
    }
    return nodes;
  }

  // Scalar

  public parseScalar(data: string): number[] {
    const parsedData = data.replace(/(\r\n|\n|\r)/gm, '');
    const scalaValues = parsedData.match(/.{1,12}/g);
    return scalaValues.map((v) => UtilitiesService.parseExponential(v));
  }

  public async parseDtmLog(projectPartitionKey: ProjectPartitionKey, logFileName: string): Promise<number[]> {
    const getOutputFile$ = this.modelFilesService.getOutputFile(projectPartitionKey, logFileName);
    return getOutputFile$
      .pipe(
        map((textResult) => {
          return this.parseXYElement(textResult);
        }),
      )
      .toPromise();
  }

  public async createMeshData(projectPartitionKey: ProjectPartitionKey, geoFileName: string, scalaFileName?: string): Promise<MeshData> {
    const readGeoFile$ = this.readGeoFile(projectPartitionKey, geoFileName);

    if (scalaFileName) {
      const scala = this.readScalarFile(projectPartitionKey, scalaFileName).then((s) => {
        return s;
      });
      return readGeoFile$.then((geoFileContent) => {
        return scala.then((scalaFileContent) => {
          BaseLoaderService.joinNodesWithScala(geoFileContent.nodes, scalaFileContent);
          return {
            header: geoFileContent.header,
            parts: geoFileContent.parts,
            nodes: geoFileContent.nodes,
            connections: geoFileContent.connections,
            nodesValues: scalaFileContent,
            minValue: this.arrayService.getMinValue(scalaFileContent),
            maxValue: this.arrayService.getMaxValue(scalaFileContent),
          };
        });
      });
    }
    return readGeoFile$.then((geoFileContent) => {
      return {
        header: geoFileContent.header,
        parts: geoFileContent.parts,
        nodes: geoFileContent.nodes,
        connections: geoFileContent.connections,
        nodesValues: null,
        minValue: 0,
        maxValue: 0,
      };
    });
  }

  public async readGeoFile(projectPartitionKey: ProjectPartitionKey, fileName: string): Promise<GeoFileParts> {
    const getOutputFile$ = this.modelFilesService.getOutputFile(projectPartitionKey, fileName);
    return getOutputFile$
      .pipe(
        map((textResult) => {
          return this.parseGeo(textResult);
        }),
      )
      .toPromise();
  }

  public async readScalarFile(projectPartitionKey: ProjectPartitionKey, fileName: string) {
    const getOutputFile$ = this.modelFilesService.getOutputFile(projectPartitionKey, fileName);
    return getOutputFile$
      .pipe(
        map((textResult) => {
          return this.parseScalar(textResult);
        }),
      )
      .toPromise();
  }
}
