import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonDefaults } from '@windsim-core/configs';
import { ProjectStatus, State, WindsimModule } from '@windsim-core/enums';
import { ApplicationModulesStateService, DataService, StorageService, WindsimWorkflowService } from '@windsim-core/services';
import { RoutesDefaults } from '@windsim/app-routing-defaults';
import { SidenavLink } from '@windsim/core/models/sidenav-link.model';
import { ApplicationStateService } from '@windsim/core/services/application-state.service';
import { BaseComponent } from '@windsim/shared/components/core/base/base.component';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent extends BaseComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dataService: DataService,
    private readonly storageService: StorageService,
    private readonly windsimWorkflowService: WindsimWorkflowService,
    private readonly applicationModulesStateService: ApplicationModulesStateService,
    private readonly applicationStateService: ApplicationStateService,
  ) {
    super();
  }
  projectId: string;
  sourcePath: string;
  clientId: string;
  windsimModule = WindsimModule;

  mapModuleState: State;
  modelModuleState: State;
  simulationModuleState: State;

  private sidenavLinks: BehaviorSubject<SidenavLink[]> = new BehaviorSubject(undefined);
  sidenavLinks$ = this.sidenavLinks.asObservable();

  private static getSourcePath(url: string): string {
    if (url.indexOf(RoutesDefaults.OWN_DATASET) !== -1) {
      return RoutesDefaults.OWN_DATASET;
    }
    if (url.indexOf(RoutesDefaults.ONLINE_RESOURCES) !== -1) {
      return RoutesDefaults.ONLINE_RESOURCES;
    }
    if (url.indexOf(RoutesDefaults.UPLOADED_PROJECT) !== -1) {
      return RoutesDefaults.UPLOADED_PROJECT;
    }
    return '';
  }

  ngOnInit(): void {
    this.clientId = this.storageService.getGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.route.paramMap.subscribe((params) => {
      this.projectId = params.get('projectId');
      this.initSidenav();
      this.subscribeRefreshApplicationModulesStateMessage();
    });
  }

  private initSidenav(): void {
    this.getApplicationModulesState();
    this.updateLinksParams();
    this.populateLinks();
  }

  private subscribeRefreshApplicationModulesStateMessage(): void {
    const { refreshApplicationModulesStateMessage$ } = this.dataService;
    refreshApplicationModulesStateMessage$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((refreshState) => refreshState === true),
        tap(() => {
          this.applicationStateService
            .getOverallApplicationState(this.clientId, this.projectId)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              tap((status: ProjectStatus) => {
                this.applicationStateService.updateProjectStatus(this.projectId, status).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
                this.initSidenav();
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  private updateLinksParams(): void {
    this.sourcePath = this.storageService.getValue(this.clientId, this.projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
    if (!this.sourcePath) {
      this.sourcePath = SidenavComponent.getSourcePath(this.router.url);
    }
  }

  private populateLinks() {
    this.sidenavLinks.next([
      {
        url: `/${this.sourcePath}/map/project`,
        urlParams: this.projectId,
        icon: 'pin',
        text: translationMarker('sidenav.map'),
        isVisible: this.isModuleVisible(this.windsimModule.Map, this.sourcePath),
        moduleState: this.mapModuleState,
      },
      {
        url: `/${this.sourcePath}/model/project`,
        urlParams: this.projectId,
        icon: 'model',
        text: translationMarker('sidenav.model'),
        isVisible: this.isModuleVisible(this.windsimModule.Model, this.sourcePath),
        moduleState: this.modelModuleState,
      },
      {
        url: `/${this.sourcePath}/simulations/project`,
        urlParams: this.projectId,
        icon: 'simulation',
        text: translationMarker('sidenav.wind-fields'),
        isVisible: this.isModuleVisible(this.windsimModule.Simulations, this.sourcePath),
        moduleState: this.simulationModuleState,
      },
      {
        url: `/${this.sourcePath}/files/project`,
        urlParams: this.projectId,
        icon: 'files',
        text: translationMarker('sidenav.files'),
        isVisible: this.isModuleVisible(this.windsimModule.Files, this.sourcePath),
      },
    ]);
  }

  private getApplicationModulesState(): void {
    this.mapModuleState = this.applicationModulesStateService.getApplicationModuleState(this.clientId, this.projectId, WindsimModule.Map);
    this.modelModuleState = this.applicationModulesStateService.getApplicationModuleState(
      this.clientId,
      this.projectId,
      WindsimModule.Model,
    );
    this.simulationModuleState = this.applicationModulesStateService.getApplicationModuleState(
      this.clientId,
      this.projectId,
      WindsimModule.Simulations,
    );
  }

  public isModuleVisible(module: WindsimModule, sourcePath: string): boolean {
    return this.windsimWorkflowService.isModuleInWorkflow(sourcePath, module);
  }
}
