import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpError } from '@windsim/core/models';
import { AuthenticationService, LoggingService } from '@windsim/core/services';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private loggingService: LoggingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpError>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authenticationService
            .logout()
            .then()
            .catch((error) => this.loggingService.consoleLogError(error));
        }
        return throwError({
          statusCode: err.status,
          statusText: err.statusText,
          message: err.message,
          errors: err.error && err.error.errors ? err.error.errors : err.error,
        });
      }),
    );
  }
}
