import { Pipe, PipeTransform } from '@angular/core';
import { CustomerCreditsStats } from '@windsim/core/models';
import moment from 'moment/moment';

@Pipe({
  name: 'subscriptionRenewalDate',
})
export class SubscriptionRenewalDatePipe implements PipeTransform {
  transform(customerCreditsStats: CustomerCreditsStats): string {
    return moment(customerCreditsStats.endDate).format('DD.MM.YYYY');
  }
}
