import { ElementRef, Injectable } from '@angular/core';
import { ModelLegend } from '@windsim/core/models';
import { StorageService } from './storage.service';
import { BufferedLegendItem } from '@windsim-model/models';
import { ShaderType } from '@windsim-model/enums';
import { Colors } from '@windsim-model/configs';

@Injectable({
  providedIn: 'root',
})
export class ModelLegendService {
  constructor(private readonly storageService: StorageService) {}

  public drawLegend(canvas: ElementRef<HTMLCanvasElement>, modelLegend: ModelLegend, positionX = 0, positionY = 0): void {
    if (canvas.nativeElement.getContext) {
      const context = canvas.nativeElement.getContext('2d');
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = 'high';
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      // eslint-disable-next-line no-self-assign
      context.canvas.width = context.canvas.width;
      // eslint-disable-next-line no-self-assign
      context.canvas.height = context.canvas.height;

      if (modelLegend === null) return;

      context.fillStyle = '#000000';
      context.fillRect(positionX + 15, positionY + 10, 22, context.canvas.height - 20);
      let step = positionY + 10;
      modelLegend.colors.reverse().forEach((color) => {
        context.fillStyle = color;
        context.fillRect(positionX + 16, step + 1, 20, 50);
        step += 50;
      });

      step = positionY + 10;
      modelLegend.values.reverse().forEach((value) => {
        context.fillStyle = '#000000';
        context.font = '12px Arial';
        context.fillText(value.toFixed(4).toString(), positionX + 45, step + 5);
        step += 50;
      });

      context.rotate((90 * Math.PI) / 180);
      context.font = '12px Arial';
      context.fillText(modelLegend.name, 10, positionY);
    }
  }

  public clearLegend(canvas: ElementRef<HTMLCanvasElement>): void {
    if (!canvas.nativeElement.getContext) {
      return;
    }
    const context = canvas.nativeElement.getContext('2d');
    // eslint-disable-next-line no-self-assign
    context.canvas.width = context.canvas.width;
    // eslint-disable-next-line no-self-assign
    context.canvas.height = context.canvas.height;
  }

  public createLegendModel(layerItem: BufferedLegendItem): ModelLegend {
    if (!layerItem.hasLegend) return null;
    let colors: Array<string>;

    switch (layerItem.shader) {
      case ShaderType.WireframeShader:
        break;
      case ShaderType.ElevationLayerShader:
      case ShaderType.ExtensionLayerShader:
        colors = [...Colors.elevationColors8];
        break;
      case ShaderType.RoughnessLayerShader:
      case ShaderType.RoughnessLogLayerShader:
      case ShaderType.InclinationLayerShader:
      case ShaderType.SecondOrderDerivativeLayerShader:
      case ShaderType.DeltaElevationLayerShader:
        colors = [...Colors.roughnessColors8];
        break;
      default:
        colors = [...Colors.elevationColors8];
        break;
    }
    const legendStep = (layerItem.maxValue - layerItem.minValue) / colors.length;
    const legendValues = [...Array(colors.length + 1)].map((_, i) => layerItem.minValue + i * legendStep);
    return {
      name: layerItem.layerName,
      colors,
      values: legendValues,
    };
  }

  public bufferLegendItemNext(clientId: string, projectId: string, item: BufferedLegendItem, prefix: string = 'layers'): void {
    if (this.storageService.isExist(clientId, projectId, `${prefix}-legend-buffer`)) {
      const bufferedLegend = this.storageService.getValue(clientId, projectId, `${prefix}-legend-buffer`);
      const bufferedLegendDeserialized = JSON.parse(bufferedLegend) as Array<BufferedLegendItem>;
      const legendItemIndex = bufferedLegendDeserialized.findIndex((l) => l.layerName === item.layerName);
      if (legendItemIndex === -1) {
        bufferedLegendDeserialized.push(item);
        this.storageService.storeValue(clientId, projectId, `${prefix}-legend-buffer`, JSON.stringify(bufferedLegendDeserialized));
        return;
      }
    }
    const legendToBuffer = new Array<BufferedLegendItem>();
    legendToBuffer.push(item);
    this.storageService.storeValue(clientId, projectId, `${prefix}-legend-buffer`, JSON.stringify(legendToBuffer));
  }

  public setLegendItemVisibility(clientId: string, projectId: string, layerName: string, isVisible: boolean, prefix: string = 'layers') {
    if (this.storageService.isExist(clientId, projectId, `${prefix}-legend-buffer`)) {
      const bufferedLegend = this.storageService.getValue(clientId, projectId, `${prefix}-legend-buffer`);
      const bufferedLegendDeserialized = JSON.parse(bufferedLegend) as Array<BufferedLegendItem>;
      const legendItemIndex = bufferedLegendDeserialized.findIndex((l) => l.layerName === layerName);
      bufferedLegendDeserialized[legendItemIndex].isVisible = isVisible;
      this.storageService.storeValue(clientId, projectId, `${prefix}-legend-buffer`, JSON.stringify(bufferedLegendDeserialized));
    }
  }

  public getLegendFromBuffer(clientId: string, projectId: string, layerName: string, prefix: string = 'layers'): BufferedLegendItem {
    const bufferedLegend = this.storageService.getValue(clientId, projectId, `${prefix}-legend-buffer`);
    const bufferedLegendDeserialized = JSON.parse(bufferedLegend) as Array<BufferedLegendItem>;
    return bufferedLegendDeserialized.find((lbi) => lbi.layerName === layerName);
  }
}
