export enum CoreModule {
  None,
  Terrain,
  Windfields,
  Objects,
  Results,
  WindResources,
  Energy,
  Exports = 7,
}
