import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-container',
  templateUrl: './loader-container.component.html',
  styleUrls: ['./loader-container.component.scss'],
})
export class LoaderContainerComponent {
  @Input() isLoading: boolean;
  @Input() showContentWhenLoading = false;
  @Input() extraCssClass: string;

  public get isContentVisible(): boolean {
    return this.showContentWhenLoading || (!this.isLoading && !this.showContentWhenLoading);
  }
}
