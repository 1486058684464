import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Alert } from '@windsim-core/models';
import { AlertService } from '@windsim-core/services';
import { BaseComponent } from '@windsim-shared/core';
import { CommonDefaults } from '@windsim/core/configs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent extends BaseComponent implements OnInit {
  @Input() id = CommonDefaults.ALERT_ID;
  alerts: Alert[] = [];

  constructor(private readonly router: Router, private readonly alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.subscribeAddAlertMessage();
    this.subscribeClearAlertsMessage();
    this.clearAlertsOnLocationChange();
  }

  private subscribeAddAlertMessage(): void {
    const { addAlertMessage$ } = this.alertService;
    addAlertMessage$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((alert: Alert) => alert && alert.id === this.id),
        tap((alert) => {
          this.addAlert(alert);
        }),
      )
      .subscribe();
  }

  private subscribeClearAlertsMessage(): void {
    const { clearAlertsMessage$ } = this.alertService;
    clearAlertsMessage$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((clearAlerts) => clearAlerts === true),
        tap(() => {
          this.alerts = [];
        }),
      )
      .subscribe();
  }

  private clearAlertsOnLocationChange(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertService.clear();
      }
    });
  }

  private isAlertExists(alert: Alert): boolean {
    return !!this.alerts.find((el) => el.message === alert.message);
  }

  private addAlert(alert: Alert): void {
    if (!alert.message) {
      return;
    }
    if (!this.isAlertExists(alert)) {
      this.alerts.push(alert);
    }
  }

  public removeAlert(alert: Alert): void {
    if (!this.isAlertExists(alert)) {
      return;
    }

    this.alerts = this.alerts.filter((el) => el.message !== alert.message);
  }
}
