import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ShareFileClient, ShareServiceClient } from '@azure/storage-file-share';
import { APP_CONFIG, AppConfig, Directory, File, FileSasToken, UploadFile } from '@windsim/core/models';
import { LoggingService } from '@windsim/core/services/logging.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilesSharesService {
  private config: AppConfig;
  private uploadFilesProgressStatusCounter = new BehaviorSubject<number>(0);
  uploadFilesProgressStatusCounter$ = this.uploadFilesProgressStatusCounter.asObservable();

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly httpClient: HttpClient,
    private readonly validationService: ValidationService,
    private readonly loggingService: LoggingService,
  ) {
    this.config = config;
  }

  private static removeJobsFolders(files: FileResponse[]): FileResponse[] {
    return files.filter((f) => !f.fileName.startsWith('/') && !f.fileName.includes('/project.zip') && !f.fileName.startsWith('Layout'));
  }

  private createDirectoryStructure(fileResponses: FileResponse[]): Directory[] {
    const directories: Directory[] = [];
    let files: File[] = [];
    let directoryNameCursor = '';

    fileResponses.forEach((fileResponse) => {
      const split = fileResponse.fileName.split('/');
      const directoryName = split[0];
      const fileName = split[split.length - 1];
      if (directoryName !== directoryNameCursor) {
        const file: File = {
          fileName,
          path: fileResponse.fileName,
          sasToken: fileResponse.fileSASTokenUri,
        };
        files.push(file);
        const directory: Directory = {
          name: directoryName,
          files,
        };
        directories.push(directory);
        directoryNameCursor = directoryName;
        files = [];
      } else {
        const directory = directories.find((d) => d.name === directoryNameCursor);
        const file: File = { fileName, path: fileResponse.fileName, sasToken: fileResponse.fileSASTokenUri };
        directory.files.push(file);
      }
    });
    return directories;
  }

  private parseSasToken(sasToken: string): FileSasToken {
    const stripedToken = sasToken.replace(`${this.config.fileStorageUri}/`, '');
    const tokenSplit = stripedToken.split('?');
    const tokenParts = tokenSplit[0].split('/');
    const shareName = tokenParts[tokenParts.length - 1];

    return {
      storageUri: this.config.fileStorageUri,
      storageAccessToken: tokenSplit[1],
      shareName,
    };
  }

  public extractSubdirectory(path: string): string {
    const splitPath = path.split('/');
    splitPath.pop();
    splitPath.shift();
    return splitPath.join('/');
  }

  public async downloadFile(sasToken: string): Promise<Blob> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');

    try {
      const shareFileClient = new ShareFileClient(sasToken);
      const file = await shareFileClient.download(0);
      return await file.blobBody;
    } catch {
      throw new Error('Cannot download a file.');
    }
  }

  public getOutputFilesList(projectId: string): Observable<Directory[]> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const dto = {
      projectId,
    };
    return this.httpClient.post(`${this.config.coreApiUrl}/${this.config.projectEndpoints.listOutputFiles}`, dto).pipe(
      catchError((error) => {
        this.loggingService.consoleLogError(error);
        return of(false);
      }),
      map((outputFilesList) => {
        if (outputFilesList) {
          let outputFilesListTb = outputFilesList as FileResponse[];
          outputFilesListTb = FilesSharesService.removeJobsFolders(outputFilesListTb);
          return this.createDirectoryStructure(outputFilesListTb);
        }
        return [];
      }),
    );
  }

  public getOutputFilesFlatList(projectId: string): Observable<FileResponse[]> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const dto = {
      projectId,
    };
    return this.httpClient.post(`${this.config.coreApiUrl}/${this.config.projectEndpoints.listOutputFiles}`, dto).pipe(
      catchError((error) => {
        this.loggingService.consoleLogError(error);
        return of(false);
      }),
      map((outputFilesList) => {
        return outputFilesList as FileResponse[];
      }),
    );
  }

  public getInputFilesList(projectId: string): Observable<FileResponse[]> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const dto = {
      projectId,
    };
    return this.httpClient.post(`${this.config.coreApiUrl}/${this.config.projectEndpoints.listOutputFiles}`, dto).pipe(
      map((inputFilesList) => {
        return inputFilesList as FileResponse[];
      }),
    );
  }

  public async cleanUpShare(sasToken: string): Promise<void> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);
    const directoryClient = sharedClient.getDirectoryClient('');
    let fileClient = directoryClient.getFileClient('project1.ws');
    await fileClient.deleteIfExists();
    fileClient = directoryClient.getFileClient('UserSettings.xml');
    await fileClient.deleteIfExists();
  }

  public async uploadFiles(sasToken: string, files: UploadFile[]): Promise<void> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);

    const sizeOfUploadedFiles = files.reduce((sum, { size }) => sum + size, 0);
    let currentUploadedSize = 0;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      let directoryClient = sharedClient.getDirectoryClient('');
      const { name, content, subdirectory, size } = files[i];
      if (subdirectory !== '') {
        const delimiter = '/';
        const nestedFolderArray = subdirectory.split(delimiter);
        // eslint-disable-next-line no-restricted-syntax
        for (const directory of nestedFolderArray) {
          // eslint-disable-next-line no-await-in-loop
          await directoryClient.getDirectoryClient(directory).createIfNotExists();
          directoryClient = directoryClient.getDirectoryClient(directory);
        }
      }

      const fileClient = directoryClient.getFileClient(name);
      // eslint-disable-next-line no-await-in-loop
      await fileClient.create(content.byteLength);
      // eslint-disable-next-line no-await-in-loop
      await fileClient.uploadData(content);

      currentUploadedSize += size;
      this.uploadFilesProgressStatusCounter.next(Math.round((currentUploadedSize / sizeOfUploadedFiles) * 100));
    }
  }

  public async createDirectory(sasToken: string, directoryPath: string): Promise<void> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);
    let directoryClient = sharedClient.getDirectoryClient('');

    const delimiter = '/';
    const nestedFolderArray = directoryPath.split(delimiter);
    // eslint-disable-next-line no-restricted-syntax
    for (const directory of nestedFolderArray) {
      // eslint-disable-next-line no-await-in-loop
      await directoryClient.getDirectoryClient(directory).createIfNotExists();
      directoryClient = directoryClient.getDirectoryClient(directory);
    }
  }

  public async listFilesInDirectory(sasToken: string, directoryPath: string): Promise<string[]> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);
    const directoryClient = sharedClient.getDirectoryClient(directoryPath);
    const simulationFiles = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const entity of directoryClient.listFilesAndDirectories()) {
      if (entity.kind !== 'directory') {
        simulationFiles.push(entity.name);
      }
    }
    return simulationFiles;
  }

  public async downloadFileToBuffer(sasToken: string, directoryPath: string, fileName: string): Promise<ArrayBuffer> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');
    this.validationService.validateStringArgument(directoryPath, 'directoryPath');
    this.validationService.validateStringArgument(fileName, 'fileName');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);
    const directoryClient = sharedClient.getDirectoryClient(directoryPath);
    const fileClient = await directoryClient.getFileClient(fileName);
    const file = await fileClient.download(0);
    const blob = await file.blobBody;
    return blob.arrayBuffer();
  }

  public async downloadFileToText(sasToken: string, directoryPath: string, fileName: string): Promise<string> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');
    this.validationService.validateStringArgument(directoryPath, 'directoryPath');
    this.validationService.validateStringArgument(fileName, 'fileName');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);
    const directoryClient = sharedClient.getDirectoryClient(directoryPath);
    const fileClient = await directoryClient.getFileClient(fileName);
    const file = await fileClient.download(0);
    const blob = await file.blobBody;
    return blob.text();
  }

  public getFilesUploadUri(projectId: string): Observable<string> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(`${this.config.coreApiUrl}/${this.config.desktopCloudHybridProject.getProjectUploadUri}/${projectId}`, {
      headers,
      responseType: 'text',
    });
  }

  public clearUploadFilesProgressStatusCounter() {
    this.uploadFilesProgressStatusCounter.next(0);
  }

  public async deleteFile(sasToken: string, directoryPath: string, fileName: string): Promise<void> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');
    this.validationService.validateStringArgument(directoryPath, 'directoryPath');
    this.validationService.validateStringArgument(fileName, 'fileName');

    const parsedSasToken = this.parseSasToken(sasToken);
    const shareServiceClient = new ShareServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const sharedClient = shareServiceClient.getShareClient(parsedSasToken.shareName);
    const directoryClient = sharedClient.getDirectoryClient(directoryPath);
    const fileClient = directoryClient.getFileClient(fileName);
    await fileClient.deleteIfExists();
  }
}

export interface FileResponse {
  fileName: string;
  fileSASTokenUri: string;
}
