import { InjectionToken } from '@angular/core';

export interface AppConfig {
  production: boolean;
  devMode: boolean;
  environmentName: string;
  googleMapsApiKey: string;
  appInsightsConnectionString: string;
  messageHubUrl: string;
  coreApiUrl: string;
  converterApiUrl: string;
  terrainApiUrl: string;
  blobStorageUri: string;
  blobStorageUriTest: string;
  fileStorageUri: string;
  tableStorageTableName: string;
  azureStorageContainerLink: string;

  converterEndpoints: ConverterEndpoints;

  authenticationEndpoints: AuthenticationEndpoints;
  mapEndpoints: MapEndpoints;
  modelEndpoints: ModelEndpoints;
  projectEndpoints: ProjectEndpoints;
  simulationEndpoints: SimulationEndpoints;
  userEndpoints: UserEndpoints;
  userAccountEndpoints: UserAccountEndpoints;
  windfieldForDesktopProject: WindfieldForDesktopProject;
  desktopCloudHybridProject: DesktopCloudHybridProject;
  organizationEndpoints: OrganizationEndpoints;
  azureFunctions: AzureFunctions;
  arcGis: ArcGis;
}

export interface ConverterEndpoints {
  uploadDataset: string;
  getDataset: string;
  getDatasets: string;
  deleteDataset: string;
}

export interface AuthenticationEndpoints {
  registerCorporateUser: string;
  registerRegularUser: string;
  registerInternalCorporateUser: string;
  login: string;
  logout: string;
}

export interface MapEndpoints {
  downloadMapSourceFile: string;
}

export interface ModelEndpoints {
  submitJob: string;
}

export interface ProjectEndpoints {
  getJobsStatus: string;
  stopJob: string;
  stopAllJobs: string;
  deleteJobs: string;
  cancelJob: string;
  cancelAllJobs: string;
  add: string;
  save: string;
  remove: string;
  makeAnotherCopyOfTheProject: string;
  updateStatus: string;
  all: string;
  ownedProjects: string;
  organizationProjects: string;
  getProject: string;
  getProjectOutputUri: string;
  listOutputFiles: string;
  downloadOutputFile: string;
  listInputFiles: string;
  listWindfieldProgressOutputFiles: string;
  downloadWindfieldProgressOutputFile: string;
  signalrConnection: string;
  getProjectValuesUri: string;
  updateVisibility: string;
  generateBlobDownloadSASUri: string;
}

export interface SimulationEndpoints {
  submitJob: string;
}

export interface UserEndpoints {
  user: string;
  getUsers: string;
  update: string;
  delete: string;
  getOrganization: string;
  updateOrganization: string;
  deleteOrganization: string;
  getBilling: string;
  addBilling: string;
  updateBilling: string;
  deleteBilling: string;
  sendRegistrationInvite: string;
  invitedUserInformation: string;
}

export interface UserAccountEndpoints {
  getRoles: string;
  getUserRoles: string;
  getProjectRoles: string;
  getProjectUserRoles: string;
  getProjectUsers: string;
  assignProjectRoles: string;
  assignRole: string;
  removeRole: string;
  removeClaim: string;
  removeProjectRole: string;
  getClaims: string;
  getUserClaim: string;
  assignClaims: string;
  forgotPassword: string;
  resetPassword: string;
  changePasswordForLoggedInUser: string;
}

export interface WindfieldForDesktopProject {
  getProjectInputUploadUri: string;
  submitJob: string;
}

export interface DesktopCloudHybridProject {
  getProjectUploadUri: string;
  getProjectDownloadUri: string;
  submitJob: string;
  submitSingleJob: string;
  submitJobWithOwnParameters: string;
  estimateProjectCredits: string;
}

export interface OrganizationEndpoints {
  availableCredits: string;
  usedCredits: string;
  reservedCredits: string;
  creditsLimit: string;
  subscription: string;
  subscriptionBooster: string;
  customerCreditsStats: string;
}

export interface AzureFunctions {
  windsimFieldValueConverter: string;
  generateGwsFile: string;
  getMapStatus: string;
}

export interface ArcGis {
  apiKey: string;
  geoCodeUrl: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('');
