import { Injectable } from '@angular/core';
import { JobWorkflowMessageType } from '@windsim/core/enums';
import { SignalrMessage, MapMessage } from '@windsim/core/models';
import { DataService } from '@windsim/core/services/data.service';
import { ValidationService } from '@windsim/core/services/validation.service';

@Injectable({
  providedIn: 'root',
})
export class JobWorkflowMessagesService {
  constructor(private readonly dataService: DataService, private readonly validationService: ValidationService) {}

  private static createJobMessage(signalrMessage: SignalrMessage): string {
    switch (true) {
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.StartJobSucceeded:
        return `Starting job ${signalrMessage.jobId} succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.CancelJobSucceeded:
        return `Cancel job ${signalrMessage.jobId} succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.CompleteJobSucceeded:
        return `Complete job ${signalrMessage.jobId} succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.StartJobFailed:
        return `Starting job ${signalrMessage.jobId} failed`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.RestartJobSucceeded:
        return `Restart job ${signalrMessage.jobId} succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.CreateJobSucceeded:
        return `Create job ${signalrMessage.jobId} succeeded`;
      default:
        break;
    }
    return '';
  }

  private static createSectorJobMessage(signalrMessage: SignalrMessage): string {
    switch (true) {
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.StartJobSucceeded:
        return `Starting job for sector ${signalrMessage.degree}° succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.CancelJobSucceeded:
        return `Cancel job for sector ${signalrMessage.degree}° succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.CompleteJobSucceeded:
        return `Complete job for sector ${signalrMessage.degree}° succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.StartJobFailed:
        return `Starting job for sector ${signalrMessage.degree}° failed`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.RestartJobSucceeded:
        return `Restart job for sector ${signalrMessage.degree}° succeeded`;
      case signalrMessage.jobProgressStatusMessage === JobWorkflowMessageType.CreateJobSucceeded:
        return `Create job for sector ${signalrMessage.degree}° succeeded`;
      default:
        break;
    }
    return '';
  }

  public isWorkflowMessage(signalrMessage: SignalrMessage): boolean {
    if (signalrMessage === undefined) {
      return false;
    }

    const validWorkflowMessagesKeywords = [
      JobWorkflowMessageType.StartJobSucceeded,
      JobWorkflowMessageType.CancelJobSucceeded,
      JobWorkflowMessageType.CompleteJobSucceeded,
      JobWorkflowMessageType.StartJobFailed,
      JobWorkflowMessageType.RestartJobSucceeded,
      JobWorkflowMessageType.CreateJobSucceeded,
    ];

    return (
      !this.validationService.isNullOrEmpty(signalrMessage.jobProgressStatusMessage) &&
      validWorkflowMessagesKeywords.some((keyword) => signalrMessage.jobProgressStatusMessage.includes(keyword))
    );
  }

  public createSnackbarMessage(signalrMessage: SignalrMessage): string {
    switch (true) {
      case signalrMessage.sector !== null:
        return JobWorkflowMessagesService.createSectorJobMessage(signalrMessage);
      case signalrMessage.sector === null:
        return JobWorkflowMessagesService.createJobMessage(signalrMessage);
      default:
        break;
    }
    return '';
  }

  public createMapMessage(message: MapMessage): string {
    return message.message;
  }
}
