<app-dialog
  [title]="assignProjectRoleDialogTitle | translate"
  icon="assign-project-role"
  >

  <form [formGroup]="assignProjectRoleForm" [id]="'assign-project-user-role-form'">
    <ng-container *ngIf="data.displayOptionsList">
      <label for="optionsList" class="base-form__label" >{{data.optionsListTitle | translate}}</label>
      <mat-form-field appearance="outline" class="base-form__field">
        <input type="text" id="optionsList" matInput formControlName="optionsList" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <label for="projectRole" class="base-form__label">{{'organization.user-project-role' | translate}}</label>
    <mat-form-field appearance="outline" class="base-form__field">
      <mat-select disableRipple id="projectRole" formControlName="projectRole" required>
        <mat-option *ngFor="let role of projectRoles$ | async" [value]="role">
          {{(role | humanReadableProjectRole) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-spinner [diameter]="16" *ngIf="assignProjectRoleFormProcessing$ | async"></mat-spinner>
  </form>
  <ng-container dialogActions>
    <button class="dialog__button" mat-flat-button color="primary" mat-dialog-close>{{'global.cancel' | translate}}</button>
    <button
      mat-flat-button
      class="dialog__button"
      type="submit"
      [attr.form]="'assign-project-user-role-form'"
      color="accent"
      [mat-dialog-close]="assignProjectRoleForm.value">
      {{'global.save' | translate}}
    </button>
  </ng-container>
</app-dialog>
