<app-loader-container [isLoading]="isLoading" [showContentWhenLoading]="true">
  <ng-container loadedComponent>
    <div class="canvas__content canvas__content--placeholder" *ngIf="!filesProcessingCompleted && !isLoading">
      <app-icon iconName="info" iconClassName="icon icon--no-margin icon--extra-large" class="canvas__info-icon"></app-icon>
      <span class="canvas__content--text">{{'canvas.processing-files' | translate}}</span>
    </div>
    <canvas
      #primaryRendererCanvas
      id="primaryRenderCanvas"
      class="canvas__content"></canvas>
    <canvas
      #hudCanvas
      id="hudCanvas"
      class="canvas__hud"
    width="150"
    height="422"></canvas>
  </ng-container>
</app-loader-container>
