import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig, ProjectPartitionKey } from '@windsim/core/models';
import { ValidationService } from '@windsim/core/services/validation.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseFileService {
  protected config: AppConfig;

  constructor(@Inject(APP_CONFIG) config: AppConfig, protected validationService: ValidationService, protected http: HttpClient) {
    this.config = config;
  }

  public getOutputFile(projectPartitionKey: ProjectPartitionKey, fileName: string): Observable<string> {
    this.validationService.validateStringArgument(projectPartitionKey.projectId, 'projectId');
    this.validationService.validateStringArgument(fileName, 'fileName');

    const dto = {
      projectId: projectPartitionKey.projectId,
      fileName,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.projectEndpoints.downloadOutputFile}`, dto, { headers, responseType: 'text' })
      .pipe(
        map((text) => {
          return text;
        }),
      );
  }
}
