import { Injectable } from '@angular/core';
import { AlertService } from '@windsim/core/services/alert.service';
import { LoggingService } from '@windsim/core/services/logging.service';
import { Observable, of } from 'rxjs';
import { HttpError } from '@windsim/core/models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly alertService: AlertService, private readonly loggingService: LoggingService) {}

  public errorHandler(error: HttpError, errorMessage: string | string[], id?: string): Observable<boolean> {
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((message) => {
        this.alertService.error(message, id);
      });
    } else {
      this.alertService.error(errorMessage, id);
    }

    this.loggingService.consoleLogError(error);
    return of(false);
  }

  public confirmationHandler(confirmationMessage: string, id?: string): void {
    this.alertService.success(confirmationMessage, id);
  }
}
