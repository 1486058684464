import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { CommonDefaults } from '@windsim-core/configs';
import { DataService } from '@windsim-core/services/data.service';
import { StorageService } from '@windsim-core/services/storage.service';
import { SeverityLevel, SignalrStatus } from '@windsim/core/enums';
import { APP_CONFIG, AppConfig, ExceptionTelemetry, TraceTelemetry } from '@windsim/core/models';
import { LoggingService } from '@windsim/core/services/logging.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private config: AppConfig;
  private hubConnection: HubConnection;
  private data: string;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly storageService: StorageService,
    private readonly dataService: DataService,
    private readonly loggingService: LoggingService,
    private readonly validationService: ValidationService,
    private readonly http: HttpClient,
  ) {
    this.config = config;
  }

  public startConnection(projectId: string): void {
    this.validationService.validateStringArgument(projectId, CommonDefaults.PROJECT_ID_STORAGE_KEY);

    const token = this.storageService.getGlobalValue(CommonDefaults.TOKEN_STORAGE_KEY);

    if (this.validationService.isNullOrEmpty(token)) {
      throw new Error('Token cannot be null when starting the SignalR hub connection.');
    }

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.config.messageHubUrl}/MessageHub?projectId=${projectId}`, { accessTokenFactory: () => token })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this.messageJobListener(projectId);

    this.hubConnection
      .start()
      .then(() => {
        const message = 'SignalR Hub connection started';
        this.loggingService.consoleLogInfo({ message });
        const traceTelemetry: TraceTelemetry = {
          message,
          severityLevel: SeverityLevel.Information,
          customProperties: { projectId: `${projectId}`, method: 'SignalrService/startConnection/hubConnection' },
        };
        this.loggingService.externalLogTrace(traceTelemetry);
        this.dataService.setSignalrStatus(SignalrStatus.Connected);
        this.getConnection(projectId)
          .pipe(
            first(),
            tap((isConnected: boolean) => {
              if (!isConnected) {
                this.dataService.reconnectSignalr();
              }
            }),
          )
          .subscribe();
      })
      .catch((error) => {
        this.loggingService.consoleLogError(error);
        const exceptionTelemetry: ExceptionTelemetry = {
          exception: {
            name: 'SignalrService/startConnection/hubConnection',
            message: error,
          },
          id: projectId,
          severityLevel: SeverityLevel.Critical,
        };
        this.loggingService.externalLogException(exceptionTelemetry);
      });

    this.onReconnectingListener(projectId);
    this.onReconnectedListener(projectId);
    this.onCloseListener(projectId);
  }

  public stopConnection(): void {
    this.hubConnection
      .stop()
      .then(() => {
        this.dataService.setSignalrStatus(SignalrStatus.Disconnected);
        this.loggingService.consoleLogInfo({ message: 'SignalR connection stopped...' });
      })
      .catch((error) => {
        this.dataService.setSignalrStatus(SignalrStatus.Error);
        this.loggingService.consoleLogError({ message: error });
      });
  }

  public getConnection(projectId: string): Observable<any> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    return this.http.get(`${this.config.coreApiUrl}/${this.config.projectEndpoints.signalrConnection}/${projectId}`);
  }

  private messageJobListener(projectId: string): void {
    this.hubConnection.on('MessageReceived', (data) => {
      this.data = JSON.stringify(data);
      const traceTelemetry: TraceTelemetry = {
        message: data,
        projectId,
        severityLevel: SeverityLevel.Information,
        customProperties: { projectId: `${projectId}`, method: 'SignalrService/addJobListener' },
      };
      this.loggingService.externalLogTrace(traceTelemetry);
      this.loggingService.consoleLogInfo({ value: traceTelemetry });
      this.dataService.receivedSignalrHubMessage(data);
      this.dataService.receiveMapMessage(data);
    });
  }

  private onReconnectingListener(projectId: string): void {
    this.hubConnection.onreconnecting((error) => {
      const traceTelemetry: TraceTelemetry = {
        message: 'SignalR Hub reconnecting',
        projectId,
        severityLevel: SeverityLevel.Information,
        customProperties: { projectId: `${projectId}`, method: 'SignalrService/onReconnectingListener' },
      };
      this.loggingService.externalLogTrace(traceTelemetry);
      this.loggingService.consoleLogInfo({ message: 'SignalR Hub reconnecting...' });

      if (error) {
        this.loggingService.consoleLogError({ message: error });
      }
      this.dataService.setSignalrStatus(SignalrStatus.Reconnecting);
    });
  }

  private onReconnectedListener(projectId: string): void {
    this.hubConnection.onreconnected(() => {
      const traceTelemetry: TraceTelemetry = {
        message: 'SignalR Hub reconnected',
        projectId,
        severityLevel: SeverityLevel.Information,
        customProperties: { projectId: `${projectId}`, method: 'SignalrService/onReconnectingListener' },
      };
      this.loggingService.externalLogTrace(traceTelemetry);
      this.loggingService.consoleLogInfo({ message: 'SignalR Hub reconnected...' });
      this.dataService.setSignalrStatus(SignalrStatus.Reconnected);
    });
  }

  private onCloseListener(projectId: string): void {
    this.hubConnection.onclose(() => {
      const traceTelemetry: TraceTelemetry = {
        message: 'SignalR Hub connection closed',
        projectId,
        severityLevel: SeverityLevel.Information,
        customProperties: { projectId: `${projectId}`, method: 'SignalrService/onReconnectingListener' },
      };
      this.loggingService.externalLogTrace(traceTelemetry);
      this.loggingService.consoleLogInfo({ message: 'SignalR Hub connection closed...' });
      this.dataService.setSignalrStatus(SignalrStatus.Disconnected);
    });
  }
}
