<app-dialog
  [icon]="icon"
  additionalClassName="dialog--confirmation"
  [title]="data.dialogTitle | translate">
  <p class="mat-body-text mat-body-text--margin-small"
     [innerHTML]="data.dialogDescription | translate: {'dialogDescriptionParams': data.dialogDescriptionParams | translate}">
  </p>
  <ng-container dialogActions>
    <button class="dialog__button" mat-flat-button color="primary" mat-dialog-close>{{'global.cancel' | translate}}</button>
    <button
      mat-flat-button
      class="dialog__button"
      type="button"
      [mat-dialog-close]="true"
      [color]="submitButtonColor">
      {{submitButtonText | translate}}
    </button>
  </ng-container>
</app-dialog>
