import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class CustomPaginatorIntlService extends MatPaginatorIntl implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  ofPageLabel: string;

  constructor(private readonly translateService: TranslateService) {
    super();
    this.getAndInitTranslations();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translateService
      .stream([
        'pagination.first-page',
        'pagination.last-page',
        'pagination.next-page',
        'pagination.previous-page',
        'pagination.items-per-page',
        'pagination.of',
      ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((translation) => {
          this.firstPageLabel = translation['pagination.first-page'];
          this.lastPageLabel = translation['pagination.last-page'];
          this.nextPageLabel = translation['pagination.next-page'];
          this.previousPageLabel = translation['pagination.previous-page'];
          this.itemsPerPageLabel = translation['pagination.items-per-page'];
          this.ofPageLabel = translation['pagination.of'];
          this.getRangeLabel = this.getRangeLabel.bind(this);

          this.changes.next();
        }),
      )
      .subscribe();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofPageLabel} ${length}`;
    }
    const maxLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < maxLength ? Math.min(startIndex + pageSize, maxLength) : startIndex + pageSize;

    if (this.translateService.currentLang === 'zh-Hans') {
      return `${maxLength} ${this.ofPageLabel} ${startIndex + 1} - ${endIndex}`;
    }
    return `${startIndex + 1} - ${endIndex} ${this.ofPageLabel} ${maxLength}`;
    // eslint-disable-next-line
  }
}
