import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '@windsim/core/enums';

@Pipe({
  name: 'projectStatusCssClass',
  pure: true,
})
export class ProjectStatusCssClassPipe implements PipeTransform {
  transform(projectStatus: ProjectStatus): unknown {
    switch (projectStatus) {
      case ProjectStatus.Completed:
        return 'is-completed';
      case ProjectStatus.InProgress:
        return 'is-in-progress';
      case ProjectStatus.Failed:
        return 'has-error';
      case ProjectStatus.Cancelled:
        return 'is-cancelled';
      default:
        return '';
    }
  }
}
