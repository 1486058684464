export class Colors {
  public static elevationColors8 = Object.freeze(['#9cce31', '#ffe164', '#e1c864', '#c8af64', '#af9664', '#967d64', '#7d644b', '#644b32']);

  // noinspection JSUnusedGlobalSymbols
  public static elevationColors16 = Object.freeze([
    '#9cce31',
    '#f9df77',
    '#eed472',
    '#e2c96d',
    '#d8bf68',
    '#cdb564',
    '#c2aa5f',
    '#b7a05b',
    '#ac9556',
    '#a18a51',
    '#96804c',
    '#8b7548',
    '#816b43',
    '#75603e',
    '#6a563a',
    '#5f4b35',
  ]);

  public static roughnessColors8 = Object.freeze(['#ffffff', '#ffff7d', '#ffff00', '#ffbe00', '#ff7d00', '#ff3c00', '#ff0000', '#7d0000']);
}
