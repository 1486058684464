import { Injectable } from '@angular/core';
import { BaseFileService } from '@windsim-core/services/base-file.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModelFilesService extends BaseFileService {
  public listOutputFiles(projectId: string): Observable<any> {
    this.validationService.validateStringArgument(projectId, 'projectId');

    const dto = { projectId };

    return this.http.post<any>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.listOutputFiles}`, dto).pipe(
      map((outputFilesList) => {
        return outputFilesList;
      }),
    );
  }
}
