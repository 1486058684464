import { Inject, Injectable, isDevMode } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ITraceTelemetry } from '@microsoft/applicationinsights-web/dist/applicationinsights-web.rollup';
import { LogColor } from '@windsim-core/enums';
import { APP_CONFIG, AppConfig, ExceptionTelemetry, LogMessage, TraceTelemetry } from '@windsim/core/models';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsightsConnectionString,
        disableAjaxTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public externalLogException(exception: ExceptionTelemetry): void {
    this.appInsights.trackException(exception);
  }

  public externalLogTrace(traceTelemetry: TraceTelemetry): void {
    const trace: ITraceTelemetry = {
      message: traceTelemetry.message,
      severityLevel: traceTelemetry.severityLevel,
    };
    this.appInsights.trackTrace(trace, traceTelemetry.customProperties);
  }

  public consoleLogError(message: any): void {
    // eslint-disable-next-line no-console
    console.error(message);
  }

  public consoleLogInfo(logMessage?: LogMessage): void {
    if (isDevMode()) {
      if (!logMessage) {
        // eslint-disable-next-line no-console
        console.log('%cnothing to log', LogColor.Red || LogColor.None);
        return;
      }
      if (logMessage.message && logMessage.value) {
        // eslint-disable-next-line no-console
        console.log(`%c${logMessage.message}`, logMessage.color || LogColor.None, logMessage.value);
      }
      if (logMessage.message && !logMessage.value) {
        // eslint-disable-next-line no-console
        console.log(`%c${logMessage.message}`, logMessage.color || LogColor.None);
      }
      if (!logMessage.message && logMessage.value) {
        // eslint-disable-next-line no-console
        console.log(logMessage.value);
      }
    }
  }

  public consoleGroupStart(name?: string): void {
    // eslint-disable-next-line no-console
    console.group(name || 'console group');
  }

  public consoleGroupEnd(): void {
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}
