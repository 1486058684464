<app-dialog
  [title]="'users.assign-user-role' | translate"
  icon="assign-user-role"
>
  <form [formGroup]="assignUserRoleForm" [id]="'assign-user-user-role-form'">
    <label class="base-form__label" for="userRole">{{'organization.user-role' | translate}}</label>
    <mat-form-field appearance="outline" class="base-form__field">
      <mat-select [formControl]="userRoleFormControl" [value]="userRoleFormControl.value" disableRipple
                  id="userRole" required>
        <mat-option *ngFor="let role of userRoles$ | async" [value]="role.name">
          {{(role.name | humanReadableUserRole) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <ng-container dialogActions>
    <button class="dialog__button" color="primary" mat-dialog-close
            mat-flat-button>{{'global.cancel' | translate}}</button>
    <button
      [attr.form]="'assign-user-module-role-form'"
      [mat-dialog-close]="userRoleFormControl.value"
      class="dialog__button"
      color="accent"
      mat-flat-button
      type="submit">
      {{'global.save' | translate}}
    </button>
  </ng-container>
</app-dialog>
