import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutesDefaults } from '@windsim/app-routing-defaults';
import { ToolbarType } from '@windsim/core/enums';
import { AuthGuard, WorkflowGuard } from '@windsim/core/guards';

const appRoutes: Routes = [
  {
    path: 'projects',
    loadChildren: () => import('./modules/projects/projects.module').then((m) => m.ProjectsModule),
    canActivate: [AuthGuard, WorkflowGuard],
  },
  {
    path: 'project-creator',
    loadChildren: () => import('./modules/project-creator/project-creator.module').then((m) => m.ProjectCreatorModule),
    canActivate: [AuthGuard, WorkflowGuard],
  },
  {
    path: RoutesDefaults.ONLINE_RESOURCES,
    children: [
      {
        path: 'map',
        loadChildren: () => import('./modules/map/map.module').then((m) => m.MapModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'model',
        loadChildren: () => import('./modules/model/model.module').then((m) => m.ModelModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'simulations',
        loadChildren: () => import('./modules/simulation/simulation.module').then((m) => m.SimulationModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'files',
        loadChildren: () => import('./modules/files/files.module').then((m) => m.FilesModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
    ],
  },
  {
    path: RoutesDefaults.OWN_DATASET,
    children: [
      {
        path: 'map',
        loadChildren: () => import('./modules/map-own-dataset/map-own-dataset.module').then((m) => m.MapOwnDatasetModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'model',
        loadChildren: () => import('./modules/model/model.module').then((m) => m.ModelModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'simulations',
        loadChildren: () => import('./modules/simulation/simulation.module').then((m) => m.SimulationModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'files',
        loadChildren: () => import('./modules/files/files.module').then((m) => m.FilesModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
    ],
  },
  {
    path: RoutesDefaults.UPLOADED_PROJECT,
    children: [
      {
        path: 'simulations',
        loadChildren: () => import('./modules/simulation/simulation.module').then((m) => m.SimulationModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'files',
        loadChildren: () => import('./modules/files/files.module').then((m) => m.FilesModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'map',
        loadChildren: () => import('./modules/map/map.module').then((m) => m.MapModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
    ],
  },
  {
    path: RoutesDefaults.GWS_GENERATOR,
    children: [
      {
        path: 'map',
        loadChildren: () => import('./modules/map/map.module').then((m) => m.MapModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
      {
        path: 'files',
        loadChildren: () => import('./modules/files/files.module').then((m) => m.FilesModule),
        canActivate: [AuthGuard, WorkflowGuard],
      },
    ],
  },
  {
    path: 'map-own-dataset',
    loadChildren: () => import('./modules/map-own-dataset/map-own-dataset.module').then((m) => m.MapOwnDatasetModule),
    canActivate: [AuthGuard, WorkflowGuard],
  },
  {
    path: 'organization',
    loadChildren: () => import('./modules/organization/organization.module').then((m) => m.OrganizationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/projects/projects.module').then((m) => m.ProjectsModule),
    canActivate: [AuthGuard, WorkflowGuard],
  },
  {
    path: '',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    data: { toolbar: ToolbarType.Simple },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
