import { Point } from '@windsim/core/models/point.model';

export interface Markers {
  [id: number]: Marker;
}

export interface Marker {
  coordinates: Point;
  label?: string;
  icon: MarkerIcon;
  isInMaxArea: boolean;
}

export enum MarkerIcon {
  insideArea = 'on-marker.svg',
  outsideArea = 'off-marker.svg',
}
