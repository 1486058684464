import { Component, Input } from '@angular/core';
import { SidenavLink } from '@windsim/core/models/sidenav-link.model';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent {
  @Input() links: SidenavLink[];
  @Input() hasStatus = false;
}
