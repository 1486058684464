import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@windsim/core/services';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authenticationService.authTokenValue;
    let httpRequest = request;
    // todo: uncomment on prod environment
    // const isLoggedIn = token;
    // const isApiUrl = request.url.startsWith(environment.coreApiUrl);
    // if (isLoggedIn && isApiUrl)
    if (token) {
      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(httpRequest);
  }
}
