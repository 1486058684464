import { Injectable } from '@angular/core';
import { CommonDefaults } from '@windsim-core/configs';
import { StorageService } from '@windsim/core/services/storage.service';
import { UtilitiesService } from '@windsim/core/services/utilities.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectValuesPersistenceService {
  constructor(private readonly storageService: StorageService, private readonly validationService: ValidationService) {}

  public async persistValues(clientId: string, projectId: string): Promise<void> {
    await this.persistMapModuleValues(clientId, projectId);
    await this.persistModelModuleValues(clientId, projectId);
    await this.persistSimulationsModuleValues(clientId, projectId);
    await this.persistProjectState(clientId, projectId);
  }

  public async loadPersistedValues(clientId: string, projectId: string): Promise<void> {
    await this.loadProjectState(clientId, projectId);
    await this.loadMapModuleValues(clientId, projectId);
    await this.loadModelModuleValues(clientId, projectId);
    await this.loadSimulationsModuleValues(clientId, projectId);
  }

  public async persistProjectState(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.storeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
  }

  public async loadProjectState(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.loadValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
  }

  public fetchProjectState(clientId: string, projectId: string): Observable<string> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    return from(this.storageService.getExternalValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY));
  }

  public async persistMapModuleValues(clientId: string, projectId: string, saveProjectState: boolean = false): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.storeValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.BUFFER_AREA_COORDS_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.MARKERS_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.MAP_MARKERS_MAX_AREA_BOUNDS_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.IS_MARKERS_AREA_LOCKED_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.ELEVATION_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.ROUGHNESS_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.MAP_AREA_SELECTION_MODE);
    await this.storeValue(clientId, projectId, CommonDefaults.ROUGHNESSLEGEND_STORAGE_KEY);

    if (saveProjectState) {
      await this.storeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);
    }
  }

  public async loadMapModuleValues(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.loadValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.BUFFER_AREA_COORDS_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.MARKERS_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.MAP_MARKERS_MAX_AREA_BOUNDS_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.IS_MARKERS_AREA_LOCKED_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.ELEVATION_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.ROUGHNESS_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.MAP_AREA_SELECTION_MODE);
    await this.loadValue(clientId, projectId, CommonDefaults.ROUGHNESSLEGEND_STORAGE_KEY);
  }

  public async persistModelModuleValues(clientId: string, projectId: string, saveProjectState: boolean = false): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.storeValue(clientId, projectId, CommonDefaults.MODEL_FORM_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.MODEL_JOB_ID_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY);

    if (saveProjectState) {
      await this.storeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);
    }
  }

  public async loadModelModuleValues(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.loadValue(clientId, projectId, CommonDefaults.MODEL_FORM_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.MODEL_JOB_ID_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY);
  }

  public async persistSimulationsModuleValues(clientId: string, projectId: string, saveProjectState: boolean = false): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.storeValue(clientId, projectId, CommonDefaults.SIMULATION_FORM_STORAGE_KEY);
    await this.storeValue(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY);

    if (saveProjectState) {
      await this.storeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);
    }
  }

  public persistSimulationsModuleValuesObs(clientId: string, projectId: string): Observable<void> {
    return from(this.persistSimulationsModuleValues(clientId, projectId));
  }

  public persistProjectStateObs(clientId: string, projectId: string): Observable<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    return from(this.storeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY));
  }

  public async persistSimulationsSectors(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.storeValue(clientId, projectId, CommonDefaults.SIMULATION_SECTORS_STORAGE_KEY);
  }

  public persistSimulationsSectorsObs(clientId: string, projectId: string): Observable<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    return from(this.storeValue(clientId, projectId, CommonDefaults.SIMULATION_SECTORS_STORAGE_KEY));
  }

  public async loadSimulationsModuleValues(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.loadValue(clientId, projectId, CommonDefaults.SIMULATION_FORM_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.SIMULATION_SECTORS_STORAGE_KEY);
    await this.loadValue(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY);
  }

  public async persistOwnProjectDetails(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.storeValue(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY);
  }

  public async loadOwnProjectDetails(clientId: string, projectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    await this.loadValue(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY);
  }

  public areOwnProjectDetailsExistInLocalStorage(clientId: string, projectId: string): boolean {
    return this.storageService.isExist(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY);
  }

  private async storeValue(clientId: string, projectId: string, key: string): Promise<void> {
    const isValueExists = this.storageService.isExist(clientId, projectId, key);
    if (isValueExists) {
      const value = this.storageService.getValue(clientId, projectId, key);
      if (await this.storageService.isExternalValueExists(clientId, projectId, key)) {
        await this.storageService.removeExternalValue(clientId, projectId, key);
      }
      await this.storageService.storeExternalValue(clientId, projectId, key, value);
      return;
    }
    if (await this.storageService.isExternalValueExists(clientId, projectId, key)) {
      await this.storageService.removeExternalValue(clientId, projectId, key);
    }
  }

  private async loadValue(clientId: string, projectId: string, key: string): Promise<void> {
    if (await this.storageService.isExternalValueExists(clientId, projectId, key)) {
      let value = await this.storageService.getExternalValue(clientId, projectId, key);
      if (value) {
        if (UtilitiesService.isJsonString(value)) {
          value = JSON.parse(value);
        }
        this.storageService.storeValue(clientId, projectId, key, value);
      }
    }
  }
}
