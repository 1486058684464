export interface RoughnessLegendData {
  color: number[];
  edited: boolean;
  description: string;
  roughness: number;
  imageData: string;
  class: string;
}

export interface RoughnessLegendDataset {
  [key: string]: RoughnessLegendData;
}

export enum RoughnessSource {
  Sentinel2_10m_LandCover = 'Sentinel2_10m_LandCover',
  Corine_CLC2018_WM = 'Corine_CLC2018_WM',
}
