import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Layer } from '@windsim-core/models';
import { DataService, FormService } from '@windsim-core/services';
import { ModelLayer } from '@windsim-model/models';
import { SimulationLayer } from '@windsim-simulation/models';
import { MapMessage } from '@windsim-core/models';

@Component({
  selector: 'app-layer-toggle',
  templateUrl: './layer-toggle.component.html',
  styleUrls: ['./layer-toggle.component.scss'],
})
export class LayerToggleComponent {
  @Input() layer: Layer | ModelLayer | SimulationLayer;
  @Input() shouldLayerBeDisabled: boolean;
  @Input() shouldSpinnerBeDisplayed = false;
  @Output() loadLayerEvent = new EventEmitter<Layer | ModelLayer | SimulationLayer>();
  message: MapMessage;
  constructor(private readonly dataService: DataService, private readonly formService: FormService) {}

  public changeOpacity(layer: Layer, event$: Event): void {
    const { value } = event$.target as HTMLInputElement;
    const valueValidated = this.formService.validatePercentageValue(value !== '' ? value : String(layer.opacity * 100));

    layer.opacity = valueValidated / 100;
    (event$.target as HTMLInputElement).value = String(valueValidated);
    this.dataService.changeLayerOpacity(layer);
  }

  public onLoadLayer(layer: Layer | ModelLayer | SimulationLayer): void {
    this.loadLayerEvent.emit(layer);
    this.dataService.toggleLayerDataGrid(layer);
    if (layer.layerName === 'layers.roughness-global-visibility-button' && layer.isVisible === true) {
      this.message = { message: 'You have set roughness data from global dataset.' };
      this.dataService.receiveMapMessage(this.message);
    }
    if (layer.layerName === 'layers.roughness-visibility-button' && layer.isVisible === true) {
      this.message = { message: 'You have set roughness data from Europe dataset.' };
      this.dataService.receiveMapMessage(this.message);
    }
  }
}
