import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multilineUnicode',
  pure: true,
})
export class MultilineUnicodePipe implements PipeTransform {
  transform(text: string): string {
    return text.replace(/&#13;/g, '\u000A');
  }
}
