<div [ngClass]="{'layer-toggle' : true, 'layer-toggle--disabled': shouldLayerBeDisabled}" *ngIf="layer">
  <button
    class="layer-toggle__button"
    mat-icon-button
    type="button"
    (click)="onLoadLayer(layer)"
    [disabled]="shouldLayerBeDisabled">
    <app-icon *ngIf="layer.isVisible" iconName="eye" iconClassName="icon icon--no-margin"></app-icon>
    <span class="cdk-visually-hidden">{{'layers.toggle-layer' | translate}}</span>
  </button>

  <span class="layer-toggle__content">
    {{layer.layerName | translate}}
  </span>

  <mat-spinner
    [diameter]="14"
    *ngIf="shouldSpinnerBeDisplayed">
  </mat-spinner>

  <span class="layer-toggle__button-alike" *ngIf="layer.hasDataGrid" >
    <app-icon iconName="grid" iconClassName="icon icon--no-margin"></app-icon>
    <span class="cdk-visually-hidden">{{'layers.tabular-data' | translate}}</span>
  </span>
  <label *ngIf="layer.opacity !== undefined"
         [ngClass]="{
         'layer-toggle__input-wrapper': true,
         'layer-toggle__input-wrapper--disabled' : shouldLayerBeDisabled || !layer.isVisible
         }">
    <span class="cdk-visually-hidden">{{'layers.opacity' | translate}}</span>
    <input
      [max]="100"
      [min]="0"
      [step]="1"
      value="{{layer.opacity * 100 | number: '1.0-1'}}"
      type="number"
      class="layer-toggle__input"
      (change)="changeOpacity(layer, $event)"
      [disabled]="shouldLayerBeDisabled || !layer.isVisible" />
  </label>

</div>
