<div class="forest-control {{className}}">
  <div class="forest-control__addon">
    <span *ngIf="counter">{{ counter }}</span>
  </div>
  <div class="forest-control__inputs">
    <div class="forest-control__field forest-control__field--first">

      <label class="forest-control__label">
        <span class="forest-control__label-text">{{ 'model.roughness' | translate }}</span>
        <input type="number"
               class="forest-control__input"
               [formControl]="roughnessFormControl"
               [value]="roughnessFormControl.value"
        />
      </label>
    </div>
    <div class="forest-control__field forest-control__field--first">
      <label class="forest-control__label">
        <span class="forest-control__label-text">{{ 'model.height' | translate }}</span>
        <input type="number"
               class="forest-control__input"
               [formControl]="heightFormControl"
               [value]="heightFormControl.value"
        />
      </label>
    </div>
    <div class="forest-control__field forest-control__field--first">
      <label class="forest-control__label">
        <span class="forest-control__label-text">{{ 'model.resistiveForceC2' | translate }}</span>
        <input type="number"
               class="forest-control__input"
               [formControl]="resistiveForceC2FormControl"
               [value]="resistiveForceC2FormControl.value"
        />
      </label>
    </div>
    <div class="forest-control__field forest-control__field--first">
      <label class="forest-control__label">
        <span class="forest-control__label-text">{{ 'model.forestCellZ' | translate }}</span>
        <input type="number"
               class="forest-control__input"
               [formControl]="ForestCellsZFormControl"
               [value]="ForestCellsZFormControl.value"
        />
      </label>
    </div>
    <div class="forest-control__field forest-control__field--first">
      <label class="forest-control__label" [attr.for]="dynamicForAttribute">
        <span class="forest-control__label-text">{{ 'model.turbulence' | translate }}</span>
        <input type="checkbox" class="forest-control__input-checkbox"
               [id]="dynamicForAttribute"
               [formControl]="turbulenceFormControl"
               [value]="turbulenceFormControl.value"
        />
      </label>
    </div>

  </div>


  <ng-content select="[action-button]"></ng-content>

</div>

<p *ngIf="roughnessFormControl.errors" [@inOutAnimation] class="forest-control__error">
  {{ roughnessErrorMessage | translate }}
</p>

<p *ngIf="heightFormControl.errors" [@inOutAnimation] class="forest-control__error">
  {{ heightErrorMessage | translate }}
</p>
<p *ngIf="resistiveForceC2FormControl.errors" [@inOutAnimation] class="forest-control__error">
  {{ resistiveForceC2ErrorMessage | translate }}
</p>
<p *ngIf="ForestCellsZFormControl.errors" [@inOutAnimation] class="forest-control__error">
  {{ ForestCellsZErrorMessage | translate }}
</p>
