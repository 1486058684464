import { Injectable } from '@angular/core';
import { AreaCoordinates } from '@windsim/core/models/area-coordinates.model';
import { Boundaries } from '@windsim/core/models/boundaries.model';
import { Point } from '@windsim/core/models/point.model';

@Injectable({
  providedIn: 'root',
})
export class MapDefaults {
  public static readonly WORLD_ZOOM_LEVEL = 2;
  public static readonly CONTINENT_ZOOM_LEVEL = 5;
  public static readonly CITY_ZOOM_LEVEL = 10;
  public static readonly STREETS_ZOOM_LEVEL = 15;

  public static readonly DEFAULT_LATITUDE = 0;
  public static readonly DEFAULT_LONGITUDE = 0;
  public static readonly INTERNAL_MODEL_BOUNDARIES_IN_METERS = 1000;
  public static readonly BUFFER_AREA_BOUNDARIES_IN_METERS = 10000;
  public static readonly MAX_AREA_BOUNDARIES_IN_METERS = 20250;
  public static readonly MIN_AREA_BOUNDARIES_IN_METERS = 0;

  public static readonly EARTH_CIRCUMFERENCE_IN_METERS = 40075000;
  public static readonly DEGREE_LAT_IN_METERS = 111000;
  public static readonly ON_AREA_MARKER_ICON = 'on-marker.svg';
  public static readonly OFF_AREA_MARKER_ICON = 'off-marker.svg';
  public static readonly MAP_MARKER_DEFAULT_NAME = 'Marker';
  public static readonly MAP_DEFAULT_CENTER: Point = {
    longitude: MapDefaults.DEFAULT_LONGITUDE,
    latitude: MapDefaults.DEFAULT_LATITUDE,
  };
  public static readonly DEFAULT_COORDINATES: AreaCoordinates = {
    east: 0,
    north: 0,
    west: 0,
    south: 0,
    center: MapDefaults.MAP_DEFAULT_CENTER,
  };
  public static readonly DEFAULT_BOUNDARIES: Boundaries = { x: 0, y: 0 };

  public static readonly DEFAULT_ELEVATION_DATASET = 'SRTM';
  public static readonly DEFAULT_ROUGHNESS_DATASET = 'ESA CCI 2010';

  public static readonly MAX_LATITUDE_VALUE = 90;
  public static readonly MIN_LATITUDE_VALUE = -90;
  public static readonly MAX_LONGITUDE_VALUE = 180;
  public static readonly MIN_LONGITUDE_VALUE = -180;

  public static readonly MAX_FOREST_HEIGHT_VALUE = 50;
  public static readonly MIN_FOREST_HEIGHT_VALUE = 0;
  public static readonly MAX_FOREST_ROUGHNESS_VALUE = 10;
  public static readonly MIN_FOREST_ROUGHNESS_VALUE = 0.00001;
  public static readonly MAX_RESISTIVE_FORCE_VALUE = 10;
  public static readonly MIN_RESISTIVE_FORCE_VALUE = 0;
  public static readonly MAX_FOREST_CELLS_IN_Z_VALUE = 10;
  public static readonly MIN_FOREST_CELLS_IN_Z_VALUE = 1;

  public static FIT_BOUNDS_PADDING = 200;
  public static MAX_AREA_FILL_COLOR = 'transparent';
  public static MAX_AREA_STROKE_COLOR = 'transparent';
  public static BUFFER_AREA_FILL_COLOR = 'transparent';
  public static BUFFER_AREA_STROKE_COLOR = '#36D000';
  public static INNER_MODEL_FILL_COLOR = 'transparent';
  public static INNER_MODEL_STROKE_COLOR = '#F26532';

  public static STROKE_POSITION_CENTER = 0;
}
