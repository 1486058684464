import { Component, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { DialogType } from '@windsim/core/enums';
import { ConfirmationDialogData } from '@windsim/core/models';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}

  public get submitButtonText(): string {
    return this.data.type === DialogType.Delete ? translationMarker('global.delete') : translationMarker('global.confirm');
  }

  public get submitButtonColor(): ThemePalette {
    return this.data.type === DialogType.Delete ? 'warn' : 'accent';
  }

  public get icon(): string {
    return this.data.type === DialogType.Delete ? 'trash' : this.data.icon;
  }
}
