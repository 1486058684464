import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BasicRoutes } from '@windsim/core/enums';
import { AuthenticationService } from '@windsim/core/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = this.authenticationService.authTokenValue;

    if (!this.authenticationService.isTokenExpired() && user) {
      return true;
    }

    await this.router.navigate([BasicRoutes.Login], { queryParams: { returnUrl: state.url } });
    await this.authenticationService.logout();
    return false;
  }
}
