import { Injectable } from '@angular/core';
import { ShaderType } from '@windsim-model/enums';
import { ModelLayer } from '@windsim-model/models';
import * as THREE from 'three';
import { IUniform } from 'three/src/renderers/shaders/UniformsLib';

@Injectable({
  providedIn: 'root',
})
export class ModelShaderService {
  private static get terrainPaletteUniform(): { [uniform: string]: IUniform } {
    return {
      color1: {
        value: new THREE.Color('#9cce31'),
      },
      color2: {
        value: new THREE.Color('#ffe164'),
      },
      color3: {
        value: new THREE.Color('#e1c864'),
      },
      color4: {
        value: new THREE.Color('#c8af64'),
      },
      color5: {
        value: new THREE.Color('#af9664'),
      },
      color6: {
        value: new THREE.Color('#967d64'),
      },
      color7: {
        value: new THREE.Color('#7d644b'),
      },
      color8: {
        value: new THREE.Color('#644b32'),
      },
    };
  }

  private static get roughnessPaletteUniform(): { [uniform: string]: IUniform } {
    return {
      color1: {
        value: new THREE.Color('#ffffff'),
      },
      color2: {
        value: new THREE.Color('#ffff7d'),
      },
      color3: {
        value: new THREE.Color('#ffff00'),
      },
      color4: {
        value: new THREE.Color('#ffbe00'),
      },
      color5: {
        value: new THREE.Color('#ff7d00'),
      },
      color6: {
        value: new THREE.Color('#ff3c00'),
      },
      color7: {
        value: new THREE.Color('#ff0000'),
      },
      color8: {
        value: new THREE.Color('#7d0000'),
      },
    };
  }

  private static getPaletteUniform(shaderType: ShaderType): { [uniform: string]: IUniform } {
    switch (shaderType) {
      case ShaderType.RoughnessLogLayerShader:
      case ShaderType.InclinationLayerShader:
      case ShaderType.SecondOrderDerivativeLayerShader:
      case ShaderType.DeltaElevationLayerShader:
      case ShaderType.RoughnessLayerShader:
        return ModelShaderService.roughnessPaletteUniform;
      case ShaderType.ElevationLayerShader:
      case ShaderType.ExtensionLayerShader:
        return ModelShaderService.terrainPaletteUniform;
      case ShaderType.WireframeShader:
        break;
      default:
        return ModelShaderService.terrainPaletteUniform;
    }
    return ModelShaderService.terrainPaletteUniform;
  }

  private static getBboxUniform(geometry: THREE.BufferGeometry): { [uniform: string]: IUniform } {
    return {
      bboxMin: {
        value: geometry.boundingBox.min,
      },
      bboxMax: {
        value: geometry.boundingBox.max,
      },
    };
  }

  private static getOpacityUniform(opacity: number): { [uniform: string]: IUniform } {
    return {
      opacity: {
        value: opacity ?? 1.0,
      },
    };
  }

  private static createUniforms(layer: ModelLayer, geometry: THREE.BufferGeometry): { [uniform: string]: IUniform } {
    const paletteUniform = ModelShaderService.getPaletteUniform(layer.shader);
    const bboxUniform = ModelShaderService.getBboxUniform(geometry);
    const opacityUniform = ModelShaderService.getOpacityUniform(layer.opacity);

    return {
      ...paletteUniform,
      ...bboxUniform,
      ...opacityUniform,
    };
  }

  private static get vertexShader(): string {
    return `
      uniform vec3 bboxMin;
      uniform vec3 bboxMax;
      varying vec2 vUv;
      void main() {
        vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }`;
  }

  private static createFragmentShader(layers: { [name: string]: { value: number } }): string {
    return `
      uniform vec3 color1;
      uniform vec3 color2;
      uniform vec3 color3;
      uniform vec3 color4;
      uniform vec3 color5;
      uniform vec3 color6;
      uniform vec3 color7;
      uniform vec3 color8;
      uniform float opacity;
      varying vec2 vUv;

      void main() {
        vec4 water  = vec4(color1, 1.0) * (step(-0.1, vUv.y) - step(${layers.water.value}, vUv.y));
        vec4 layer1 = vec4(color2, 1.0) * (step(${layers.water.value}, vUv.y) - step(${layers.layer1.value}, vUv.y));
        vec4 layer2 = vec4(color3, 1.0) * (step(${layers.layer1.value}, vUv.y) - step(${layers.layer2.value}, vUv.y));
        vec4 layer3 = vec4(color4, 1.0) * (step(${layers.layer2.value}, vUv.y) - step(${layers.layer3.value}, vUv.y));
        vec4 layer4 = vec4(color5, 1.0) * (step(${layers.layer3.value}, vUv.y) - step(${layers.layer4.value}, vUv.y));
        vec4 layer5 = vec4(color6, 1.0) * (step(${layers.layer4.value}, vUv.y) - step(${layers.layer5.value}, vUv.y));
        vec4 layer6 = vec4(color7, 1.0) * (step(${layers.layer5.value}, vUv.y) - step(${layers.layer6.value}, vUv.y));
        vec4 layer7 = vec4(color8, 1.0) * (step(${layers.layer6.value}, vUv.y) - step(1.1, vUv.y));
        gl_FragColor = (layer7 + layer6 + layer5 + layer4 + layer3 + layer2 + layer1 + water) * opacity;
      }`;
  }

  private static get elevationFragmentShader(): string {
    const layers = {
      water: { value: 0.1568 },
      layer1: { value: 0.31 },
      layer2: { value: 0.48 },
      layer3: { value: 0.63 },
      layer4: { value: 0.755 },
      layer5: { value: 0.935 },
      layer6: { value: 0.99 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static get extensionFragmentShader(): string {
    const layers = {
      water: { value: 0.125 },
      layer1: { value: 0.247 },
      layer2: { value: 0.365 },
      layer3: { value: 0.51 },
      layer4: { value: 0.63 },
      layer5: { value: 0.75 },
      layer6: { value: 0.88 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static get roughnessFragmentShader(): string {
    const layers = {
      water: { value: 0.05 },
      layer1: { value: 0.2 },
      layer2: { value: 0.4 },
      layer3: { value: 0.6 },
      layer4: { value: 0.8 },
      layer5: { value: 0.9 },
      layer6: { value: 0.99 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static get roughnessLogFragmentShader(): string {
    const layers = {
      water: { value: -0.01 },
      layer1: { value: -0.005 },
      layer2: { value: 0.25 },
      layer3: { value: 0.45 },
      layer4: { value: 0.65 },
      layer5: { value: 0.75 },
      layer6: { value: 1.1 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static get inclinationFragmentShader(): string {
    const layers = {
      water: { value: 0.17 },
      layer1: { value: 0.375 },
      layer2: { value: 0.535 },
      layer3: { value: 0.83 },
      layer4: { value: 0.95 },
      layer5: { value: 0.99 },
      layer6: { value: 1.1 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static get deltaElevationFragmentShader(): string {
    const layers = {
      water: { value: 0.05 },
      layer1: { value: 0.2 },
      layer2: { value: 0.4 },
      layer3: { value: 0.6 },
      layer4: { value: 0.8 },
      layer5: { value: 0.9 },
      layer6: { value: 0.99 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static get secondOrderDerivativeFragmentShader(): string {
    const layers = {
      water: { value: 0.01 },
      layer1: { value: 0.2 },
      layer2: { value: 0.4 },
      layer3: { value: 0.5 },
      layer4: { value: 0.6 },
      layer5: { value: 0.7 },
      layer6: { value: 0.8 },
    };
    return ModelShaderService.createFragmentShader(layers);
  }

  private static getFragmentShader(shaderType: ShaderType): string {
    switch (shaderType) {
      case ShaderType.RoughnessLogLayerShader:
        return this.roughnessLogFragmentShader;
      case ShaderType.InclinationLayerShader:
        return this.inclinationFragmentShader;
      case ShaderType.SecondOrderDerivativeLayerShader:
        return this.secondOrderDerivativeFragmentShader;
      case ShaderType.DeltaElevationLayerShader:
        return this.deltaElevationFragmentShader;
      case ShaderType.RoughnessLayerShader:
        return this.roughnessFragmentShader;
      case ShaderType.ElevationLayerShader:
        return this.elevationFragmentShader;
      case ShaderType.ExtensionLayerShader:
        return this.extensionFragmentShader;
      case ShaderType.WireframeShader:
        break;
      default:
        return this.elevationFragmentShader;
    }
    return this.elevationFragmentShader;
  }

  private static getShaderMaterial(layer: ModelLayer, geometry: THREE.BufferGeometry): THREE.ShaderMaterial {
    const uniforms = ModelShaderService.createUniforms(layer, geometry);
    const { vertexShader } = ModelShaderService;
    const fragmentShader = ModelShaderService.getFragmentShader(layer.shader);
    return new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.DoubleSide,
      transparent: true,
    });
  }

  private static wireframeShader(opacity: number): THREE.MeshBasicMaterial {
    return new THREE.MeshBasicMaterial({
      color: 'black',
      opacity,
      polygonOffset: true,
      polygonOffsetFactor: 1,
      polygonOffsetUnits: 1,
      side: THREE.DoubleSide,
    });
  }

  public getShaderMaterial(layer: ModelLayer, geometry: THREE.BufferGeometry): THREE.ShaderMaterial | THREE.MeshBasicMaterial {
    if (layer.shader === ShaderType.WireframeShader) {
      return ModelShaderService.wireframeShader(layer.opacity);
    }
    return ModelShaderService.getShaderMaterial(layer, geometry);
  }
}
