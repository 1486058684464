import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { ProjectService } from '@windsim-projects/services';
import { BaseComponent } from '@windsim-shared/core';
import { ProjectRole } from '@windsim/core/enums';
import { ApiService, AuthorizationService, StorageService } from '@windsim/core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutocompleteOption } from '@windsim/core/models';

@Component({
  selector: 'app-assign-project-role',
  templateUrl: './assign-project-role.component.html',
})
export class AssignProjectRoleComponent extends BaseComponent implements OnInit {
  assignProjectRoleDialogTitle: string = translationMarker('users.edit-project-role');
  assignProjectRoleForm: FormGroup<{
    optionsList: FormControl<string>;
    projectRole: FormControl<string>;
  }>;

  private assignProjectRoleFormProcessing = new BehaviorSubject<boolean>(true);
  assignProjectRoleFormProcessing$ = this.assignProjectRoleFormProcessing.asObservable();

  filteredOptions$: Observable<AutocompleteOption[]>;

  projectRoles$ = this.authorizationService.projectRoles;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { displayOptionsList: boolean; currentRole: ProjectRole; options: AutocompleteOption[]; optionsListTitle: string },
    private readonly apiService: ApiService,
    private readonly formBuilder: FormBuilder,
    private readonly storageService: StorageService,
    private readonly projectService: ProjectService,
    private readonly authorizationService: AuthorizationService,
  ) {
    super();
  }

  get assignProjectRoleFormControl(): { [key: string]: AbstractControl } {
    return this.assignProjectRoleForm.controls;
  }

  get optionsListFormControl(): FormControl {
    return this.assignProjectRoleFormControl.optionsList as FormControl;
  }

  ngOnInit(): void {
    if (this.data && this.data.displayOptionsList) {
      this.assignProjectRoleDialogTitle = translationMarker('users.assign-project-role');
    }

    this.initForm();
    this.assignProjectRoleFormProcessing.next(false);
    this.filteredOptions$ = this.optionsListFormControl.valueChanges.pipe(
      startWith(''),
      map((name) => (name ? this.filter(this.data.options, name) : this.data.options.slice())),
    );
  }

  displayFn(option: AutocompleteOption): string {
    return option && option.name ? option.name : '';
  }

  private initForm(): void {
    this.assignProjectRoleForm = this.formBuilder.group({
      optionsList: ['', Validators.required],
      projectRole: [this.data.currentRole ? this.data.currentRole.toString() : ProjectRole.ProjectOwner.toString(), Validators.required],
    });
  }

  private filter(options: AutocompleteOption[], value: string): AutocompleteOption[] {
    return options.filter((option) => option.name.toLowerCase().includes(value));
  }
}
