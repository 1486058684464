<nav class="sidenav">
  <h2 class="cdk-visually-hidden">{{'sidenav.title' | translate}}</h2>
  <ul class="sidenav-menu" [ngClass]="{'sidenav-menu--with-status' : hasStatus}" >
    <ng-container *ngFor="let link of links">
      <li class="sidenav-menu__item" *ngIf="link.isVisible" >
        <a
          [routerLink]="link.urlParams ? [link.url, link.urlParams] : [link.url]"
          class="sidenav-menu__link {{link.moduleState | sidenavMenuLinkStatus}}"
          routerLinkActive="sidenav-menu__link--active">
          <app-icon [iconName]="link.icon" iconClassName="icon icon--no-margin icon--extra-large"></app-icon>
          <span>{{link.text | translate}}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>
