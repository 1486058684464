import { Pipe, PipeTransform } from '@angular/core';
import { State } from '@windsim/core/enums';

@Pipe({
  name: 'sidenavMenuLinkStatus',
  pure: true,
})
export class SidenavMenuLinkStatusPipe implements PipeTransform {
  transform(moduleState: State): unknown {
    const linkClass = 'sidenav-menu__link';

    switch (moduleState) {
      case State.Completed:
        return `${linkClass}--success`;
      case State.InProgress:
        return `${linkClass}--in-progress`;
      case State.Failed:
        return `${linkClass}--failed`;
      case State.Cancelled:
        return `${linkClass}--cancelled`;
      case State.Untouched:
        return `${linkClass}--untouched`;
      default:
        return '';
    }
  }
}
