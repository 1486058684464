import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeZhHans from '@angular/common/locales/zh-Hans';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ErrorStateMatcher, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilesInterceptor } from '@windsim-core/interceptors/files.interceptor';
import { environment } from '@windsim-environments/environment';
import { ShowOnDirtyAnTouchedErrorStateMatcher } from '@windsim/core/error-matchers';
import { AzureFunctionsInterceptor } from '@windsim/core/interceptors/azure-functions.interceptor';
import { ErrorInterceptor } from '@windsim/core/interceptors/error.interceptor';
import { JwtInterceptor } from '@windsim/core/interceptors/jwt.interceptor';
import { DataService } from '@windsim/core/services';
import { NotificationModule } from '@windsim/modules/notification/notification.module';
import { SharedModule } from '@windsim/shared/shared.module';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeEn);
registerLocaleData(localeZhHans);

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NotificationModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places'],
    }),
  ],
  providers: [
    DataService,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyAnTouchedErrorStateMatcher },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FilesInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AzureFunctionsInterceptor, multi: true },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en', 'zh-Hans'] } },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true,
        animation: {
          enterDuration: 300,
          exitDuration: 0,
        },
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
