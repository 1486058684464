import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@windsim-core/models';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration$: Observable<AppConfig>;

  constructor(private http: HttpClient) {}

  public load(): Observable<AppConfig> {
    if (!this.configuration$) {
      this.configuration$ = this.http.get<AppConfig>('./assets/configs/app-config.json').pipe(shareReplay(1));
    }
    return this.configuration$;
  }
}
