import { Component, Inject, OnInit } from '@angular/core';
import { ApiUrls, APP_CONFIG, AppConfig } from '@windsim/core/models';

@Component({
  selector: 'app-environment-info',
  templateUrl: './environment-info.component.html',
  styleUrls: ['./environment-info.component.scss'],
})
export class EnvironmentInfoComponent implements OnInit {
  config: AppConfig;
  environmentName: string;
  apiUrls: ApiUrls;

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.config = config;
  }

  ngOnInit(): void {
    this.environmentName = this.config.environmentName;
    this.apiUrls = {
      coreApiUrl: this.config.coreApiUrl,
      converterApiUrl: this.config.converterApiUrl,
      terrainApiUrl: this.config.terrainApiUrl,
      blobStorageUri: this.config.blobStorageUri,
    };
  }
}
