import { Injectable } from '@angular/core';
import { CommonDefaults } from '@windsim-core/configs';
import { StorageService } from '@windsim/core/services/storage.service';
import { ValidationService } from '@windsim/core/services/validation.service';

@Injectable({
  providedIn: 'root',
})
export class BaseLayersService {
  constructor(protected validationService: ValidationService, protected storageService: StorageService) {}

  public flushModelLayers(clientId: string, projectId: string, clearModelLegend = true): void {
    this.validationService.validateStringArgument(clientId, CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.validationService.validateStringArgument(projectId, CommonDefaults.PROJECT_ID_STORAGE_KEY);

    this.storageService.removeValue(clientId, projectId, CommonDefaults.MODEL_LAYERS_STORAGE_KEY);

    if (clearModelLegend) {
      this.storageService.removeValue(clientId, projectId, CommonDefaults.LAYERS_LEGEND_BUFFER_STORAGE_KEY);
    }
  }

  public flushSimulationLayer(clientId: string, projectId: string): void {
    this.validationService.validateStringArgument(clientId, CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.validationService.validateStringArgument(projectId, CommonDefaults.PROJECT_ID_STORAGE_KEY);

    this.storageService.removeValue(clientId, projectId, CommonDefaults.SIMULATION_LAYER_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.FIELD_VALUE_LEGEND_BUFFER_STORAGE_KEY);
  }
}
