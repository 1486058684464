export enum ShaderType {
  RoughnessLayerShader,
  RoughnessLogLayerShader,
  WireframeShader,
  ElevationLayerShader,
  ExtensionLayerShader,
  InclinationLayerShader,
  SecondOrderDerivativeLayerShader,
  DeltaElevationLayerShader,
}
