import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BlobServiceClient } from '@azure/storage-blob';
import { Observable, map } from 'rxjs';

import { SeverityLevel } from '@windsim/core/enums';
import { LoggingService } from '@windsim/core/services/logging.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { APP_CONFIG, AppConfig, BlobSasToken, TraceTelemetry } from '@windsim/core/models';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  private config: AppConfig;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly validationService: ValidationService,
    private readonly httpClient: HttpClient,
    private readonly loggingService: LoggingService,
  ) {
    this.config = config;
  }

  public getProjectInputUploadUri(projectId: string): Observable<string> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(
      `${this.config.coreApiUrl}/${this.config.windfieldForDesktopProject.getProjectInputUploadUri}/${projectId}`,
      {
        headers,
        responseType: 'text',
      },
    );
  }

  public getProjectOutputDownloadUri(projectId: string): Observable<string> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(`${this.config.coreApiUrl}/${this.config.projectEndpoints.getProjectOutputUri}/${projectId}`, {
      headers,
      responseType: 'text',
    });
  }

  public generateBlobDownloadSASUri(projectId: string, fileName: string): Observable<string> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(fileName, 'fileName');

    const dto = {
      projectId,
      fileName,
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .post(`${this.config.coreApiUrl}/${this.config.projectEndpoints.generateBlobDownloadSASUri}`, dto, { headers, responseType: 'text' })
      .pipe(
        map((uri) => {
          return uri;
        }),
      );
  }

  public async uploadProjectInput(sasToken: string, projectFile: ArrayBuffer): Promise<void> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');
    const parsedSasToken = this.parseSasToken(sasToken);
    const blobServiceClient = new BlobServiceClient(`${parsedSasToken.storageUri}?${parsedSasToken.storageAccessToken}`);
    const containerClient = blobServiceClient.getContainerClient(parsedSasToken.container);
    const blockBlobClient = containerClient.getBlockBlobClient(parsedSasToken.fileName);
    const uploadBlobResponse = await blockBlobClient.uploadData(projectFile);
    this.loggingService.consoleLogInfo({ message: 'Blob was uploaded successfully.', value: uploadBlobResponse.requestId });
    const traceTelemetry: TraceTelemetry = {
      message: `Blob was uploaded successfully. Container: ${parsedSasToken.container}`,
      severityLevel: SeverityLevel.Information,
      customProperties: { container: parsedSasToken.container, requestId: uploadBlobResponse.requestId },
    };
    this.loggingService.externalLogTrace(traceTelemetry);
  }

  public async getOutputData(sasToken: string): Promise<ArrayBuffer> {
    this.validationService.validateStringArgument(sasToken, 'sasToken');
    const parsedSasToken = this.parseSasToken(sasToken);

    const blobServiceClient = new BlobServiceClient(`${parsedSasToken.storageUri}/?${parsedSasToken.storageAccessToken}`);

    const containerClient = blobServiceClient.getContainerClient(parsedSasToken.container);

    const blockBlobClient = containerClient.getBlockBlobClient(parsedSasToken.fileName);
    const file = await blockBlobClient.download(0);
    const blob = await file.blobBody;

    return blob.arrayBuffer();
  }

  private parseSasToken(sasToken: string): BlobSasToken {
    const stripedToken = sasToken.replace(`${this.config.blobStorageUri}/`, '');
    const tokenSplit = stripedToken.split('?');
    const tokenParts = tokenSplit[0].split('/');
    const fileName = tokenParts[tokenParts.length - 1];
    const containerName = tokenSplit[0].replace(`/${fileName}`, '').replace(this.config.blobStorageUri, '');

    return {
      storageUri: this.config.blobStorageUri,
      storageAccessToken: tokenSplit[1],
      container: containerName,
      fileName,
    };
  }
}
