import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UpdateStatusDto } from '@windsim/core/dtos';
import { ProjectStatus, SeverityLevel, State } from '@windsim/core/enums';
import { APP_CONFIG, AppConfig, ExceptionTelemetry, WindsimModulesState } from '@windsim/core/models';
import { LoggingService } from '@windsim/core/services/logging.service';
import { ProjectValuesPersistenceService } from '@windsim/core/services/project-values-persistence.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { CommonDefaults } from '@windsim/core/configs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  private config: AppConfig;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService,
    private readonly projectValuesPersistenceService: ProjectValuesPersistenceService,
  ) {
    this.config = config;
  }

  public updateProjectStatus(projectId: string, projectStatus: ProjectStatus): Observable<boolean> {
    const dto: UpdateStatusDto = {
      id: projectId,
      projectStatus,
    };
    return this.http.post<UpdateStatusDto>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.updateStatus}`, dto).pipe(
      catchError((error) => {
        const exceptionTelemetry: ExceptionTelemetry = {
          exception: {
            name: 'BaseProjectService/updateProjectStatus',
            message: error,
          },
          id: projectId,
          severityLevel: SeverityLevel.Critical,
        };
        this.loggingService.externalLogException(exceptionTelemetry);
        this.loggingService.consoleLogError(error);
        return of(false);
      }),
      map((response) => {
        return !!response;
      }),
    );
  }

  public getOverallApplicationState(clientId: string, projectId: string): Observable<ProjectStatus> {
    const projectState$ = this.projectValuesPersistenceService.fetchProjectState(clientId, projectId);
    return projectState$.pipe(
      map((result) => {
        if (result === null) {
          throw new Error();
        }
        return result;
      }),
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError(() => of(null)),
      map((result) => {
        if (!result) {
          return ProjectStatus.None;
        }

        const applicationState = JSON.parse(result) as WindsimModulesState;

        if (applicationState.modulesState.every((module) => module.state === State.Untouched)) {
          return ProjectStatus.None;
        }

        if (applicationState.modulesState.every((module) => module.state === State.Completed)) {
          return ProjectStatus.Completed;
        }

        if (applicationState.modulesState.some((module) => module.state === State.Failed)) {
          return ProjectStatus.Failed;
        }

        if (applicationState.modulesState.some((module) => module.state === State.Cancelled)) {
          return ProjectStatus.Cancelled;
        }

        if (applicationState.modulesState.some((module) => module.state === State.InProgress || module.state === State.Untouched)) {
          return ProjectStatus.InProgress;
        }
        return ProjectStatus.None;
      }),
    );
  }
}
