import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelFilesService } from '@windsim-model/services/model-files.service';
import { BaseLoaderService } from '@windsim/core/services/base-loader.service';
import { ArrayService } from './array.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})
export class ModelLoaderService extends BaseLoaderService {
  constructor(
    private readonly http: HttpClient,
    arrayService: ArrayService,
    utilitiesService: UtilitiesService,
    modelFilesService: ModelFilesService,
  ) {
    super(arrayService, utilitiesService, modelFilesService);
  }
}
