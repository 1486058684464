import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoaderMessageType } from '@windsim/core/enums/loader-message-type.enum';
import { APP_CONFIG, AppConfig } from '@windsim/core/models';
import { Loader } from '@windsim/core/models/loader.model';
import { DataService } from '@windsim/core/services/data.service';
import { Observable } from 'rxjs';

@Injectable()
export class AzureFunctionsInterceptor implements HttpInterceptor {
  private config: AppConfig;
  private requests: HttpRequest<any>[] = [];
  private loader: Loader;

  constructor(@Inject(APP_CONFIG) config: AppConfig, private readonly dataService: DataService) {
    this.config = config;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const converterAzureFunctionUrl = this.config.azureFunctions.windsimFieldValueConverter.split('?')[0];
    const isWindsimConverterAzureFunctionUrl = request.url.startsWith(converterAzureFunctionUrl);

    if (isWindsimConverterAzureFunctionUrl) {
      this.requests.push(request);

      const messageType = this.getLoaderMessageType(request);

      this.loader = {
        shouldBeVisible: true,
        loaderMessageType: messageType,
      };

      this.dataService.showLoader(this.loader);

      return new Observable((observer) => {
        const subscription = next.handle(request).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.removeRequest(request);
              observer.next(event);
            }
          },
          (err) => {
            this.removeRequest(request);
            observer.error(err);
          },
          () => {
            this.removeRequest(request);
            observer.complete();
          },
        );
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(request);
          subscription.unsubscribe();
        };
      });
    }
    return next.handle(request);
  }

  private getLoaderMessageType(request: HttpRequest<any>): LoaderMessageType | null {
    const converterAzureFunctionUrl = this.config.azureFunctions.windsimFieldValueConverter.split('?')[0];
    if (request.url.startsWith(converterAzureFunctionUrl)) {
      return LoaderMessageType.StartingAzureFunction;
    }
    return null;
  }

  private removeRequest(request: HttpRequest<any>) {
    const requestIndex = this.requests.indexOf(request);
    if (requestIndex !== -1) {
      this.requests.splice(requestIndex, 1);
    }

    this.requests.length > 0 ? this.dataService.showLoader(this.loader) : this.dataService.hideLoader(this.loader);
  }
}
