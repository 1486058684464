import { Pipe, PipeTransform } from '@angular/core';
import { AlertType } from '@windsim/core/enums';
import { Alert } from '@windsim/core/models';

@Pipe({
  name: 'alertCssClass',
  pure: true,
})
export class AlertCssClassPipe implements PipeTransform {
  transform(alert: Alert): unknown {
    if (!alert) {
      return '';
    }
    switch (alert.type) {
      case AlertType.Error:
        return 'alert alert--error';
      case AlertType.Success:
        return 'alert alert--success';
      default:
        return '';
    }
  }
}
