import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectVisibility } from '@windsim/core/enums';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { ProjectVisibilityType } from '@windsim/core/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '@windsim-projects/models';

@Component({
  selector: 'app-assign-project-visibility',
  templateUrl: './set-project-visibility.component.html',
})
export class SetProjectVisibilityComponent implements OnInit {
  setProjectVisibilityForm: FormGroup<{
    projectVisibility: FormControl<ProjectVisibility>;
  }>;

  projectVisibilityTypes: ProjectVisibilityType[] = [
    { label: translationMarker('global.private'), enumValue: ProjectVisibility.Private },
    { label: translationMarker('global.shared'), enumValue: ProjectVisibility.Shared },
  ];

  constructor(private readonly formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private project: Project) {}

  ngOnInit(): void {
    this.initForm(this.project);
  }

  get setProjectVisibilityFormControl(): { [key: string]: AbstractControl } {
    return this.setProjectVisibilityForm.controls;
  }

  get projectVisibilityFormControl(): FormControl {
    return this.setProjectVisibilityFormControl.projectVisibility as FormControl;
  }

  private initForm(project: Project): void {
    this.setProjectVisibilityForm = this.formBuilder.group({
      projectVisibility: [project.isPrivate ? ProjectVisibility.Private : ProjectVisibility.Shared, Validators.required],
    });
  }
}
