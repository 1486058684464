import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModelDefaults {
  public static readonly AUTOMATIC_HEIGHT_ABOVE_TERRAIN = -999;
  public static readonly HEIGHT_ABOVE_TERRAIN_SELECT_TYPE = 0;
  public static readonly HEIGHT_ABOVE_TERRAIN = 1500;
  public static readonly RESOLUTION_TYPE = 0;
  public static readonly HORIZONTAL_RESOLUTION = 10;
  public static readonly MAXIMUM_NUMBER_OF_CELLS = 10000000;
  public static readonly HEIGHT_DISTRIBUTION_FACTOR_ARITHMETICAL = 0.1;
  public static readonly HEIGHT_DISTRIBUTION_FACTOR_GEOMETRICAL = 0.3;

  public static readonly NUMBER_OF_CELLS_IN_Z_DIRECTION = 30;
  public static readonly SMOOTHING_TYPE = 0;
  public static readonly FOREST = 0;

  public static readonly HEIGHT_ABOVE_TERRAIN_MAX = 10000;
  public static readonly HEIGHT_ABOVE_TERRAIN_MIN = 0;
  public static readonly MAXIMUM_NUMBER_OF_CELLS_MIN = 1;
  public static readonly HEIGHT_DISTRIBUTION_FACTOR_MAX = 1;
  public static readonly HEIGHT_DISTRIBUTION_FACTOR_MIN = 0;
  public static readonly NUMBER_OF_CELLS_IN_Z_DIRECTION_MAX = 1000;
  public static readonly NUMBER_OF_CELLS_IN_Z_DIRECTION_MIN = 0;
  public static readonly HORIZONTAL_RESOLUTION_MAX = 500;
  public static readonly HORIZONTAL_RESOLUTION_MIN = 10;

  public static readonly SELECTED_LAYERS_LIMIT = 2;

  public static readonly LOADING_MODEL_TERRAIN_LAYER_MESSAGE_ERROR_ID = 'loading-model-terrain-layer-error-message';
  public static readonly LOADING_MODEL_DATA_LAYER_MESSAGE_ERROR_ID = 'loading-model-data-layer-error-message';
  public static readonly LOADING_FOREST_LAYER_MESSAGE_ERROR_ID = 'loading-forest-layer-error-message';
}
