import { Injectable } from '@angular/core';
import { ModelLayer } from '@windsim-model/models/model-layer.model';
import { SimulationLayer } from '@windsim-simulation/models/simulation-layer.model';
import { SignalrStatus, Status } from '@windsim/core/enums';
import { ProjectSimulationStatus, SignalrMessage, MapMessage, SimulationsFormStorage } from '@windsim/core/models';
import { Layer } from '@windsim/core/models/layer.model';
import { Loader } from '@windsim/core/models/loader.model';
import { Sidebar } from '@windsim/shared/components/ui-elements/sidenav/models/sidebar.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private datasetFileUploadedMessage = new BehaviorSubject<boolean>(false);
  datasetFileUploadedMessage$ = this.datasetFileUploadedMessage.asObservable();

  private markersMessage = new BehaviorSubject<boolean>(false);
  markersMessage$ = this.markersMessage.asObservable();

  private pointMessage = new BehaviorSubject<boolean>(false);
  pointMessage$ = this.pointMessage.asObservable();

  private resetDatasetsMessage = new BehaviorSubject<boolean>(false);
  resetDatasetsMessage$ = this.resetDatasetsMessage.asObservable();

  private fetchingDatasetsMessage = new BehaviorSubject<boolean>(false);
  fetchingDatasetsMessage$ = this.fetchingDatasetsMessage.asObservable();

  private refreshProjectsListMessage = new BehaviorSubject<boolean>(false);
  refreshProjectsListMessage$ = this.refreshProjectsListMessage.asObservable();

  private singleProjectContextMessage = new BehaviorSubject<boolean>(false);
  singleProjectContextMessage$ = this.singleProjectContextMessage.asObservable();

  private toggleModelLayerMessage = new BehaviorSubject<any>(undefined);
  toggleModelLayerMessage$ = this.toggleModelLayerMessage.asObservable();

  private revertModelLayersMessage = new BehaviorSubject<any>(undefined);
  revertModelLayersMessage$ = this.revertModelLayersMessage.asObservable();

  private toggleSimulationLayerMessage = new BehaviorSubject<any>(undefined);
  toggleSimulationLayerMessage$ = this.toggleSimulationLayerMessage.asObservable();

  private takeModelScreenshotMessage = new BehaviorSubject<boolean>(false);
  takeModelScreenshotMessage$ = this.takeModelScreenshotMessage.asObservable();

  private loadingModelLayerMessage = new BehaviorSubject<any>(undefined);
  loadingModelLayerMessage$ = this.loadingModelLayerMessage.asObservable();

  private changeLayerOpacityMessage = new BehaviorSubject<any>(undefined);
  changeLayerOpacityMessage$ = this.changeLayerOpacityMessage.asObservable();

  private toggleLayerDataGridMessage = new BehaviorSubject<any>(undefined);
  toggleLayerDataGridMessage$ = this.toggleLayerDataGridMessage.asObservable();

  private sidebarIsOpenMessage = new BehaviorSubject<any>(undefined);
  sidebarIsOpenMessage$ = this.sidebarIsOpenMessage.asObservable();

  private signalrHubMessage = new BehaviorSubject<any>(undefined);
  signalrHubMessage$ = this.signalrHubMessage.asObservable();

  private mapMessage = new BehaviorSubject<any>(undefined);
  mapMessage$ = this.mapMessage.asObservable();

  private modelFilesGeneratedMessage = new BehaviorSubject<boolean>(false);
  modelFilesGeneratedMessage$ = this.modelFilesGeneratedMessage.asObservable();

  private simulationStatusMessage = new BehaviorSubject<ProjectSimulationStatus>(undefined);
  simulationStatusMessage$ = this.simulationStatusMessage.asObservable();

  private simulationFormIsProcessingMessage = new BehaviorSubject<boolean>(false);
  simulationFormIsProcessingMessage$ = this.simulationFormIsProcessingMessage.asObservable();

  private sectorCalculationCompletedMessage = new BehaviorSubject<string>('');
  sectorCalculationCompletedMessage$ = this.sectorCalculationCompletedMessage.asObservable();

  private refreshSimulationJobsStatusListMessage = new BehaviorSubject<boolean>(false);
  refreshSimulationJobsStatusListMessage$ = this.refreshSimulationJobsStatusListMessage.asObservable();

  private refreshApplicationModulesStateMessage = new BehaviorSubject<boolean>(false);
  refreshApplicationModulesStateMessage$ = this.refreshApplicationModulesStateMessage.asObservable();

  private isLoadingMessage = new BehaviorSubject<any>(undefined);
  isLoadingMessage$ = this.isLoadingMessage.asObservable();

  private toggleNotificationPanelMessage = new BehaviorSubject<boolean>(false);
  toggleNotificationPanelMessage$ = this.toggleNotificationPanelMessage.asObservable();

  private coreLogsCounterMessage = new BehaviorSubject<number>(0);
  coreLogsCounterMessage$ = this.coreLogsCounterMessage.asObservable();

  private initialsUpdatedMessage = new BehaviorSubject<boolean>(false);
  initialsUpdatedMessage$ = this.initialsUpdatedMessage.asObservable();

  private resetSimulationLayersMessage = new BehaviorSubject<boolean>(false);
  resetSimulationLayersMessage$ = this.resetSimulationLayersMessage.asObservable();

  private resetModelLayersMessage = new BehaviorSubject<boolean>(false);
  resetModelLayersMessage$ = this.resetModelLayersMessage.asObservable();

  private refreshMapDependentComponentsMessage = new BehaviorSubject<boolean>(false);
  refreshMapDependentComponentsMessage$ = this.refreshMapDependentComponentsMessage.asObservable();

  private generatePostmanCollectionMessage = new BehaviorSubject<boolean>(false);
  generatePostmanCollectionMessage$ = this.generatePostmanCollectionMessage.asObservable();

  private copyRequestAsJsonMessage = new BehaviorSubject<boolean>(false);
  copyRequestAsJsonMessage$ = this.copyRequestAsJsonMessage.asObservable();

  private userEmailMessage = new BehaviorSubject<any>(undefined);
  userEmailMessage$ = this.userEmailMessage.asObservable();

  private modelIsProcessingMessage = new BehaviorSubject<boolean>(false);
  modelIsProcessingMessage$ = this.modelIsProcessingMessage.asObservable();

  private mapStatusMessage = new BehaviorSubject<any>(undefined);
  mapStatusMessage$ = this.mapStatusMessage.asObservable();

  private signalrStatusMessage = new BehaviorSubject<SignalrStatus>(SignalrStatus.Disconnected);
  signalrStatusMessage$ = this.signalrStatusMessage.asObservable();

  private reconnectSignalrMessage = new BehaviorSubject<boolean>(false);
  reconnectSignalrMessage$ = this.reconnectSignalrMessage.asObservable();

  private utilizationPanelVisibleOnMobileMessage = new BehaviorSubject<boolean>(false);
  utilizationPanelVisibleOnMobileMessage$ = this.utilizationPanelVisibleOnMobileMessage.asObservable();

  private simulationFormValuesMessage = new BehaviorSubject<SimulationsFormStorage>(undefined);
  simulationFormValuesMessage$ = this.simulationFormValuesMessage.asObservable();

  private refreshNumberOfProjectsMessage = new BehaviorSubject<boolean>(false);
  refreshNumberOfProjectsMessage$ = this.refreshNumberOfProjectsMessage.asObservable();

  private deleteAnimationStatusFileMessage = new BehaviorSubject<boolean>(false);
  deleteAnimationStatusFileMessage$ = this.deleteAnimationStatusFileMessage.asObservable();

  private hideSimulationLayerContentMessage = new BehaviorSubject<boolean>(false);
  hideSimulationLayerContentMessage$ = this.hideSimulationLayerContentMessage.asObservable();

  private setSimulationLayerContentOnTheFlyModeMessage = new BehaviorSubject<boolean>(false);
  setSimulationLayerContentOnTheFlyModeMessage$ = this.setSimulationLayerContentOnTheFlyModeMessage.asObservable();

  private currentProjectNameMessage = new BehaviorSubject<string>('');
  currentProjectNameMessage$ = this.currentProjectNameMessage.asObservable();

  private refreshProjectSizeMessage = new BehaviorSubject<boolean>(false);
  refreshProjectSizeMessage$ = this.refreshProjectSizeMessage.asObservable();

  datasetFileUploaded(status: boolean): void {
    this.datasetFileUploadedMessage.next(status);
  }

  updateMarkersList(): void {
    this.markersMessage.next(true);
  }

  updatePoint(): void {
    this.pointMessage.next(true);
  }

  resetDatasets(): void {
    this.resetDatasetsMessage.next(true);
  }

  fetchingDatasets(status: boolean): void {
    this.fetchingDatasetsMessage.next(status);
  }

  refreshProjectsList(): void {
    this.refreshProjectsListMessage.next(true);
  }

  toggleModelLayer(layer: ModelLayer): void {
    this.toggleModelLayerMessage.next(layer);
  }

  revertModelLayers(layers: ModelLayer[]): void {
    this.revertModelLayersMessage.next(layers);
  }

  toggleSimulationLayer(layer: SimulationLayer): void {
    this.toggleSimulationLayerMessage.next(layer);
  }

  takeModelScreenshot(status: boolean): void {
    this.takeModelScreenshotMessage.next(status);
  }

  loadingModelLayer(layerName: string): void {
    this.loadingModelLayerMessage.next(layerName);
  }

  changeLayerOpacity(layer: Layer): void {
    this.changeLayerOpacityMessage.next(layer);
  }

  toggleLayerDataGrid(layer: Layer): void {
    this.toggleLayerDataGridMessage.next(layer);
  }

  toggleSidebar(sidebar: Sidebar): void {
    this.sidebarIsOpenMessage.next(sidebar);
  }

  setSingleProjectContext(value: boolean): void {
    this.singleProjectContextMessage.next(value);
  }

  receivedSignalrHubMessage(message: SignalrMessage): void {
    this.signalrHubMessage.next(message);
  }

  receiveMapMessage(message: MapMessage): void {
    this.mapMessage.next(message);
  }

  modelFilesAreGenerated(value: boolean): void {
    this.modelFilesGeneratedMessage.next(value);
  }

  setSimulationStatus(projectId: string, simulationStatus: Status): void {
    this.simulationStatusMessage.next({ projectId, simulationStatus });
  }

  simulationFormIsProcessing(value: boolean): void {
    this.simulationFormIsProcessingMessage.next(value);
  }

  sectorCalculationCompleted(projectId: string): void {
    this.sectorCalculationCompletedMessage.next(projectId);
  }

  refreshSimulationJobsStatusList(): void {
    this.refreshSimulationJobsStatusListMessage.next(true);
  }

  refreshApplicationModulesState(): void {
    this.refreshApplicationModulesStateMessage.next(true);
  }

  showLoader(loader: Loader): void {
    loader.shouldBeVisible = true;
    this.isLoadingMessage.next(loader);
  }

  hideLoader(loader: Loader): void {
    loader.shouldBeVisible = false;
    this.isLoadingMessage.next(loader);
  }

  resetSimulationLayers(): void {
    this.resetSimulationLayersMessage.next(true);
  }

  resetModelLayers(): void {
    this.resetModelLayersMessage.next(true);
  }

  refreshMapDependentComponents(): void {
    this.refreshMapDependentComponentsMessage.next(true);
  }

  toggleNotificationPanel(value: boolean): void {
    this.toggleNotificationPanelMessage.next(value);
  }

  updateCoreLogsCounter(value: number): void {
    this.coreLogsCounterMessage.next(value);
  }

  initialsUpdated(): void {
    this.initialsUpdatedMessage.next(true);
  }

  generatePostmanCollection(message: boolean): void {
    this.generatePostmanCollectionMessage.next(message);
  }

  copyRequestAsJson(message: boolean): void {
    this.copyRequestAsJsonMessage.next(message);
  }

  setUserEmail(message: string): void {
    this.userEmailMessage.next(message);
  }

  modelIsProcessing(value: boolean): void {
    this.modelIsProcessingMessage.next(value);
  }

  receiveMapStatus(value: any): void {
    this.mapStatusMessage.next(JSON.stringify(value));
  }

  setSignalrStatus(status: SignalrStatus): void {
    this.signalrStatusMessage.next(status);
  }

  reconnectSignalr(): void {
    this.reconnectSignalrMessage.next(true);
  }

  toggleUtilizationPanelOnMobile(message: boolean): void {
    this.utilizationPanelVisibleOnMobileMessage.next(message);
  }

  setSimulationFormValues(values: SimulationsFormStorage): void {
    this.simulationFormValuesMessage.next(values);
  }

  refreshNumberOfProjects(): void {
    this.refreshNumberOfProjectsMessage.next(true);
  }

  deleteAnimationStatusFile(): void {
    this.deleteAnimationStatusFileMessage.next(true);
  }

  hideSimulationLayerContent(): void {
    this.hideSimulationLayerContentMessage.next(true);
  }

  setSimulationLayerContentOnTheFlyMode(): void {
    this.setSimulationLayerContentOnTheFlyModeMessage.next(true);
  }

  setCurentProjectName(projectName: string): void {
    this.currentProjectNameMessage.next(projectName);
  }

  refreshProjectSize(): void {
    this.refreshProjectSizeMessage.next(true);
  }
}
