import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Animations } from '@windsim/core/animations';
import { FormService } from '@windsim/core/services';

@Component({
  selector: 'app-numerical-control',
  templateUrl: './numerical-control.component.html',
  styleUrls: ['./numerical-control.component.scss'],
  animations: [Animations.inOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericalControlComponent implements OnInit {
  @Input() label: string;
  @Input() className: string;
  @Input() numericalFormControl: FormControl;
  @Input() step: number = null;
  @Input() disabled = false;
  @Input() disableErrorMessage = false;
  @Output() valueChangeEvent = new EventEmitter<number>();
  isRequired: boolean;
  id: string;

  constructor(private readonly formService: FormService) {}

  ngOnInit() {
    this.isRequired = this.formService.isRequired(this.numericalFormControl);
    this.id = this.formService.getFormControlId(this.numericalFormControl);
    if (this.disabled) {
      this.numericalFormControl.disable();
    }
  }

  get errorMessage(): string {
    const { errors } = this.numericalFormControl;
    return this.formService.errorMessage(errors);
  }

  public onInputEvent(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    this.valueChangeEvent.emit(Number(element.value));
  }
}
