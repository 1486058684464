import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'logMessageFormat',
  pure: true,
})
export class LogMessageFormatPipe implements PipeTransform {
  @memo()
  transform(text: string): string {
    const separatorSymbol = ':';
    const newLineUnicodeSymbol = '\u000A';
    const result = text
      .replace(/[.]{2,}/g, '')
      .split(separatorSymbol)
      .join(`${separatorSymbol}${newLineUnicodeSymbol}`);
    return result;
  }
}
