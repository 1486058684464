import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataService, ModelLegendService, ThreeService } from '@windsim-core/services';
import { Sidebar } from '@windsim-shared/ui-elements/sidenav/models/sidebar.model';
import { ModelLegend } from '@windsim/core/models';
import { BaseComponent } from '@windsim/shared/components/core/base/base.component';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasComponent extends BaseComponent implements OnInit {
  @ViewChild('primaryRendererCanvas', { static: true }) primaryCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('hudCanvas', { static: true }) hudCanvas: ElementRef<HTMLCanvasElement>;
  @Input() isLoading: boolean;
  @Input() filesProcessingCompleted = true;
  resizeCanvas: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(
    private readonly threeService: ThreeService,
    private readonly dataService: DataService,
    private readonly modelLegendService: ModelLegendService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.resizeCanvasWithDelay();
    this.sidebarStateChangeSubscription();
    this.subscribeTakeModelScreenshotMessage();
  }

  public drawLegend(modelLegend: ModelLegend): void {
    this.modelLegendService.drawLegend(this.hudCanvas, modelLegend);
  }

  public clearLegend(): void {
    this.modelLegendService.clearLegend(this.hudCanvas);
  }

  public initializeCanvas(): void {
    this.threeService.startRendering(this.primaryCanvas);
  }

  public resizeCanvasWithDelay(delay = 10): void {
    this.resizeCanvas
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(delay),
        distinctUntilChanged(),
        filter(() => this.filesProcessingCompleted),
        tap(() => {
          this.threeService.onResize();
        }),
      )
      .subscribe();
  }

  private takeScreenshot() {
    this.threeService.takeScreenshot(this.hudCanvas.nativeElement);
    this.dataService.takeModelScreenshot(false);
  }

  private sidebarStateChangeSubscription(): void {
    const { sidebarIsOpenMessage$ } = this.dataService;
    sidebarIsOpenMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((sidebar: Sidebar) => {
      if (sidebar) {
        this.resizeCanvasWithDelay();
      }
    });
  }

  private subscribeTakeModelScreenshotMessage(): void {
    const { takeModelScreenshotMessage$ } = this.dataService;
    takeModelScreenshotMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((message) => {
      if (message) {
        this.takeScreenshot();
      }
    });
  }
}
