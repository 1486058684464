<div class="dialog {{additionalClassName}}">
  <h2 mat-dialog-title class="dialog__title">
    <app-icon [iconName]="icon" iconClassName="icon icon--large"  class="dialog__title-icon"></app-icon>
    <span class="dialog__title-text">{{title}}</span>
  </h2>
  <mat-dialog-content class="dialog__content">
    <ng-content></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog__actions">
    <ng-content select="[dialogActions]"></ng-content>
  </mat-dialog-actions>
</div>
