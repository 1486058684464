import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@windsim-environments/environment';

import { AppModule } from '@windsim/app.module';
import { APP_CONFIG } from '@windsim/core/models';

if (environment.production) {
  enableProdMode();
}

fetch('/assets/configs/app-config.json')
  .then((response) => response.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  });
