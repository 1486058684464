import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonDefaults } from '@windsim/core/configs';
import { AccountType, UserRole } from '@windsim/core/enums';
import { APP_CONFIG, AppConfig, User, UserProjectRole } from '@windsim/core/models';
import { ApiService } from '@windsim/core/services/api.service';
import { AuthenticationService } from '@windsim/core/services/authentication.service';
import { LoggingService } from '@windsim/core/services/logging.service';
import { StorageService } from '@windsim/core/services/storage.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { UserDto } from '@windsim/modules/organization/dtos/user-dto.model';
import { ChangePassword } from '@windsim/modules/profile/models';
import { CorporateUserDto } from '@windsim/modules/user/dtos/corporate-user-dto.model';
import { DeleteUserDto } from '@windsim/modules/user/dtos/delete-user-dto.model';
import { EditUserDto } from '@windsim/modules/user/dtos/edit-user-dto.model';
import { InternalCorporateUserDto } from '@windsim/modules/user/dtos/internal-corporate-user-dto.model';
import { RegularUserDto } from '@windsim/modules/user/dtos/regular-user-dto.model';
import { InvitedUser, NewPassword } from '@windsim/modules/user/models';

import { retryBackoff } from 'backoff-rxjs';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private config: AppConfig;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly validationService: ValidationService,
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService,
    private readonly apiService: ApiService,
    private readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.config = config;
  }

  public get userId(): string {
    if (!this.authenticationService.decodedToken) {
      return '';
    }
    return this.authenticationService.decodedToken.Id;
  }

  public getUserDetails(): Observable<boolean | User> {
    if (!this.userId) {
      return of(false);
    }
    return this.http.get<UserDto>(`${this.config.coreApiUrl}/${this.config.userEndpoints.user}/${this.userId}`).pipe(
      map((result: UserDto) => {
        const user: User = {
          userId: result.userId,
          name: result.firstName,
          surname: result.lastName,
          email: result.email,
          accountType: result.accountType,
          phoneNumber: result.phoneNumber,
          countryCode: result.countryCode,
          organization: {
            name: result.organizationName,
          },
        };
        return user;
      }),
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('profile.fetching-user-details-error'))),
    );
  }

  public getUserInitials(): Observable<string> {
    return this.getUserDetails().pipe(
      map((result) => {
        if (result) {
          const user = result as User;
          const firstName = user.name ?? '';
          const lastName = user.surname ?? '';
          return firstName.trimStart().charAt(0).toUpperCase() + lastName.trimStart().charAt(0).toUpperCase() || 'U';
        }
        return 'U';
      }),
    );
  }

  public get isAdmin(): boolean {
    const token = this.authenticationService.decodedToken;
    if (!token) {
      return false;
    }
    return (
      (token.role.includes(UserRole.SuperAdmin) || token.role.includes(UserRole.Admin)) && token.AccountType === AccountType.CorporateUser
    );
  }

  public get isCorporateUser(): boolean {
    const token = this.authenticationService.decodedToken;
    if (!token) {
      return false;
    }
    return token.AccountType === AccountType.CorporateUser;
  }

  public get isUser(): boolean {
    const token = this.authenticationService.decodedToken;
    if (!token) {
      return false;
    }
    return token.role.includes(UserRole.User);
  }

  public get isIndividualUser(): boolean {
    if (!this.authenticationService.decodedToken) {
      return false;
    }
    return this.authenticationService.decodedToken.AccountType === AccountType.IndividualUser;
  }

  public sendResetPasswordEmail(email: string): Observable<boolean> {
    const dto = {
      email,
    };

    return this.http.post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.forgotPassword}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public resetPassword(newPassword: NewPassword): Observable<boolean> {
    return this.http.post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.resetPassword}`, newPassword).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public changePassword(changePassword: ChangePassword): Observable<boolean> {
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.changePasswordForLoggedInUser}`, changePassword)
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public getInvitedUserInformation(token: string): Observable<InvitedUser> {
    return this.http.get<InvitedUser>(`${this.config.coreApiUrl}/${this.config.userEndpoints.invitedUserInformation}/${token}`).pipe(
      map((response) => {
        if (response) {
          return {
            email: response.email,
            organizationName: response.organizationName,
          };
        }
        return null;
      }),
    );
  }

  public registerRegularUser(user: User): Observable<boolean> {
    const dto: RegularUserDto = {
      accountType: AccountType.IndividualUser,
      email: user.email,
      password: user.password,
      firstName: user.name,
      lastName: user.surname,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
    };

    return this.http.post<RegularUserDto>(`${this.config.coreApiUrl}/${this.config.authenticationEndpoints.registerRegularUser}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public registerCorporateUser(user: User): Observable<boolean> {
    const dto: CorporateUserDto = {
      organizationName: user.organization.name,
      accountType: AccountType.CorporateUser,
      email: user.email,
      password: user.password,
      firstName: user.name,
      lastName: user.surname,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
    };

    return this.http
      .post<CorporateUserDto>(`${this.config.coreApiUrl}/${this.config.authenticationEndpoints.registerCorporateUser}`, dto)
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public registerInternalCorporateUser(user: User): Observable<boolean> {
    const dto: InternalCorporateUserDto = {
      accountType: AccountType.CorporateUser,
      password: user.password,
      firstName: user.name,
      lastName: user.surname,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
      invitationToken: user.invitationToken,
    };

    return this.http
      .post<InternalCorporateUserDto>(`${this.config.coreApiUrl}/${this.config.authenticationEndpoints.registerInternalCorporateUser}`, dto)
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public activateUserAccount(activationId: string): Observable<boolean> {
    this.validationService.validateStringArgument(activationId, 'activationId');
    this.loggingService.consoleLogInfo({ message: 'activateUserAccount', value: activationId });
    return new Observable((subscriber) => {
      subscriber.next(true);
    });
  }

  public editUserDetails(user: User): Observable<boolean> {
    const dto: EditUserDto = {
      id: user.userId,
      firstName: user.name,
      lastName: user.surname,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
    };

    return this.http.post<EditUserDto>(`${this.config.coreApiUrl}/${this.config.userEndpoints.update}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public changeEmail(user: User): Observable<boolean> {
    this.loggingService.consoleLogInfo({ message: 'changeEmail', value: user });
    return of(true).pipe(delay(1000));
  }

  public deleteUserAccount(userId: string): Observable<boolean> {
    const dto: DeleteUserDto = {
      id: userId,
    };
    return this.http.post<DeleteUserDto>(`${this.config.coreApiUrl}/${this.config.userEndpoints.delete}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public getUserProjectRoles(userId: string): Observable<boolean | UserProjectRole[]> {
    return this.http
      .get<UserProjectRole[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getProjectUserRoles}/${userId}`)
      .pipe(
        retryBackoff({
          initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
          maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
          resetOnSuccess: true,
        }),
        catchError((error) => this.apiService.errorHandler(error, translationMarker('user.fetching-user-projects-error'))),
      );
  }
}
