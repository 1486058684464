<div class="numerical-control {{className}}">

  <label class="numerical-control__label" [for]="id">
    <span class="numerical-control__label-title" [innerHTML]="label"></span>
    <input
      class="numerical-control__input"
      type="number"
      [id]="id"
      [formControl]="numericalFormControl"
      [value]="numericalFormControl.value"
      [required]="isRequired ? isRequired : null"
      [attr.step]="step"
      (input)="onInputEvent($event)"
    >
  </label>

  <p *ngIf="!disableErrorMessage && numericalFormControl.errors" [@inOutAnimation] class="numerical-control__error">
    {{errorMessage | translate}}
  </p>
</div>
