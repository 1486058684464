import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@windsim-core/services';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit {
  constructor(private languageService: LanguageService) {}
  ngOnInit() {
    this.languageService.initialize();
  }

  changeLanguage(languageCode): void {
    this.languageService.setUserSelectedLanguageCode(languageCode);
  }
}
