import { Injectable } from '@angular/core';
import { CommonDefaults } from '@windsim-core/configs';
import { ProjectStatus, State, Status, WindsimModule } from '@windsim-core/enums';
import { WindsimModulesState, WindsimModuleState } from '@windsim-core/models';
import { StorageService } from '@windsim-core/services/storage.service';
import { ValidationService } from '@windsim-core/services/validation.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationModulesStateService {
  constructor(private readonly storageService: StorageService, private readonly validationService: ValidationService) {}

  public isWindsimModuleCompleted(clientId: string, projectId: string, module: WindsimModule): boolean {
    const applicationState = this.getApplicationModulesStates(clientId, projectId);
    if (applicationState) {
      const mapModuleState = applicationState.modulesState.filter((ms) => ms.windsimModule === module);
      return mapModuleState.length > 0 && mapModuleState[0].state === State.Completed;
    }
    return false;
  }

  public setWindsimModuleState(clientId: string, projectId: string, windsimModule: WindsimModule, state: State): void {
    let applicationState: WindsimModulesState = { modulesState: [] };
    const storedApplicationState = this.getApplicationModulesStates(clientId, projectId);
    if (storedApplicationState) {
      applicationState = storedApplicationState;
      const moduleIndex = applicationState.modulesState.findIndex((ms) => ms.windsimModule === windsimModule);
      if (moduleIndex !== -1) {
        applicationState.modulesState[moduleIndex].state = state;
      } else {
        const moduleState: WindsimModuleState = { windsimModule, state };
        applicationState.modulesState.push(moduleState);
      }
    } else {
      const moduleState: WindsimModuleState = { windsimModule, state };
      applicationState.modulesState.push(moduleState);
    }
    this.storageService.storeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY, applicationState);
  }

  public hasWindsimModuleState(clientId: string, projectId: string, windsimModule: WindsimModule): boolean {
    const applicationState = this.getApplicationModulesStates(clientId, projectId);
    if (applicationState) {
      const moduleIndex = applicationState.modulesState.findIndex((ms) => ms.windsimModule === windsimModule);
      return moduleIndex !== -1;
    }
    return false;
  }

  public getApplicationModulesStates(clientId: string, projectId: string): WindsimModulesState {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    const applicationState = this.storageService.getValue(
      clientId,
      projectId,
      CommonDefaults.APPLICATION_STATE_STORAGE_KEY,
    ) as WindsimModulesState;
    if (applicationState) {
      return applicationState;
    }
    return null;
  }

  public setModuleInitialState(clientId: string, projectId: string, windsimModule: WindsimModule): void {
    if (!this.hasWindsimModuleState(clientId, projectId, windsimModule)) {
      this.setWindsimModuleState(clientId, projectId, windsimModule, State.Untouched);
    }
  }

  public getApplicationModuleState(clientId: string, projectId: string, windsimModule: WindsimModule): State {
    const applicationState = this.getApplicationModulesStates(clientId, projectId);
    if (applicationState) {
      const moduleIndex = applicationState.modulesState.findIndex((ms) => ms.windsimModule === windsimModule);
      if (moduleIndex !== -1) {
        return applicationState.modulesState[moduleIndex].state;
      }
    }
    return State.Untouched;
  }

  public projectStateToModuleState(projectStatus: ProjectStatus): State {
    switch (projectStatus) {
      case ProjectStatus.None:
        return State.Untouched;
      case ProjectStatus.InProgress:
        return State.InProgress;
      case ProjectStatus.Completed:
        return State.Completed;
      case ProjectStatus.Failed:
        return State.Failed;
      case ProjectStatus.Cancelled:
        return State.Cancelled;
      case ProjectStatus.Archived:
        return State.Untouched;
      default:
        return State.Untouched;
    }
  }

  public projectSimulationStatusToModuleState(simulationStatus: Status): State {
    switch (simulationStatus) {
      case Status.None:
      case Status.Created:
        return State.Untouched;
      case Status.InProgress:
        return State.InProgress;
      case Status.Completed:
        return State.Completed;
      case Status.Failed:
        return State.Failed;
      case Status.Cancelled:
        return State.Cancelled;
      default:
        return State.Untouched;
    }
  }

  public projectSimulationStatusToProjectStatus(simulationStatus: Status): ProjectStatus {
    switch (simulationStatus) {
      case Status.None:
      case Status.Created:
        return ProjectStatus.None;
      case Status.InProgress:
        return ProjectStatus.InProgress;
      case Status.Completed:
        return ProjectStatus.Completed;
      case Status.Failed:
        return ProjectStatus.Failed;
      case Status.Cancelled:
        return ProjectStatus.Cancelled;
      default:
        return ProjectStatus.None;
    }
  }
}
