<div class="content-viewer {{dataGridCssClass}} {{additionalCssClass}}">

  <div class="content-viewer__top-panel">
    <ng-content select="[formControls]"></ng-content>
  </div>

  <div class="content-viewer__item">
    <section class="content-viewer__main">
      <ng-content select="[visualData]"></ng-content>
    </section>

    <ng-container *ngIf="tabularDataShouldBeDisplayed">
      <section class="content-viewer-table-container">
        <header class="content-viewer-table-container__header">
         <h2 class="content-viewer-table-container__title">{{tableContainerTitle}}</h2>
          <button class="content-viewer-table-container__button" mat-icon-button type="button" (click)="changeDataGridSize(tabularDataGridSize.Max)" [disabled]="dataGridSize === tabularDataGridSize.Max"  >
            <app-icon iconName="arrows-expand" iconClassName="icon icon--no-margin"></app-icon>
            <span class="cdk-visually-hidden">{{'tables.expand' | translate}}</span>
          </button>
          <button class="content-viewer-table-container__button" mat-icon-button type="button" (click)="changeDataGridSize(tabularDataGridSize.Medium)" [disabled]="dataGridSize === tabularDataGridSize.Medium">
            <app-icon iconName="arrows-resize" iconClassName="icon icon--no-margin"></app-icon>
            <span class="cdk-visually-hidden">{{'tables.default' | translate}}</span>
          </button>
          <button class="content-viewer-table-container__button" mat-icon-button type="button" (click)="changeDataGridSize(tabularDataGridSize.Min)" [disabled]="dataGridSize === tabularDataGridSize.Min">
            <app-icon iconName="arrows-shrink" iconClassName="icon icon--no-margin"></app-icon>
            <span class="cdk-visually-hidden">{{'tables.collapse' | translate}}</span>
          </button>

        </header>
        <ngx-simplebar class="content-viewer-table-container__tables">
          <ng-content select="[tabularData]"></ng-content>
        </ngx-simplebar>
      </section>
    </ng-container>
  </div>
</div>
