import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AzureSASCredential, TableClient } from '@azure/data-tables';
import { APP_CONFIG, AppConfig, TableSasToken } from '@windsim/core/models';
import { ValidationService } from '@windsim/core/services/validation.service';

@Injectable({
  providedIn: 'root',
})
export class TableStorageService {
  private config: AppConfig;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly validationService: ValidationService,
    private readonly http: HttpClient,
  ) {
    this.config = config;
  }

  private parseSasToken(sasToken: string): TableSasToken {
    this.validationService.validateStringArgument(sasToken, 'sasToken');

    const tokenSplit = sasToken.split('?');
    const params = tokenSplit[1].split('&');
    const tableName = params[0].split('=')[1];

    return {
      storageUri: tokenSplit[0].replace(this.config.tableStorageTableName, '').substr(0, tokenSplit[0].length - 1),
      storageAccessToken: `?${tokenSplit[1]}`,
      tableName,
    };
  }

  public getTableSasToken(): Promise<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http
      .get(`${this.config.coreApiUrl}/${this.config.projectEndpoints.getProjectValuesUri}`, { headers, responseType: 'text' })
      .toPromise();
  }

  public async getTableClient(): Promise<TableClient> {
    const sasToken = await this.getTableSasToken();
    const parsedToken = this.parseSasToken(sasToken);
    return new TableClient(parsedToken.storageUri, parsedToken.tableName, new AzureSASCredential(parsedToken.storageAccessToken));
  }
}
