import { Component, OnInit } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { LoaderMessageType } from '@windsim-core/enums';
import { Loader } from '@windsim-core/models';
import { DataService } from '@windsim-core/services';
import { BaseComponent } from '@windsim/shared/components/core/base/base.component';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent extends BaseComponent implements OnInit {
  isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();
  loaderMessage = '';

  constructor(private readonly dataService: DataService) {
    super();
  }

  private static createLoaderMessage(loaderMessageType: LoaderMessageType): string {
    switch (loaderMessageType) {
      case LoaderMessageType.LoadProjectValues:
        return translationMarker('global.load-from-cloud');
      case LoaderMessageType.StoreProjectValues:
        return translationMarker('global.save-to-cloud');
      case LoaderMessageType.DeleteProjectValues:
        return translationMarker('global.remove-from-cloud');
      case LoaderMessageType.GetFilesList:
        return translationMarker('global.get-files-list');
      case LoaderMessageType.SynchronisingProjectValues:
        return translationMarker('global.synchronising-project-values');
      case LoaderMessageType.StartingAzureFunction:
        return translationMarker('global.starting-azure-function');
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.subscribeLoaderMessage();
  }

  private subscribeLoaderMessage(): void {
    const { isLoadingMessage$ } = this.dataService;
    isLoadingMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loader: Loader) => {
      if (loader) {
        this.isLoading.next(loader.shouldBeVisible);
        this.loaderMessage = LoaderComponent.createLoaderMessage(loader.loaderMessageType);
      }
    });
  }
}
