import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BasicRoutes } from '@windsim/core/enums';
import { AuthenticationService, LoggingService } from '@windsim/core/services';
import { UserService } from '@windsim/modules/user/services/user.service';

@Injectable({ providedIn: 'root' })
export class BillingGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly userService: UserService,
    private readonly loggingService: LoggingService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { isAdmin, isIndividualUser } = this.userService;

    if (isAdmin || isIndividualUser) {
      return true;
    }

    this.router
      .navigate([BasicRoutes.Login], { queryParams: { returnUrl: state.url } })
      .then()
      .catch((err) => this.loggingService.consoleLogError(err));
    this.authenticationService
      .logout()
      .then()
      .catch((err) => this.loggingService.consoleLogError(err));
    return false;
  }
}
