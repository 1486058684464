import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TabularDataGridSize } from '@windsim-core/enums';
import { DataService } from '@windsim-core/services';
import { BaseComponent } from '@windsim/shared/components/core/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-content-viewer',
  templateUrl: './content-viewer.component.html',
  styleUrls: ['./content-viewer.component.scss'],
})
export class ContentViewerComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() tabularDataShouldBeDisplayed = false;
  @Input() tableContainerTitle = '';
  @Input() additionalCssClass = '';
  dataGridSize: TabularDataGridSize;
  dataGridCssClass = '';
  @Output() changeDataGridSizeEvent = new EventEmitter<TabularDataGridSize>();

  constructor(private readonly dataService: DataService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToggleLayerDataGridMessage();
    this.dataGridSize = this.tabularDataGridSize.Medium;
  }

  private subscribeToggleLayerDataGridMessage(): void {
    const { toggleLayerDataGridMessage$ } = this.dataService;
    toggleLayerDataGridMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((layer) => {
      if (layer) {
        this.dataGridCssClass = this.setDataGridCssClass(this.dataGridSize);
      }
    });
  }

  get tabularDataGridSize(): typeof TabularDataGridSize {
    return TabularDataGridSize;
  }

  changeDataGridSize(size: TabularDataGridSize) {
    this.dataGridSize = size;
    this.dataGridCssClass = this.setDataGridCssClass(size);
    this.changeDataGridSizeEvent.emit(size);
  }

  setDataGridCssClass(size: TabularDataGridSize): string {
    switch (size) {
      case TabularDataGridSize.Min:
        return 'content-viewer--table-hidden';
      case TabularDataGridSize.Max:
        return 'content-viewer--table-expanded';
      default:
        return '';
    }
  }
}
