import { Injectable } from '@angular/core';
import moment from 'moment/moment';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class ArrayService {
  constructor(private readonly validationService: ValidationService) {}

  public removeElementById(arr, id: number): any[] {
    if (!arr || !arr.length) {
      throw new Error('Array cannot be empty');
    }
    return arr.filter((e) => {
      return e.id !== id;
    });
  }

  public getMaxValueByElementName(arr: any[], elementName: string): number {
    this.validationService.validateStringArgument(elementName, 'elementName');

    if (arr.length === 0) {
      return 0;
    }
    return arr.reduce((max, el) => (el[elementName] > max ? el[elementName] : max), arr[0][elementName]);
  }

  public sortByDate(arr: any[], elementName: string): void {
    arr.sort((a, b) => {
      const date1 = moment(b[elementName], 'DD/MM/YYYY HH:mm').valueOf();
      const date2 = moment(a[elementName], 'DD/MM/YYYY HH:mm').valueOf();
      return date1 - date2;
    });
  }

  public getMaxValue(arr: number[]): number {
    if (arr.length === 0) {
      return 0;
    }
    return arr.reduce((max, el) => (el > max ? el : max), arr[0]);
  }

  public getMinValue(arr: any[]): number {
    if (arr.length === 0) {
      return 0;
    }
    return arr.reduce((max, el) => (el < max ? el : max), arr[0]);
  }
}
