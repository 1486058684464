import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import userAccessPolicies from '@windsim-assets/dataset/user-access-policies.json';
import { CommonDefaults } from '@windsim/core/configs';
import { AssignClaimsDto, RemoveClaimDto, RemoveProjectUserRoleDto, RemoveUserRoleDto, UserRoleDto } from '@windsim/core/dtos';
import { AssignRoleDto } from '@windsim/core/dtos/assign-role-dto.model';
import { ProjectRole, UserClaim, UserRole } from '@windsim/core/enums';
import {
  APP_CONFIG,
  AppConfig,
  Claim,
  ModuleAccessPolicy,
  PolicyGroup,
  ProjectAccessPolicy,
  ProjectPartitionKey,
  RoleInProject,
  UserAccessPolicies,
  UserProjectRole,
} from '@windsim/core/models';
import { Role } from '@windsim/core/models/role.model';
import { ApiService } from '@windsim/core/services/api.service';
import { AuthenticationService } from '@windsim/core/services/authentication.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { UserService } from '@windsim/modules/user/services/user.service';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private config: AppConfig;
  clientId: string;
  projectId: string;

  public get userId(): string {
    if (!this.authenticationService.decodedToken) {
      return '';
    }
    return this.authenticationService.decodedToken.Id;
  }

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly userService: UserService,
    private readonly validationService: ValidationService,
    private readonly apiService: ApiService,
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.config = config;
  }

  public set projectPartitionKey(projectPartitionKey: ProjectPartitionKey) {
    this.validationService.validateStringArgument(projectPartitionKey.clientId, CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.validationService.validateStringArgument(projectPartitionKey.projectId, CommonDefaults.PROJECT_ID_STORAGE_KEY);

    this.clientId = projectPartitionKey.clientId;
    this.projectId = projectPartitionKey.projectId;
  }

  public get claims(): Observable<boolean | string[]> {
    return this.http.get<string[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getClaims}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-claims-error'))),
    );
  }

  public get projectRoles(): Observable<boolean | string[]> {
    return this.http.get<string[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getProjectRoles}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      map((roles) => roles.filter((role) => role !== ProjectRole.ProjectContributor)),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-project-roles-error'))),
    );
  }

  public get roles(): Observable<boolean | Role[]> {
    return this.http.get<Role[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getRoles}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-roles-error'))),
    );
  }

  public get projectRole(): Observable<string> {
    return this.userProjectRoles.pipe(
      map((response) => {
        if (response) {
          const roles = response as UserProjectRole[];
          const roleIndex = roles.findIndex((r) => r.projectId === this.projectId.toUpperCase());
          return roleIndex !== -1 ? roles[roleIndex].role : ProjectRole.DefaultRole;
        }
        return ProjectRole.DefaultRole;
      }),
    );
  }

  public get userProjectPolicy(): Observable<ProjectAccessPolicy> {
    const policies = userAccessPolicies as UserAccessPolicies;
    const policyGroup = policies.ProjectUserPolicies as PolicyGroup;
    const defaultRole = policyGroup.DefaultRole as ProjectAccessPolicy;
    const projectAccessAll = policyGroup.ProjectAccessAll as ProjectAccessPolicy;

    if (this.userService.isAdmin) {
      return of(projectAccessAll);
    }

    return this.projectRole.pipe(
      catchError(() => of(defaultRole)),
      map((projectRole: string) => {
        return (policyGroup[projectRole] as ProjectAccessPolicy) || defaultRole;
      }),
    );
  }

  public get defaultRole(): ProjectAccessPolicy {
    const policies = userAccessPolicies as UserAccessPolicies;
    const policyGroup = policies.ProjectUserPolicies as PolicyGroup;
    return policyGroup.DefaultRole as ProjectAccessPolicy;
  }

  public get isProjectOwner(): Observable<boolean> {
    return this.projectRole.pipe(
      map((role) => {
        return role === ProjectRole.ProjectOwner;
      }),
    );
  }

  public get isProjectContributor(): Observable<boolean> {
    return this.projectRole.pipe(
      map((role) => {
        return role === ProjectRole.ProjectContributor;
      }),
    );
  }

  public get isProjectReader(): Observable<boolean> {
    return this.projectRole.pipe(
      map((role) => {
        return role === ProjectRole.ProjectReader;
      }),
    );
  }

  public get userClaims(): Observable<boolean | string[]> {
    return this.http.get<string[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getUserClaim}/${this.userId}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-user-claims-error'))),
    );
  }

  public get userProjectRoles(): Observable<boolean | UserProjectRole[]> {
    return this.http
      .get<UserProjectRole[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getProjectUserRoles}/${this.userId}`)
      .pipe(
        retryBackoff({
          initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
          maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
          resetOnSuccess: true,
        }),
        catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-project-user-roles-error'))),
      );
  }

  public getUserProjectPolicy(role: string): ProjectAccessPolicy | ModuleAccessPolicy {
    const policies = userAccessPolicies as UserAccessPolicies;
    return policies.ProjectUserPolicies[role] || policies.ProjectUserPolicies.DefaultRole;
  }

  public assignUserRole(userId: string, currentUserRoles: string[], userRole: UserRole): Observable<boolean> {
    const isRoleExists = currentUserRoles.indexOf(userRole.toString()) !== -1;
    if (isRoleExists) {
      return of(true);
    }

    currentUserRoles.push(userRole.toString());
    const userRoles: UserRoleDto[] = [];
    currentUserRoles.map((role) => userRoles.push({ name: role }));

    const dto: AssignRoleDto = {
      userId,
      userRoles,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.assignRole}`, dto, { headers, responseType: 'text' })
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public assignUserClaim(userId: string, currentClaims: Claim[], claim: UserClaim): Observable<boolean> {
    const isClaimExists = currentClaims.find((c) => c.value === claim.toString());
    if (isClaimExists) {
      return of(true);
    }
    const claims: string[] = currentClaims.map((c) => c.value);
    claims.push(claim.toString());

    const dto: AssignClaimsDto = {
      userId,
      claims,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.assignClaims}`, dto, { headers, responseType: 'text' })
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public getRolesInProject(projectId: string): Observable<boolean | RoleInProject[]> {
    return this.http
      .get<RoleInProject[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getProjectUsers}/${projectId}`)
      .pipe(
        retryBackoff({
          initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
          maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
          resetOnSuccess: true,
        }),
        catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-project-users-error'))),
      );
  }

  public getUserClaims(userId: string): Observable<boolean | Claim[]> {
    return this.http.get<Claim[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getUserClaim}/${userId}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-user-claims-error'))),
    );
  }

  public getUserRoles(userId: string): Observable<boolean | string[]> {
    return this.http.get<string[]>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.getUserRoles}/${userId}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('authentication.fetching-user-roles-error'))),
    );
  }

  public removeProjectUserRole(userId: string, projectId: string, projectUserRole: string): Observable<boolean> {
    const dto: RemoveProjectUserRoleDto = {
      userIds: [userId],
      projectId,
      role: projectUserRole,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.removeProjectRole}`, dto, { headers, responseType: 'text' })
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public removeUserClaim(userId: string, claim: string): Observable<boolean> {
    const dto: RemoveClaimDto = {
      userId,
      claim,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.removeClaim}`, dto, { headers, responseType: 'text' })
      .pipe(
        map(() => {
          return true;
        }),
      );
  }

  public removeUserRole(userId: string, userRole: string): Observable<boolean> {
    const dto: RemoveUserRoleDto = {
      userId,
      role: userRole,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.removeRole}`, dto, { headers, responseType: 'text' })
      .pipe(
        map(() => {
          return true;
        }),
      );
  }
}
