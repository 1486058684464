import { Injectable } from '@angular/core';
import { AlertType } from '@windsim-core/enums';
import { Alert } from '@windsim-core/models';
import { CommonDefaults } from '@windsim/core/configs';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private addAlertMessage = new BehaviorSubject<Alert>(undefined);
  addAlertMessage$ = this.addAlertMessage.asObservable();

  private clearAlertsMessage = new BehaviorSubject<boolean>(false);
  clearAlertsMessage$ = this.clearAlertsMessage.asObservable();

  private alertDefaultId = CommonDefaults.ALERT_ID;

  public success(message: string, id: string = this.alertDefaultId): void {
    this.alert({ id, type: AlertType.Success, message });
  }

  public error(message: string, id: string = this.alertDefaultId): void {
    this.alert({ id, type: AlertType.Error, message });
  }

  alert(alert: Alert) {
    this.addAlertMessage.next(alert);
  }

  public clear(): void {
    this.clearAlertsMessage.next(true);
  }
}
