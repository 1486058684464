<div class="language-picker">
  <button mat-icon-button [matMenuTriggerFor]="language" aria-label="Example icon-button with a menu" id="lng-dropdown">
    <mat-icon>language</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #language="matMenu">
    <button mat-menu-item (click)="changeLanguage('en')" id="lng-eng">
      <mat-icon>language</mat-icon>
      <span>{{'menu.english' | translate}}</span>
    </button>
    <button mat-menu-item (click)="changeLanguage('zh-Hans')" id="lng-zh">
      <mat-icon>language</mat-icon>
      <span>{{'menu.chinese' | translate}}</span>
    </button>
  </mat-menu>
</div>
