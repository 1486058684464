import { Injectable } from '@angular/core';
import { CommonDefaults } from '@windsim-core/configs';
import { GridHeader } from '@windsim/core/models';
import { FilesSharesService } from '@windsim/core/services/files-shares.service';
import { map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { UtilitiesService } from './utilities.service';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly filesSharesService: FilesSharesService,
    private readonly storageService: StorageService,
    private readonly validationService: ValidationService,
  ) {}

  gridFilePath = 'dtm/grid.gws';

  private static parseGridHeader(gridFileContent: string): GridHeader {
    const lines = gridFileContent.split(/\r?\n/);

    const windSimVersionRaw = lines.find((el) => el.includes('WindSim version'));
    const windSimVersion = windSimVersionRaw ? windSimVersionRaw.split(':')[1].trim() : null;

    const areaNameRaw = lines.find((el) => el.includes('area name'));
    const areaName = areaNameRaw ? areaNameRaw.split(':')[1].trim() : null;

    const coordinateSystemRaw = lines.find((el) => el.includes('co-ordinate system'));
    const coordinateSystem = coordinateSystemRaw ? UtilitiesService.parseFloat(coordinateSystemRaw.split(':')[1]) : null;

    const nxpNypRaw = lines.find((el) => el.includes('#nodes nxp nyp'));
    const nxpNyp = nxpNypRaw ? nxpNypRaw.split(':')[1].trim().replace(/\s+/g, ' ').split(/\s/g) : null;
    const nxp = nxpNyp ? UtilitiesService.parseFloat(nxpNyp[0]) : null;
    const nyp = nxpNyp ? UtilitiesService.parseFloat(nxpNyp[1]) : null;

    const xRaw = lines.find((el) => el.includes('ext. xmin xmax'));
    const x = xRaw ? xRaw.split(':')[1].trim().replace(/\s+/g, ' ').split(/\s/g) : null;
    const xMin = x ? UtilitiesService.parseFloat(x[0]) : null;
    const xMax = x ? UtilitiesService.parseFloat(x[1]) : null;

    const yRaw = lines.find((el) => el.includes('ext. ymin ymax'));
    const y = yRaw ? yRaw.split(':')[1].trim().replace(/\s+/g, ' ').split(/\s/g) : null;
    const yMin = y ? UtilitiesService.parseFloat(y[0]) : null;
    const yMax = y ? UtilitiesService.parseFloat(y[1]) : null;

    const zRaw = lines.find((el) => el.includes('ext. zmin zmax'));
    const z = zRaw ? zRaw.split(':')[1].trim().replace(/\s+/g, ' ').split(/\s/g) : null;
    const zMin = z ? UtilitiesService.parseFloat(z[0]) : null;
    const zMax = z ? UtilitiesService.parseFloat(z[1]) : null;

    const horizontalDatumRaw = lines.find((el) => el.includes('Horizontal datum'));
    const horizontalDatum = horizontalDatumRaw ? horizontalDatumRaw.split(':')[1].trim() : null;

    const projectionRaw = lines.find((el) => el.includes('Projection'));
    const projection = projectionRaw ? projectionRaw.split(':')[1].trim() : null;

    const gridHeader = {
      windSimVersion,
      ...(areaName && { areaName }),
      nodes: {
        nxp,
        nyp,
      },
      coordinateSystem,
      extension: {
        xMin,
        xMax,
        yMin,
        yMax,
        zMin,
        zMax,
      },
      ...(horizontalDatum && { horizontalDatum }),
      ...(projection && { projection }),
    };

    let missingProperties = [];
    missingProperties = this.getMissingGridHeaderProperties(gridHeader, missingProperties);

    if (!missingProperties.length) {
      return gridHeader;
    }

    throw new Error(`Cannot parse grid file. Following properties are missing: ${missingProperties.join(', ')}`);
  }

  private static getMissingGridHeaderProperties(gridHeader, missingProperties): any {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(gridHeader)) {
      if (value === null) {
        missingProperties.push(key);
      }
      if (typeof value === 'object' && value !== null) {
        this.getMissingGridHeaderProperties(value, missingProperties);
      }
    }
    return missingProperties;
  }

  private isStoredGridHeaderExists(clientId: string, projectId: string): boolean {
    return this.storageService.isExist(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY);
  }

  private async getGridHeaderSASToken(projectId: string): Promise<string> {
    const getOutputFilesFlatList$ = this.filesSharesService.getOutputFilesFlatList(projectId);
    return getOutputFilesFlatList$
      .pipe(
        map((fileList) => {
          const filteredFileList = fileList.filter((fl) => fl.fileName === this.gridFilePath);
          if (filteredFileList.length) {
            return filteredFileList[0].fileSASTokenUri;
          }
          throw new Error('Grid file is not available.');
        }),
      )
      .toPromise();
  }

  private async fetchGridFile(clientId: string, projectId: string): Promise<string> {
    const sasToken = await this.getGridHeaderSASToken(projectId);
    const downloadGridFile$ = await this.filesSharesService.downloadFile(sasToken);
    const blob = new Blob([downloadGridFile$], { type: 'text/json; charset=utf-8' });
    return new Response(blob).text();
  }

  private async fetchGridFileHeader(clientId: string, projectId: string): Promise<string> {
    const sasToken = await this.getGridHeaderSASToken(projectId);
    const downloadGridFile$ = await this.filesSharesService.downloadFile(sasToken);

    const content = await new Response(downloadGridFile$).text();

    const lines = content.split('\n');

    const first15Lines = lines.slice(0, 15).join('\n');

    return first15Lines;
  }

  public storeGridHeader(clientId: string, projectId: string, gridHeader: GridHeader): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.storageService.storeValue(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY, gridHeader);
  }

  public async getStoredGridHeader(clientId: string, projectId: string): Promise<GridHeader> {
    const isStoredGridHeaderExists = this.isStoredGridHeaderExists(clientId, projectId);

    if (!isStoredGridHeaderExists) {
      try {
        const gridHeader = await this.getGridHeaderFromTable(clientId, projectId);
        this.storeGridHeader(clientId, projectId, gridHeader);
        return gridHeader;
      } catch (error) {
        const gridHeader = await this.getGridHeader(clientId, projectId);
        this.storeGridHeader(clientId, projectId, gridHeader);
        return gridHeader;
      }
    }
    return this.storageService.getValue(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY) as GridHeader;
  }

  public async getGridHeaderFromTable(clientId: string, projectId: string): Promise<GridHeader> {
    try {
      const gridHeaderString = await this.storageService.getExternalValue(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY);
      if (gridHeaderString === null) {
        throw new Error('Grid header is not available.');
      }
      return JSON.parse(gridHeaderString) as GridHeader;
    } catch (error) {
      // Error handling logic here, if needed
      throw new Error('Error retrieving or parsing grid header: ' + error.message);
    }
  }

  public async getGridHeader(clientId: string, projectId: string): Promise<GridHeader> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const fileContent = await this.fetchGridFileHeader(clientId, projectId);
    return GridService.parseGridHeader(fileContent);
  }
}
