import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonDefaults } from '@windsim-core/configs';
import { CoreModule, SeverityLevel } from '@windsim-core/enums';
import { AlertService, BaseFileService, JobBaseService, LoggingService, StorageService } from '@windsim-core/services';
import { ExceptionTelemetry } from '@windsim/core/models';
import { TabularDataServiceContext } from '@windsim/core/services/tabular-data-service-context.service';
import { BaseComponent } from '@windsim/shared/components/core/base/base.component';
import { retryBackoff } from 'backoff-rxjs';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base-tabular-data',
  template: ``,
})
export class BaseTabularDataComponent extends BaseComponent implements OnInit {
  @Output() tabularDataLoadedEvent = new EventEmitter<boolean>();
  areTabularDataLoading = new BehaviorSubject<boolean>(false);
  areTabularDataLoading$ = this.areTabularDataLoading.asObservable();
  tabularData = [];
  fetchingTabularDataErrorMessageId = 'fetching-tabular-data-error';
  tabularDataLoadingError = new BehaviorSubject<boolean>(false);
  protected clientId: string;
  protected projectId: string;
  protected jobId: string;
  protected context: TabularDataServiceContext;
  protected dataFileName: string;
  protected coreModule: CoreModule;

  constructor(
    protected route: ActivatedRoute,
    protected storageService: StorageService,
    protected jobBaseService: JobBaseService,
    protected alertService: AlertService,
    protected baseFileService: BaseFileService,
    private readonly loggingService: LoggingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.areTabularDataLoading.next(true);
    this.subscribeAreTabularDataLoading();
    this.clientId = this.storageService.getGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.route.paramMap.subscribe((params) => {
      this.projectId = params.get('projectId');
      this.jobId = this.jobBaseService.getJobId(this.clientId, this.projectId, this.coreModule) ?? this.projectId;
      this.initializeTabularData();
    });
  }

  private subscribeAreTabularDataLoading(): void {
    this.areTabularDataLoading.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.tabularDataLoadedEvent.emit(!value);
    });
  }

  public get canTabularDataBeDisplayed(): boolean {
    return !this.areTabularDataLoading.value && !this.tabularDataLoadingError.value;
  }

  public initializeTabularData(): void {
    const projectPartitionKey = { clientId: this.clientId, projectId: this.projectId };
    const getOutputFile$ = this.baseFileService.getOutputFile(projectPartitionKey, `${this.dataFileName}`);
    getOutputFile$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        retryBackoff({
          initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
          maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
          resetOnSuccess: true,
        }),
      )
      .subscribe(
        (file) => {
          this.tabularData = this.context.parseDataFile(file);
          this.areTabularDataLoading.next(false);
        },
        (error) => {
          this.loggingService.consoleLogError(error);
          const errorMessage = translationMarker('model.fetching-tabular-data-error-message');
          this.alertService.error(errorMessage, this.fetchingTabularDataErrorMessageId);
          const exceptionTelemetry: ExceptionTelemetry = {
            exception: {
              name: 'BaseTabularDataComponent/initializeTabularData/getOutputFile',
              message: error,
            },
            id: this.projectId,
            severityLevel: SeverityLevel.Critical,
          };
          this.loggingService.externalLogException(exceptionTelemetry);
          this.tabularDataLoadingError.next(true);
          this.areTabularDataLoading.next(false);
        },
      );
  }
}
