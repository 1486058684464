<app-dialog
  [title]="'users.assign-module-role' | translate"
  icon="assign-module-role"
>
  <form [formGroup]="assignModuleRoleForm" [id]="'assign-user-module-role-form'">
    <label class="base-form__label" for="moduleRole">{{'organization.user-module-role' | translate}}</label>
    <mat-form-field appearance="outline" class="base-form__field">
      <mat-select [formControl]="moduleRoleFormControl" [value]="moduleRoleFormControl.value" disableRipple
                  id="moduleRole" required>
        <mat-option *ngFor="let claim of userClaims$ | async" [value]="claim">
          {{(claim | humanReadableModule) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <ng-container dialogActions>
    <button class="dialog__button" color="primary" mat-dialog-close
            mat-flat-button>{{'global.cancel' | translate}}</button>
    <button
      [attr.form]="'assign-user-module-role-form'"
      [mat-dialog-close]="moduleRoleFormControl.value"
      class="dialog__button"
      color="accent"
      mat-flat-button
      type="submit">
      {{'global.save' | translate}}
    </button>
  </ng-container>
</app-dialog>
