import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() iconName: string;
  @Input() iconClassName = 'icon';
  @Input() iconShouldBeHidden = true;
}
