<div class="environment-info">
  <span class="environment-info__name">{{environmentName}}</span>
  <app-icon iconName="info" iconClassName="icon icon--extra-small icon--no-margin" class="environment-info__icon"></app-icon>
  <div class="environment-info-tooltip">
    <dl class="environment-info-tooltip__list">
      <ng-container *ngFor="let url of apiUrls | keyvalue">
        <dt class="environment-info-tooltip__list-label">{{url.key}}:</dt>
        <dd class="environment-info-tooltip__list-item">{{url.value}}</dd>
      </ng-container>
    </dl>
  </div>
</div>
