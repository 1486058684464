<div class="mobile-alert-backdrop">
  <section class="mobile-alert">
  <app-icon
    iconName="arrow-top-left"
    iconClassName="icon icon--extra-large icon--no-margin"
    class="mobile-alert__icon mobile-alert__icon--top-left">
  </app-icon>

  <app-icon
    iconName="arrow-top-left"
    iconClassName="icon icon--extra-large icon--no-margin"
    class="mobile-alert__icon mobile-alert__icon--top-right">
  </app-icon>

  <app-icon
    iconName="arrow-top-left"
    iconClassName="icon icon--extra-large icon--no-margin"
    class="mobile-alert__icon mobile-alert__icon--bottom-left">
  </app-icon>

  <app-icon
    iconName="arrow-top-left"
    iconClassName="icon icon--extra-large icon--no-margin"
    class="mobile-alert__icon mobile-alert__icon--bottom-right">
  </app-icon>

  <div class="mobile-alert__content mat-typography">
    <h2 class="cdk-visually-hidden">{{'dialogs.mobile-alert-title' | translate}}</h2>
    <app-icon iconName="windsim-logo" iconClassName="icon icon--logo icon--no-margin"></app-icon>
    <p class="mobile-alert__text" [innerHTML]="'dialogs.mobile-alert-description' | translate"></p>
  </div>
</section>
</div>
