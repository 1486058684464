import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonDefaults } from '@windsim-core/configs';
import { Project } from '@windsim-projects/models';
import { APP_CONFIG, AppConfig, ProjectCost } from '@windsim/core/models';
import { ApiService } from '@windsim/core/services/api.service';
import { StorageService } from '@windsim/core/services/storage.service';
import { ValidationService } from '@windsim/core/services/validation.service';
import { retryBackoff } from 'backoff-rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseProjectService {
  protected config: AppConfig;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    protected validationService: ValidationService,
    protected storageService: StorageService,
    protected http: HttpClient,
    protected apiService: ApiService,
  ) {
    this.config = config;
  }

  public flushProjectStorage(clientId: string, projectId: string, clearSourcePath = true): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    this.storageService.removeValue(clientId, projectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);

    if (clearSourcePath) {
      this.storageService.removeValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
    }

    this.flushMapLocalStorage(clientId, projectId);
    this.flushModelLocalStorage(clientId, projectId);
    this.flushDatasetsLocalStorage(clientId, projectId);
    this.flushSimulationLocalStorage(clientId, projectId);
    this.storageService.removeProjectExternalValues(projectId).then();
  }

  public flushSimulationLocalStorage(clientId: string, projectId: string): void {
    this.storageService.removeValue(clientId, projectId, CommonDefaults.SIMULATION_FORM_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.SIMULATION_SECTORS_STORAGE_KEY);
  }

  public flushMapLocalStorage(clientId: string, projectId: string): void {
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.IS_MARKERS_AREA_LOCKED_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.ELEVATION_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.ROUGHNESS_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MARKERS_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MARKERS_LONGITUDE_COORDS_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MARKERS_LATITUDE_COORDS_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MAP_AREA_SELECTION_MODE);
  }

  public flushModelLocalStorage(clientId: string, projectId: string): void {
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MODEL_FORM_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.MODEL_JOB_ID_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.GRID_HEADER_STORAGE_KEY);
  }

  public flushDatasetsLocalStorage(clientId: string, projectId: string): void {
    this.storageService.removeValue(clientId, projectId, CommonDefaults.DATASET_HEADER_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.DATASET_COORDS_STORAGE_KEY);
    this.storageService.removeValue(clientId, projectId, CommonDefaults.DATASET_NAME_STORAGE_KEY);
  }

  public getProjectsCost(): Observable<ProjectCost> {
    return this.http.get<Project[]>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.organizationProjects}`).pipe(
      map((projects: Project[]) => {
        let projectsCost: ProjectCost = {
          costNOK: 0,
          costEUR: 0,
          costUSD: 0,
        };
        if (projects.length) {
          const costNOK = projects.map((p) => p.costNOK).reduce((a, b) => a + b);
          const costUSD = projects.map((p) => p.costUSD).reduce((a, b) => a + b);
          const costEUR = projects.map((p) => p.costEUR).reduce((a, b) => a + b);
          projectsCost = {
            costNOK: Number(Number(costNOK).toFixed(2)),
            costEUR: Number(Number(costEUR).toFixed(2)),
            costUSD: Number(Number(costUSD).toFixed(2)),
          };
        }
        return projectsCost;
      }),
    );
  }

  public setSourcePath(clientId: string, projectId: string, sourcePath: string): void {
    this.storageService.storeValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY, sourcePath);
  }

  public getSourcePath(clientId: string, projectId: string): string {
    return this.storageService.getValue(clientId, projectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
  }

  public getProject(projectId: string): Observable<Project> {
    this.validationService.validateStringArgument(projectId, 'projectId');

    return this.http.get<Project>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.getProject}/${projectId}`).pipe(
      catchError((error) => this.apiService.errorHandler(error, translationMarker('project-dialog.fetching-project-details-error'))),
      map((response) => {
        if (response) {
          return response as Project;
        }
        return undefined;
      }),
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
    );
  }
}
