import { Pipe, PipeTransform } from '@angular/core';
import { Status } from '@windsim/core/enums';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'humanReadableJobStatus',
  pure: true,
})
export class HumanReadableJobStatusPipe implements PipeTransform {
  transform(jobStatus: Status): string {
    switch (jobStatus) {
      case Status.None:
        return translationMarker('human-readable-job-status.none');
      case Status.Cancelled:
        return translationMarker('human-readable-job-status.cancelled');
      case Status.Completed:
        return translationMarker('human-readable-job-status.completed');
      case Status.Created:
        return translationMarker('human-readable-job-status.created');
      case Status.Failed:
        return translationMarker('human-readable-job-status.failed');
      case Status.InProgress:
        return translationMarker('human-readable-job-status.inProgress');
      default:
        return '';
    }
  }
}
