import { Pipe, PipeTransform } from '@angular/core';
import { ProjectType } from '@windsim/core/enums';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'humanReadableProjectType',
  pure: true,
})
export class HumanReadableProjectTypePipe implements PipeTransform {
  transform(projectType: ProjectType): string {
    switch (projectType) {
      case ProjectType.WindSimX:
        return translationMarker('human-readable-project-type.windSimX');
      case ProjectType.WindSimDesktopHybrid:
        return translationMarker('human-readable-project-type.windSimDesktopHybrid');
      default:
        return '';
    }
  }
}
