import { A11yModule } from '@angular/cdk/a11y';
import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '@windsim/shared/shared.module';
import { NotificationComponent } from './components/notification/notification.component';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, SharedModule, MatBadgeModule, MatTabsModule, A11yModule, ObserversModule],
  exports: [NotificationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationModule {}
