<mat-toolbar color="primary" class="menu">
  <ng-container *ngIf="toolbar$ | async">
    <ng-container *ngIf="(toolbar$ | async) === toolbarType.Regular as userIsLoggedIn; else userInNotLoggedIn">
      <button class="menu__hamburger" type="button" mat-flat-button color="accent" [matMenuTriggerFor]="mainMenu" (menuClosed)="(true)">
        <span class="cdk-visually-hidden">{{'menu.dropdown-toggle' | translate}}</span>
        <app-icon iconName="burger" iconClassName="icon icon--no-margin"></app-icon>
        <app-icon iconName="cross" iconClassName="icon icon--small icon--no-margin"></app-icon>
      </button>
      <mat-menu #mainMenu="matMenu" class="dropdown-menu dropdown-menu--dark dropdown-menu--shift-left10">
        <a mat-menu-item [routerLink]="staticMenuLinks.projects" (click)="clearCurrentProjectTitle()" *ngIf='canSeeProjects'>
          <app-icon iconName="project" iconClassName="icon icon--large icon--margin-large"></app-icon>
          <span>{{'menu.my-projects' | translate}}</span>
        </a>
        <a mat-menu-item [routerLink]="organizationUrl" (click)="clearCurrentProjectTitle()">
          <app-icon iconName="settings" iconClassName="icon icon--large icon--margin-large"></app-icon>
          <span>{{'menu.my-organization' | translate}}</span>
        </a>
      </mat-menu>
      <div class="menu__inner-container">
        <div class="menu__item">
          <a [routerLink]="homeUrl" class="menu__link" (click)="clearCurrentProjectTitle()">
            <app-icon iconName="windsim-logo" iconClassName="icon icon--logo icon--no-margin"></app-icon>
            <span class="cdk-visually-hidden">{{'menu.logo' | translate}}</span>
          </a>
          <app-environment-info *ngIf="!isProduction"></app-environment-info>
        </div>
        <div class="menu__item">
          <h1 class="menu__title" *ngIf="(projectName | async) && (isSingleProjectContext | async)">
            <app-icon iconName="project" iconClassName="icon icon--large icon--margin-large"></app-icon>
            <span>{{projectName | async}}</span>
            <span *ngIf="!(projectAccessPolicy$ | async).runActivities" class="menu__title-addon">{{'menu.readonly' | translate}}</span>
          </h1>
          <div class="menu__item" *ngIf="isProjectContextMenuAvailable">
            <button mat-icon-button type="button" [matMenuTriggerFor]="menu" class="menu__kebab"
                    #menuTrigger="matMenuTrigger">
              <span class="cdk-visually-hidden">{{'menu.dropdown-toggle' | translate}}</span>
              <app-icon iconName="dot-burger" iconClassName="icon icon--no-margin"></app-icon>
            </button>
            <mat-menu #menu="matMenu" class="dropdown-menu dropdown-menu--dark dropdown-menu--shift-right14">
              <button mat-menu-item type="button" (click)="openResetProjectDialog()">
                <app-icon iconName="revert" iconClassName="icon icon--margin-large"></app-icon>
                <span>{{'menu.reset-project' | translate}}</span>
              </button>
              <button mat-menu-item type="button" (click)="persistProjectValues()">
                <app-icon iconName="save" iconClassName="icon icon--margin-large"></app-icon>
                <span>{{'menu.persist-project-data' | translate}}</span>
              </button>
              <button mat-menu-item type="button" (click)="openLoadProjectValuesDialog()">
                <app-icon iconName="load" iconClassName="icon icon--margin-large"></app-icon>
                <span>{{'menu.load-project-data' | translate}}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="menu__item">
          <button
            *ngIf="(isSingleProjectContext | async)"
            #logsButton
            mat-button
            type="button"
            class="menu__button"
            [ngClass]="{'menu__button--active': (isNotificationPanelVisible$ | async)}"
            (click)="toggleNotificationPanel()">
            <span class="menu__icon-with-badge"
                  [ngClass]="{'menu__icon-with-badge--active' : ((coreLogsCounter | async) > 0)}">
              <app-icon iconName="logs" iconClassName="icon icon--no-margin"></app-icon>
            </span>
            <span>{{'menu.logs' | translate}}</span>
          </button>
          <button type="button" class="menu__button" mat-icon-button [matMenuTriggerFor]="userMenu">
            <span class="cdk-visually-hidden">{{'menu.dropdown-toggle' | translate}}</span>
            <span class="menu__avatar">
              <span class="menu__avatar-content">
                {{avatarPlaceholder$ | async}}
              </span>
            </span>
          </button>
          <mat-menu #userMenu="matMenu" class="dropdown-menu dropdown-menu--dark">
            <a mat-menu-item [routerLink]="staticMenuLinks.profile" (click)="clearCurrentProjectTitle()">
              <app-icon iconName="user" iconClassName="icon icon--large icon--margin-large"></app-icon>
              <span>{{'menu.profile' | translate}}</span>
            </a>
            <button mat-menu-item (click)="logout()">
              <app-icon iconName="logout" iconClassName="icon icon--large icon--margin-large"></app-icon>
              <span>{{'menu.logout' | translate}}</span>
            </button>
            <mat-menu #menu="matMenu" class="dropdown-menu dropdown-menu--dark dropdown-menu--shift-right14">
              <button mat-menu-item type="button" (click)="openResetProjectDialog()">
                <app-icon iconName="revert" iconClassName="icon icon--margin-large"></app-icon>
                <span>{{'menu.reset-project' | translate}}</span>
              </button>
              <button mat-menu-item type="button" (click)="persistProjectValues()">
                <app-icon iconName="save" iconClassName="icon icon--margin-large"></app-icon>
                <span>{{'menu.persist-project-data' | translate}}</span>
              </button>
              <button mat-menu-item type="button" (click)="openLoadProjectValuesDialog()">
                <app-icon iconName="load" iconClassName="icon icon--margin-large"></app-icon>
                <span>{{'menu.load-project-data' | translate}}</span>
              </button>
            </mat-menu>
          </mat-menu>
        </div>
      </div>
    </ng-container>
    <app-language-picker class="menu__language-picker"></app-language-picker>
    <ng-template #userInNotLoggedIn>
      <div class="menu__inner-container menu__inner-container--simple">
        <a [routerLink]="homeUrl" class="menu__link">
          <app-icon iconName="windsim-logo" iconClassName="icon icon--logo icon--no-margin"></app-icon>
          <span class="cdk-visually-hidden">{{'menu.logo' | translate}}</span>
        </a>
        <app-environment-info *ngIf="!isProduction"></app-environment-info>
      </div>
    </ng-template>
  </ng-container>
</mat-toolbar>
