import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '@windsim/core/enums';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'humanReadableProjectStatus',
  pure: true,
})
export class HumanReadableProjectStatusPipe implements PipeTransform {
  transform(projectStatus: ProjectStatus): string {
    switch (projectStatus) {
      case ProjectStatus.None:
        return translationMarker('human-readable-project-status.none');
      case ProjectStatus.InProgress:
        return translationMarker('human-readable-project-status.inProgress');
      case ProjectStatus.Completed:
        return translationMarker('human-readable-project-status.completed');
      case ProjectStatus.Failed:
        return translationMarker('human-readable-project-status.failed');
      case ProjectStatus.Cancelled:
        return translationMarker('human-readable-project-status.cancelled');
      case ProjectStatus.Archived:
        return translationMarker('human-readable-project-status.archived');
      default:
        return '';
    }
  }
}
