import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '@windsim/core/enums';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import memo from 'memo-decorator';

@Pipe({
  name: 'humanReadableUserRole',
  pure: true,
})
export class HumanReadableUserRolePipe implements PipeTransform {
  @memo()
  transform(userRole: string): string {
    switch (userRole) {
      case UserRole.Admin.toString():
        return translationMarker('human-readable-user-role.admin');
      case UserRole.User.toString():
        return translationMarker('human-readable-user-role.user');
      case UserRole.SuperAdmin.toString():
        return translationMarker('human-readable-user-role.superAdmin');
      default:
        return '';
    }
  }
}
