import { Injectable } from '@angular/core';
import { AreaCoordinates } from '@windsim/core/models/area-coordinates.model';
import proj4 from 'proj4';

@Injectable({
  providedIn: 'root',
})
export class ProjectionService {
  private static convert(dstProjection: string, coords: AreaCoordinates): AreaCoordinates {
    let north = proj4(dstProjection).forward([coords.center.longitude, coords.north])[1];
    let south = proj4(dstProjection).forward([coords.center.longitude, coords.south])[1];
    const east = proj4(dstProjection).forward([coords.east, coords.center.latitude])[0];
    const west = proj4(dstProjection).forward([coords.west, coords.center.latitude])[0];
    let center = proj4(dstProjection).forward([coords.center.longitude, coords.center.latitude]);

    north = north > 0 ? north : north + 10000000;
    south = south > 0 ? south : south + 10000000;
    center[1] = center[1] > 0 ? center[1] : center[1] + 10000000;

    return { north, east, south, west, center: { latitude: center[1], longitude: center[0] } };
  }

  private static lngToUtmZone(lng: number): number {
    return Math.floor((lng + 180) / 6) + 1;
  }

  public convertToMercator(coords: AreaCoordinates): AreaCoordinates {
    // eslint-disable-next-line max-len
    const dstProjection = `+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs`;
    return ProjectionService.convert(dstProjection, coords);
  }

  public convertToUtm(coords: AreaCoordinates): AreaCoordinates {
    const utmZone = ProjectionService.lngToUtmZone(coords.center.longitude);
    const dstProjection = `+proj=utm +zone=${utmZone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
    return ProjectionService.convert(dstProjection, coords);
  }
}
