import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonDefaults } from '@windsim-core/configs';
import {
  ApiService,
  BaseProjectService,
  LoggingService,
  StorageService,
  UtilitiesService,
  ValidationService,
} from '@windsim-core/services';
import { AddProjectDto } from '@windsim-projects/dtos';
import { Project } from '@windsim-projects/models';
import { AssignProjectRolesDto } from '@windsim/core/dtos/assign-project-roles-dto.model';
import { ProjectRole, ProjectVisibility, WindSimVersion } from '@windsim/core/enums';
import { APP_CONFIG, AppConfig, OwnProjectDetails } from '@windsim/core/models';
import { retryBackoff } from 'backoff-rxjs';
import moment from 'moment/moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { XMLParser } from 'fast-xml-parser';
import { SolverMethod } from '@windsim-simulation/enums';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseProjectService {
  public projects$ = this.http.get<Project[]>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.all}`).pipe(
    catchError((error) => this.apiService.errorHandler(error, translationMarker('projects.fetching-project-list-error'))),
    map((response) => {
      if (response) {
        const projects = response as Project[];
        return projects.map((project) => {
          project.creationDate = moment.utc(project.creationDate).local().format('DD/MM/YYYY HH:mm');
          project.updateDate = moment.utc(project.updateDate).local().format('DD/MM/YYYY HH:mm');
          project.updateDateAgo = moment(project.updateDate, 'DD/MM/YYYY HH:mm').fromNow();
          project.costNOK = project.costNOK ? Number(project.costNOK.toFixed(2)) : 0;
          project.costEUR = project.costEUR ? Number(project.costEUR.toFixed(2)) : 0;
          project.costUSD = project.costUSD ? Number(project.costUSD.toFixed(2)) : 0;
          project.usedCredits = project.usedCredits ? Number(project.usedCredits.toFixed(2)) : 0;
          return project;
        });
      }
      return [];
    }),
    retryBackoff({
      initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
      maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
      resetOnSuccess: true,
    }),
  );

  public organizationProjects$ = this.http
    .get<Project[]>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.organizationProjects}`)
    .pipe(
      map((response) => {
        if (response) {
          return response.map((project) => {
            project.creationDate = moment.utc(project.creationDate).local().format('DD/MM/YYYY HH:mm');
            project.updateDate = moment.utc(project.updateDate).local().format('DD/MM/YYYY HH:mm');
            project.updateDateAgo = moment(project.updateDate, 'DD/MM/YYYY HH:mm').fromNow();
            project.costNOK = project.costNOK ? Number(project.costNOK.toFixed(2)) : 0;
            project.costEUR = project.costEUR ? Number(project.costEUR.toFixed(2)) : 0;
            project.costUSD = project.costUSD ? Number(project.costUSD.toFixed(2)) : 0;
            project.usedCredits = project.usedCredits ? Number(project.usedCredits.toFixed(2)) : 0;
            return project;
          });
        }
        return [];
      }),
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
    );

  public projectsCounter$ = this.organizationProjects$.pipe(map((projects) => projects.length ?? 0));

  public projectsNames$ = this.projects$.pipe(map((projects) => projects.map((project) => project.name)));

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    validationService: ValidationService,
    storageService: StorageService,
    http: HttpClient,
    apiService: ApiService,
    private readonly loggingService: LoggingService,
  ) {
    super(config, validationService, storageService, http, apiService);
  }

  public getOwnedProjects(userId: string): Observable<boolean | Project[]> {
    return this.http.get<Project[]>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.ownedProjects}/${userId}`).pipe(
      retryBackoff({
        initialInterval: CommonDefaults.INITIAL_REQUEST_INTERVAL,
        maxRetries: CommonDefaults.MAX_REQUEST_RETRIES,
        resetOnSuccess: true,
      }),
      catchError((error) => this.apiService.errorHandler(error, translationMarker('user.fetching-user-projects-error'))),
    );
  }

  public addProject(project: Project): Observable<string> {
    const projectDto: AddProjectDto = {
      name: UtilitiesService.removeExtraWhitespaceChars(project.name),
      projectType: project.projectType,
    };
    return this.http.post<string>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.add}`, projectDto).pipe(
      map((projectId: string) => {
        if (projectId) {
          return projectId;
        }
        return '';
      }),
    );
  }

  public deleteProject(projectId: string): Observable<boolean> {
    this.validationService.validateStringArgument(projectId, 'projectId');

    return this.http.delete<string>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.remove}/${projectId}`).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public duplicateProject(project: Project): Observable<string> {
    this.validationService.validateStringArgument(project.id, 'project.id');

    const projectDto = {
      id: project.id,
      name: `${project.name} Copy`,
    };

    return this.http.post<string>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.makeAnotherCopyOfTheProject}`, projectDto).pipe(
      map((newProjectId) => {
        return newProjectId;
      }),
    );
  }

  public editProject(project: Project): Observable<boolean> {
    this.validationService.validateStringArgument(project.id, 'project.id');
    this.validationService.validateStringArgument(project.name, 'project.name');

    return this.http.post<string>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.save}`, project).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public setProjectVisibility(projectId: string, visibility: ProjectVisibility): Observable<boolean> {
    const dto = {
      id: projectId,
      isPrivate: visibility === ProjectVisibility.Private,
    };
    return this.http.post<string>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.updateVisibility}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public assignUserProjectRole(userId: string[], projectId: string, projectRole: ProjectRole): Observable<boolean> {
    const dto: AssignProjectRolesDto = {
      userIds: userId,
      projectId,
      role: projectRole.toString(),
    };
    return this.http.post<string>(`${this.config.coreApiUrl}/${this.config.userAccountEndpoints.assignProjectRoles}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  public removeUserFromProject(clientId: string, projectId: string) {
    const dto = {
      clientId,
      projectId,
    };
    this.loggingService.consoleLogInfo({ message: 'removeUserFromProject', value: dto });
    return of(true);
  }

  public getFirstProjectLayout(layoutFile: string): string {
    const parser = new XMLParser();
    const layoutFileContent = parser.parse(layoutFile);
    const layoutList = layoutFileContent.LayoutList.Layout;
    const layoutName = layoutList.length > 0 ? layoutList[0].Name : layoutList.Name;
    if (layoutName === undefined) {
      throw new Error('Cannot parse layout list file.');
    }
    return `${layoutName}.lws`;
  }

  public getWindSimProjectVersion(projectFile: string): number {
    const parser = new XMLParser();
    const projectFileContent = parser.parse(projectFile);
    const windSimProjectVersion = projectFileContent.ProjectParameters.WindSim.Version;
    if (windSimProjectVersion === undefined) {
      throw new Error('Cannot parse project version value from project file.');
    }
    return windSimProjectVersion;
  }

  public getOwnProjectDetails(clientId: string, projectId: string): OwnProjectDetails {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    return this.storageService.getValue(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY) as OwnProjectDetails;
  }

  public async duplicateProjectValues(clientId: string, srcProjectId: string, dstProjectId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(srcProjectId, 'srcProjectId');
    this.validationService.validateStringArgument(dstProjectId, 'dstProjectId');

    // Project state
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.APPLICATION_STATE_STORAGE_KEY);

    // Map module
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.SOURCE_PATH_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.MARKERS_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.MAP_MARKERS_MAX_AREA_BOUNDS_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.IS_MARKERS_AREA_LOCKED_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.DATASET_NAME_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.DATASET_COORDS_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.DATASET_HEADER_STORAGE_KEY);

    // Model module
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.MODEL_FORM_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.MODEL_JOB_ID_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.GRID_HEADER_STORAGE_KEY);

    // Simulation module
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.SIMULATION_FORM_STORAGE_KEY);
    await this.copyProjectValue(clientId, srcProjectId, dstProjectId, CommonDefaults.SIMULATION_SECTORS_STORAGE_KEY);
  }

  private async copyProjectValue(clientId: string, srcProjectId: string, dstProjectId: string, key: string): Promise<void> {
    this.validationService.validateStringArgument(key, 'key');

    const localValue = this.storageService.getValue(clientId, srcProjectId, key) as string;
    if (localValue) {
      this.storageService.storeValue(clientId, dstProjectId, key, localValue);
      await this.storageService.storeExternalValue(clientId, dstProjectId, key, localValue);
    } else {
      const remoteValue = (await this.storageService.getExternalValue(clientId, srcProjectId, key)) as string;
      if (remoteValue) {
        this.storageService.storeValue(clientId, dstProjectId, key, remoteValue);
        await this.storageService.storeExternalValue(clientId, dstProjectId, key, remoteValue);
      }
    }
  }

  public isNewestProjectVersion(projectVersion: number | undefined): boolean {
    return projectVersion ? projectVersion >= WindSimVersion.Version12 : false;
  }

  public setProjectSizeInOwnProjectDetails(clientId: string, projectId: string, ownProjectDetails: OwnProjectDetails): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');

    this.storageService.storeValue(clientId, projectId, CommonDefaults.OWN_PROJECT_DETAILS_STORAGE_KEY, ownProjectDetails);
  }

  public getProperSolverBasedOnProjectSize(projectSize: number): SolverMethod {
    if (projectSize >= CommonDefaults.PROJECT_BOUNDARY_SIZE) {
      return SolverMethod.ParallelGCV;
    }
    return SolverMethod.GCV;
  }

  public getProjectSize(clientId: string, projectId: string): number | undefined {
    const ownProjectDetails = this.getOwnProjectDetails(clientId, projectId);
    return ownProjectDetails && ownProjectDetails.projectSize ? ownProjectDetails.projectSize : undefined;
  }
}
