import { Injectable } from '@angular/core';
import pricingTiers from '@windsim-assets/dataset/pricing-tiers.json';
import { RoutesDefaults } from '@windsim/app-routing-defaults';
import { WindsimModule } from '@windsim/core/enums/windsim-module.enum';
import { ProjectPricingTiers } from '@windsim/core/models';
import { WindsimWorkflow } from '@windsim/core/models/windsim-workflow.model';
import { ValidationService } from '@windsim/core/services/validation.service';

@Injectable({
  providedIn: 'root',
})
export class WindsimWorkflowService {
  projectPricingTiers = pricingTiers as ProjectPricingTiers;
  workflows: WindsimWorkflow = {
    [RoutesDefaults.ONLINE_RESOURCES]: {
      name: 'Simulation based on terrain selection',
      availableInPricingTiers: { Free: this.projectPricingTiers.Free },
      modules: [WindsimModule.Map, WindsimModule.Model, WindsimModule.Simulations, WindsimModule.Files],
    },
    [RoutesDefaults.OWN_DATASET]: {
      name: 'Simulation based on terrain selection and uploaded own dataset',
      availableInPricingTiers: { Free: this.projectPricingTiers.Free },
      modules: [WindsimModule.Map, WindsimModule.Model, WindsimModule.Simulations, WindsimModule.Files],
    },
    [RoutesDefaults.UPLOADED_PROJECT]: {
      name: 'Simulation based on uploaded project',
      availableInPricingTiers: { Free: this.projectPricingTiers.Free },
      modules: [WindsimModule.Simulations, WindsimModule.Files],
    },
    [RoutesDefaults.GWS_GENERATOR]: {
      name: 'Generate GWS file based on terrain selection',
      availableInPricingTiers: { Free: this.projectPricingTiers.Free },
      modules: [WindsimModule.Map, WindsimModule.Files],
    },
  };

  constructor(private readonly validationService: ValidationService) {}

  public getWorkflow(sourcePath: string): WindsimModule[] {
    this.validationService.validateStringArgument(sourcePath, 'sourcePath');
    return this.workflows[sourcePath].modules;
  }

  public isModuleInWorkflow(sourcePath: string, module: WindsimModule): boolean {
    this.validationService.validateStringArgument(sourcePath, 'sourcePath');
    return this.workflows[sourcePath].modules.indexOf(module) !== -1;
  }
}
