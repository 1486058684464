<app-dialog
  [title]="'projects.set-project-visibility' | translate"
  icon="eye"
>
  <form [formGroup]="setProjectVisibilityForm" [id]="'set-project-visibility-form'">
    <label for="projectVisibility" class="base-form__label">{{'projects.project-visibility-label' | translate}}</label>
    <mat-form-field appearance="outline" class="base-form__field">
      <mat-select disableRipple id="projectVisibility" [formControl]="projectVisibilityFormControl" [value]="projectVisibilityFormControl.value" required>
        <mat-option *ngFor="let visibility of projectVisibilityTypes" [value]="visibility.enumValue">
          {{visibility.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <ng-container dialogActions>
    <button class="dialog__button"
            color="primary"
            mat-dialog-close
            mat-flat-button>{{'global.cancel' | translate}}</button>
    <button
      [attr.form]="'set-project-visibility-form'"
      [mat-dialog-close]="projectVisibilityFormControl.value"
      class="dialog__button"
      color="accent"
      mat-flat-button
      type="submit">
      {{'global.save' | translate}}
    </button>
  </ng-container>
</app-dialog>
