import { Pipe, PipeTransform } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import memo from 'memo-decorator';

@Pipe({
  name: 'humanReadableProjectRole',
  pure: true,
})
export class HumanReadableProjectRolePipe implements PipeTransform {
  @memo()
  transform(projectRole: string): string {
    switch (projectRole) {
      case 'ProjectOwner':
        return translationMarker('human-readable-project-role.owner');
      case 'ProjectContributor':
        return translationMarker('human-readable-project-role.contributor');
      case 'ProjectReader':
        return translationMarker('human-readable-project-role.reader');
      default:
        return '';
    }
  }
}
