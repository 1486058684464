import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  static readonly defaultLanguageCode = 'en';
  localStorageLanguageKey = 'language';

  constructor(private readonly translateService: TranslateService, private readonly storageService: StorageService) {}

  public get languageCode(): string {
    return this.storageService.getGlobalValue(this.localStorageLanguageKey);
  }

  public set languageCode(value: string) {
    this.storageService.storeGlobalValue(this.localStorageLanguageKey, value);
    this.translateService.use(value);
  }

  public initialize(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(LanguageService.defaultLanguageCode);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(this.languageCode || LanguageService.defaultLanguageCode);
  }

  public setUserSelectedLanguageCode(languageCode: string): void {
    this.storageService.storeGlobalValue(this.localStorageLanguageKey, languageCode);
    this.languageCode = languageCode;
  }
}
