import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import { AppConfig, ExceptionTelemetry, ProjectAccessPolicy, User } from '@windsim-core/models';
import {
  AuthenticationService,
  AuthorizationService,
  DataService,
  FilesSharesService,
  GridService,
  LoggingService,
  ProjectValuesPersistenceService,
  StorageService,
} from '@windsim-core/services';
import { ProjectService } from '@windsim-projects/services';
import { BaseComponent } from '@windsim-shared/core';
import { ConfirmationDialogComponent } from '@windsim-shared/dialogs';
import { RoutesDefaults } from '@windsim/app-routing-defaults';
import { CommonDefaults } from '@windsim/core/configs';
import { BasicRoutes, DialogType, EnvironmentType, SeverityLevel, ToolbarType } from '@windsim/core/enums';
import { ConfigService } from '@windsim/core/services/config.service';
import { UserService } from '@windsim/modules/user/services';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends BaseComponent implements OnInit {
  @ViewChild('logsButton', { read: ElementRef }) logsButtonEl: ElementRef;
  @ViewChild('menuTrigger') menuTriggerEl: MatMenuTrigger;
  coreLogsCounter = new BehaviorSubject<number>(0);
  projectName = new BehaviorSubject<string>('');
  isSingleProjectContext = new BehaviorSubject<boolean>(false);
  shouldDownloadGridButtonBeDisplayed = false;
  gridFileDownloadingError = new BehaviorSubject<boolean>(false);
  avatarPlaceholder = new BehaviorSubject<string>('');
  avatarPlaceholder$ = this.avatarPlaceholder.asObservable();
  user: User;
  projectId: string;
  clientId: string;

  toolbarType = ToolbarType;

  private toolbar = new BehaviorSubject<ToolbarType>(undefined);
  toolbar$ = this.toolbar.asObservable();
  private isNotificationPanelVisible = new BehaviorSubject<boolean>(false);
  isNotificationPanelVisible$ = this.isNotificationPanelVisible.asObservable();

  private projectAccessPolicy = new BehaviorSubject<ProjectAccessPolicy>(CommonDefaults.DEFAULT_PROJECT_ROLE);
  projectAccessPolicy$ = this.projectAccessPolicy.asObservable();

  config: AppConfig;

  staticMenuLinks = Object.freeze({
    profile: BasicRoutes.ProfileGeneral,
    projects: BasicRoutes.Projects,
  });

  constructor(
    private readonly configService: ConfigService,
    private readonly storageService: StorageService,
    private readonly dataService: DataService,
    private readonly projectService: ProjectService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly dialog: MatDialog,
    private readonly gridService: GridService,
    private readonly userService: UserService,
    private readonly projectValuesPersistenceService: ProjectValuesPersistenceService,
    private readonly loggingService: LoggingService,
    private readonly filesSharesService: FilesSharesService,
    private readonly authorizationService: AuthorizationService,
    private readonly location: Location,
  ) {
    super();
  }

  ngOnInit(): void {
    concat(this.loadConfig(), this.initializeMenu()).subscribe();

    this.subscribeRouterEvents();
    this.subscribeSingleProjectContextMessage();
    this.subscribeToggleNotificationPanel();
    this.subscribeUpdateCoreLogsCounter();
    this.subscribeInitialsUpdate();
  }

  get isProduction(): boolean {
    return this.config && this.config.environmentName === EnvironmentType.Production;
  }

  get isRegularUser(): boolean {
    return this.userService.isIndividualUser || this.userService.isUser;
  }

  get isAdmin(): boolean {
    return this.userService.isAdmin;
  }

  get canSeeProjects(): boolean {
    return this.userService.isIndividualUser || this.userService.isUser || this.userService.isAdmin;
  }

  get organizationUrl(): string {
    return this.isAdmin ? BasicRoutes.OrganizationUsers : BasicRoutes.OrganizationUtilization;
  }

  get homeUrl(): string {
    return BasicRoutes.Home;
  }

  get isUploadedProjectScenario(): boolean {
    const currentLocation = this.location.path();
    return currentLocation.includes(RoutesDefaults.UPLOADED_PROJECT);
  }

  get isProjectContextMenuAvailable(): boolean {
    return (
      this.projectAccessPolicy.value &&
      this.projectAccessPolicy.value.runActivities &&
      this.projectName.value &&
      this.isSingleProjectContext.value &&
      !this.isUploadedProjectScenario
    );
  }

  private loadConfig(): Observable<AppConfig> {
    return this.configService.load().pipe(
      filter((config) => config !== undefined),
      tap((config) => {
        this.config = config;
      }),
    );
  }

  private initializeMenu(): Observable<string | void> {
    return this.authenticationService.token$.pipe(
      takeUntil(this.ngUnsubscribe),
      tap((tokenMessage) => {
        this.toolbar.next(tokenMessage ? ToolbarType.Regular : ToolbarType.Simple);
        if (tokenMessage) {
          this.clientId = this.storageService.getGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY);
          this.setUserInitials();
        }
      }),
    );
  }

  private setUserInitials(): void {
    this.userService
      .getUserInitials()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((result) => {
          this.avatarPlaceholder.next(result);
        }),
      )
      .subscribe();
  }

  private subscribeRouterEvents(): void {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: ActivationEnd) => {
        if (Object.keys(event.snapshot.params).length > 0 && event.snapshot.params.projectId) {
          this.projectId = event.snapshot.params.projectId as string;
          this.subscribeProjectAccessPolicy();
        }
      });
  }

  private subscribeProjectAccessPolicy(): void {
    this.authorizationService.projectPartitionKey = { clientId: this.clientId, projectId: this.projectId };
    this.authorizationService.userProjectPolicy
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((policy: ProjectAccessPolicy) => {
          this.projectAccessPolicy.next(policy);
        }),
      )
      .subscribe();
  }

  private subscribeSingleProjectContextMessage(): void {
    const { singleProjectContextMessage$ } = this.dataService;
    singleProjectContextMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.isSingleProjectContext.next(value);
      if (value) {
        this.setCurrentProjectName();
      } else {
        this.coreLogsCounter.next(0);
        this.projectName.next('');
        this.dataService.setCurentProjectName('');
      }
    });
  }

  private subscribeToggleNotificationPanel(): void {
    const { toggleNotificationPanelMessage$ } = this.dataService;
    toggleNotificationPanelMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((message) => {
      this.isNotificationPanelVisible.next(message);

      if (this.logsButtonEl && message === false) {
        const button = this.logsButtonEl.nativeElement as HTMLElement;
        button.focus();
        button.blur();
      }
    });
  }

  private subscribeUpdateCoreLogsCounter(): void {
    const { coreLogsCounterMessage$ } = this.dataService;
    coreLogsCounterMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.coreLogsCounter.next(value);
    });
  }

  private subscribeInitialsUpdate(): void {
    const { initialsUpdatedMessage$ } = this.dataService;
    initialsUpdatedMessage$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((areUpdated) => {
          if (areUpdated) {
            this.setUserInitials();
          }
        }),
      )
      .subscribe();
  }

  private setCurrentProjectName(): void {
    const getProjects$ = this.projectService.getProject(this.projectId);
    getProjects$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((project) => project !== undefined),
        tap((project) => {
          this.projectName.next(project.name);
          this.dataService.setCurentProjectName(project.name);
        }),
      )
      .subscribe();
  }

  public async logout(): Promise<void> {
    await this.authenticationService.logout();
    this.clearCurrentProjectTitle();
    this.avatarPlaceholder.next('');
  }

  public isLogged(): string {
    return this.authenticationService.authTokenValue;
  }

  public clearCurrentProjectTitle(): void {
    this.projectName.next('');
    this.dataService.setCurentProjectName('');
    this.isSingleProjectContext.next(false);
  }

  private async resetCurrentProject(): Promise<void> {
    this.dataService.setSingleProjectContext(false);
    this.projectService.flushProjectStorage(this.clientId, this.projectId, false);
  }

  public openResetProjectDialog(): void {
    const dialogTitle = translationMarker('dialogs.reset-project-title');
    const dialogDescription = translationMarker('dialogs.reset-project-description');

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { type: DialogType.Delete, dialogTitle, dialogDescription },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resetCurrentProject()
          .then()
          .catch((error) => {
            this.loggingService.consoleLogError(error);
            const exceptionTelemetry: ExceptionTelemetry = {
              exception: {
                name: 'MenuComponent/openResetProjectDialog/resetCurrentProject',
                message: error,
              },
              id: this.projectId,
              severityLevel: SeverityLevel.Critical,
            };
            this.loggingService.externalLogException(exceptionTelemetry);
          });
        this.router
          .navigate(['projects'])
          .then()
          .catch((error) => this.loggingService.consoleLogError(error));
      }
    });
  }

  public openLoadProjectValuesDialog(): void {
    const dialogTitle = translationMarker('dialogs.load-project-values-title');
    const dialogDescription = translationMarker('dialogs.load-project-values-description');
    const icon = 'load';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { type: DialogType.Confirmation, icon, dialogTitle, dialogDescription },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadProjectValues()
          .then()
          .catch((error) => {
            this.loggingService.consoleLogError(error);
            const exceptionTelemetry: ExceptionTelemetry = {
              exception: {
                name: 'MenuComponent/openLoadProjectValuesDialog/loadProjectValues',
                message: error,
              },
              id: this.projectId,
              severityLevel: SeverityLevel.Critical,
            };
            this.loggingService.externalLogException(exceptionTelemetry);
          });
      }
    });
  }

  public async persistProjectValues(): Promise<void> {
    await this.projectValuesPersistenceService.persistValues(this.clientId, this.projectId);
  }

  public async loadProjectValues(): Promise<void> {
    await this.projectValuesPersistenceService.loadPersistedValues(this.clientId, this.projectId);
    window.location.reload();
  }

  public toggleNotificationPanel(): void {
    this.dataService.toggleNotificationPanel(!this.isNotificationPanelVisible.value);
  }
}
