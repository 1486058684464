import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeleteJobsDto } from '@windsim/core/dtos';
import { Status } from '@windsim/core/enums';
import { CoreModule } from '@windsim/core/enums/core-module.enum';
import { APP_CONFIG, AppConfig } from '@windsim/core/models';
import { JobStatus } from '@windsim/core/models/job-status.model';
import { LoggingService } from '@windsim/core/services/logging.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class JobBaseService {
  protected config: AppConfig;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    protected validationService: ValidationService,
    protected storageService: StorageService,
    protected http: HttpClient,
    protected loggingService: LoggingService,
  ) {
    this.config = config;
  }

  public getJobsStatus(projectId: string): Observable<JobStatus[]> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    return this.http.get<JobStatus[]>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.getJobsStatus}/${projectId}`);
  }

  public getMapStatus(projectId: string, clientId: string): Observable<string> {
    this.validationService.validateStringArgument(projectId, 'projectId');
    const url = `${this.config.azureFunctions.getMapStatus}&projectId=${projectId}&clientId=${clientId}`;
    return this.http.get<string>(url);
  }
  public storeJobId(clientId: string, projectId: string, jobId: string, moduleName: string): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(jobId, 'jobId');
    this.validationService.validateStringArgument(moduleName, 'moduleName');

    this.storageService.storeValue(clientId, projectId, `${moduleName}-job-id`, jobId);
  }

  public getJobId(clientId: string, projectId: string, coreModule: CoreModule): string {
    let moduleName = '';
    switch (coreModule) {
      case CoreModule.Terrain:
        moduleName = 'model';
        break;
      case CoreModule.Windfields:
        moduleName = 'simulation';
        break;
      default:
        break;
    }
    return this.storageService.getValue(clientId, projectId, `${moduleName}-job-id`) as string;
  }

  public getJobsForCoreModule(jobsStatuses: JobStatus[], coreModule: CoreModule): JobStatus[] {
    return jobsStatuses.filter((s) => s.module === coreModule);
  }

  public deleteJobs(clientId: string, jobIds: string[]): Observable<boolean> {
    this.validationService.validateStringArgument(clientId, 'clientId');
    const dto: DeleteJobsDto = {
      userId: clientId,
      jobIds,
    };

    return this.http.post<DeleteJobsDto>(`${this.config.coreApiUrl}/${this.config.projectEndpoints.deleteJobs}`, dto).pipe(
      map(() => {
        return true;
      }),
    );
  }

  protected getJobsNumber(jobsStatus: JobStatus[], status: Status): number {
    return jobsStatus.length === 0 ? 0 : jobsStatus.filter((wj) => wj.status === status).length;
  }
}
