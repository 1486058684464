import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { odata } from '@azure/data-tables';
import { LoaderMessageType } from '@windsim/core/enums';
import { Loader } from '@windsim/core/models';
import { DataService } from '@windsim/core/services/data.service';
import { TableStorageService } from '@windsim/core/services/table-storage.service';
import { UtilitiesService } from '@windsim/core/services/utilities.service';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  loader: Loader = {
    shouldBeVisible: true,
    loaderMessageType: LoaderMessageType.SynchronisingProjectValues,
  };

  constructor(
    private readonly validationService: ValidationService,
    private readonly http: HttpClient,
    private readonly dataService: DataService,
    private readonly tableStorageService: TableStorageService,
  ) {}

  public getValue(clientId: string, projectId: string, key: string): any {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(key, 'key');

    return JSON.parse(localStorage.getItem(`windsim-${clientId}-${projectId}-${key}`));
  }

  public storeValue(clientId: string, projectId: string, key: string, value: any): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(key, 'key');
    this.validationService.validateValue(value, 'value');

    localStorage.setItem(`windsim-${clientId}-${projectId}-${key}`, JSON.stringify(value));
  }

  public storeGlobalValue(key: string, value: any): void {
    this.validationService.validateStringArgument(key, 'key');
    this.validationService.validateValue(value, 'value');

    localStorage.setItem(`windsim-${key}`, JSON.stringify(value));
  }

  public getGlobalValue(key: string): string {
    this.validationService.validateStringArgument(key, 'key');

    return JSON.parse(localStorage.getItem(`windsim-${key}`));
  }

  public removeValue(clientId: string, projectId: string, key: string): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(key, 'key');

    localStorage.removeItem(`windsim-${clientId}-${projectId}-${key}`);
  }

  public removeGlobalValue(key: string): void {
    this.validationService.validateStringArgument(key, 'key');
    localStorage.removeItem(`windsim-${key}`);
  }

  public isExist(clientId: string, projectId: string, key: string): boolean {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(key, 'key');

    return JSON.parse(localStorage.getItem(`windsim-${clientId}-${projectId}-${key}`)) !== null;
  }

  // Native Azure Tables

  private validateTableProperties(clientId: string, projectId: string, rowKey: string): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
    this.validationService.validateStringArgument(rowKey, 'rowKey');
  }

  private async removeTableValuePartitionKey(partitionKey: string, rowKey: string): Promise<void> {
    this.validationService.validateStringArgument(partitionKey, 'partitionKey');
    this.validationService.validateStringArgument(rowKey, 'rowKey');

    this.dataService.showLoader(this.loader);

    try {
      const tableClient = await this.tableStorageService.getTableClient();
      await tableClient.deleteEntity(partitionKey, rowKey);
      this.dataService.hideLoader(this.loader);
    } catch {
      this.dataService.hideLoader(this.loader);
    }
  }

  public async storeExternalValue(clientId: string, projectId: string, rowKey: string, value: any): Promise<void> {
    this.validateTableProperties(clientId, projectId, rowKey);

    this.dataService.showLoader(this.loader);
    const partitionKey = `${clientId}-${projectId}`;

    try {
      const tableClient = await this.tableStorageService.getTableClient();
      const entity = {
        partitionKey,
        rowKey,
        ClientId: clientId,
        ProjectId: projectId,
        Value: JSON.stringify(value),
      };
      await tableClient.createEntity(entity);

      this.dataService.hideLoader(this.loader);
    } catch {
      this.dataService.hideLoader(this.loader);
    }
  }

  public async getExternalValue(clientId: string, projectId: string, rowKey: string): Promise<string> {
    this.validateTableProperties(clientId, projectId, rowKey);

    const partitionKey = `${clientId}-${projectId}`;
    this.dataService.showLoader(this.loader);

    try {
      const tableClient = await this.tableStorageService.getTableClient();
      const entity = await tableClient.getEntity(partitionKey, rowKey);

      this.dataService.hideLoader(this.loader);

      return UtilitiesService.stripQuotas(entity.Value as string);
    } catch {
      this.dataService.hideLoader(this.loader);
    }
    return null;
  }

  public async removeExternalValue(clientId: string, projectId: string, rowKey: string): Promise<void> {
    this.validateTableProperties(clientId, projectId, rowKey);

    this.dataService.showLoader(this.loader);

    const partitionKey = `${clientId}-${projectId}`;
    try {
      const tableClient = await this.tableStorageService.getTableClient();
      await tableClient.deleteEntity(partitionKey, rowKey);
      this.dataService.hideLoader(this.loader);
    } catch {
      this.dataService.hideLoader(this.loader);
    }
  }

  public async removeProjectExternalValues(projectId: string): Promise<void> {
    this.validationService.validateStringArgument(projectId, 'projectId');

    this.dataService.showLoader(this.loader);

    try {
      const tableClient = await this.tableStorageService.getTableClient();
      const entities = tableClient.listEntities({ queryOptions: { filter: odata`ProjectId eq ${projectId}` } });
      // eslint-disable-next-line no-restricted-syntax
      for await (const entity of entities) {
        if (entity.rowKey) {
          await this.removeTableValuePartitionKey(entity.partitionKey, entity.rowKey);
        }
      }
      this.dataService.hideLoader(this.loader);
    } catch {
      this.dataService.hideLoader(this.loader);
    }
  }

  public async removeClientExternalValues(clientId: string): Promise<void> {
    this.validationService.validateStringArgument(clientId, 'clientId');

    this.dataService.showLoader(this.loader);

    try {
      const tableClient = await this.tableStorageService.getTableClient();
      const entities = await tableClient.listEntities({ queryOptions: { filter: odata`ClientId eq ${clientId}` } });
      // eslint-disable-next-line no-restricted-syntax
      for await (const entity of entities) {
        if (entity.rowKey) {
          await this.removeTableValuePartitionKey(entity.partitionKey, entity.rowKey);
        }
      }
      this.dataService.hideLoader(this.loader);
    } catch {
      this.dataService.hideLoader(this.loader);
    }
  }

  public async isExternalValueExists(clientId: string, projectId: string, rowKey: string): Promise<boolean> {
    this.validateTableProperties(clientId, projectId, rowKey);

    this.dataService.showLoader(this.loader);
    const partitionKey = `${clientId}-${projectId}`;

    try {
      const tableClient = await this.tableStorageService.getTableClient();
      const entities = tableClient.listEntities({
        queryOptions: { filter: odata`PartitionKey eq ${partitionKey} and RowKey eq ${rowKey}` },
      });

      // eslint-disable-next-line no-restricted-syntax
      for await (const entity of entities) {
        if (entity.rowKey === rowKey) {
          this.dataService.hideLoader(this.loader);
          return true;
        }
      }
      this.dataService.hideLoader(this.loader);
      return false;
    } catch {
      this.dataService.hideLoader(this.loader);
      return false;
    }
  }
}
