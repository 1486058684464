import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@windsim/core/models';
import { DatasetFileDto } from '@windsim/modules/map-base/models/dataset-file-dto';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private config: AppConfig;

  constructor(@Inject(APP_CONFIG) config: AppConfig, private httpClient: HttpClient) {
    this.config = config;
  }

  uploadDatasetRequest(dto): Observable<any> {
    return this.httpClient
      .post<DatasetFileDto>(`${this.config.converterApiUrl}/${this.config.converterEndpoints.uploadDataset}`, dto, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return Math.round((100 * event.loaded) / event.total);
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        }),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  deleteFile(fileName: string, clientId: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.delete<string>(
      `${this.config.converterApiUrl}/${this.config.converterEndpoints.deleteDataset}/${clientId}/${fileName}`,
      {
        headers,
        responseType: 'text' as 'json',
      },
    );
  }
}
