import { animate, state, style, transition, trigger } from '@angular/animations';

export const Animations = {
  inOutAnimation: trigger('inOutAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(-100%)', opacity: 0 }),
      animate('.3s ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
    ]),
  ]),
  rowToggleAnimation: trigger('rowToggle', [
    state('collapsed, void', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
};
