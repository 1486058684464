import { Injectable } from '@angular/core';
import { CommonDefaults } from '@windsim-core/configs';
import { MapDefaults } from '@windsim-map-base/configs/map.defaults';
import { MapAreaSelectionMode } from '@windsim-map-base/enums/map-area-selection-mode.enum';
import { AreaCoordinates } from '@windsim/core/models/area-coordinates.model';
import { ProjectPartitionKey } from '@windsim/core/models/project-partition-key.model';
import { ProjectionService } from '@windsim/core/services/projection.service';
import { StorageService } from './storage.service';
import { ValidationService } from './validation.service';
import { isEqual } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class MapAreaBaseService {
  protected clientId: string;
  protected projectId: string;

  constructor(
    protected storageService: StorageService,
    protected validationService: ValidationService,
    protected projectionService: ProjectionService,
  ) {}

  public set projectPartitionKey(projectPartitionKey: ProjectPartitionKey) {
    this.validationService.validateStringArgument(projectPartitionKey.clientId, CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.validationService.validateStringArgument(projectPartitionKey.projectId, CommonDefaults.PROJECT_ID_STORAGE_KEY);

    this.clientId = projectPartitionKey.clientId;
    this.projectId = projectPartitionKey.projectId;
  }

  public get getMaxAreaBoundariesInMeters(): number {
    this.validateInput(this.clientId, this.projectId);
    return (
      (this.storageService.getValue(this.clientId, this.projectId, CommonDefaults.MAP_MARKERS_MAX_AREA_BOUNDS_STORAGE_KEY) as number) ||
      MapDefaults.MAX_AREA_BOUNDARIES_IN_METERS
    );
  }

  public get getModelAreaCoordinates(): AreaCoordinates {
    this.validateInput(this.clientId, this.projectId);
    return (
      (this.storageService.getValue(this.clientId, this.projectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY) as AreaCoordinates) ||
      MapDefaults.DEFAULT_COORDINATES
    );
  }

  public get getBufferAreaCoordinates(): AreaCoordinates {
    this.validateInput(this.clientId, this.projectId);
    return this.storageService.getValue(this.clientId, this.projectId, CommonDefaults.BUFFER_AREA_COORDS_STORAGE_KEY) as AreaCoordinates;
  }

  public get isModelAreaSet(): boolean {
    this.validateInput(this.clientId, this.projectId);
    const modelAreaCoordinates = this.getModelAreaCoordinates;

    const isModelAreaStored = this.storageService.isExist(this.clientId, this.projectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY);
    const isModelAreaDefault = isEqual(modelAreaCoordinates, MapDefaults.DEFAULT_COORDINATES);

    return isModelAreaStored && !isModelAreaDefault;
  }

  private validateInput(clientId: string, projectId: string): void {
    this.validationService.validateStringArgument(clientId, 'clientId');
    this.validationService.validateStringArgument(projectId, 'projectId');
  }

  public storeMaxAreaBoundaries(boundariesInMeters: number): void {
    this.validateInput(this.clientId, this.projectId);
    return this.storageService.storeValue(
      this.clientId,
      this.projectId,
      CommonDefaults.MAP_MARKERS_MAX_AREA_BOUNDS_STORAGE_KEY,
      boundariesInMeters,
    );
  }

  public storeModelAreaCoordinates(area: AreaCoordinates): void {
    this.validateInput(this.clientId, this.projectId);
    return this.storageService.storeValue(this.clientId, this.projectId, CommonDefaults.MODEL_AREA_COORDS_STORAGE_KEY, area);
  }

  public storeBufferAreaCoordinates(coords: AreaCoordinates): void {
    this.validateInput(this.clientId, this.projectId);
    this.storageService.storeValue(this.clientId, this.projectId, CommonDefaults.BUFFER_AREA_COORDS_STORAGE_KEY, coords);
  }

  public removeBufferAreaCoordinates(): void {
    this.validateInput(this.clientId, this.projectId);
    this.storageService.removeValue(this.clientId, this.projectId, CommonDefaults.BUFFER_AREA_COORDS_STORAGE_KEY);
  }

  public calculateGlobalMapperSafeZone(coords: AreaCoordinates): number {
    const reprojectedCoords = this.projectionService.convertToUtm(coords);
    const xDistanceInMeters = Math.abs(reprojectedCoords.east - reprojectedCoords.west);
    const yDistanceInMeters = Math.abs(reprojectedCoords.north - reprojectedCoords.south);
    let safeZone = CommonDefaults.GLOBAL_MAPPER_SAFE_ZONE_IN_METERS;

    if (
      xDistanceInMeters > CommonDefaults.GLOBAL_MAPPER_SAFE_ZONE_MODEL_SIZE_IN_METERS ||
      yDistanceInMeters > CommonDefaults.GLOBAL_MAPPER_SAFE_ZONE_MODEL_SIZE_IN_METERS
    ) {
      safeZone = Math.round(((xDistanceInMeters + yDistanceInMeters) / 2) * CommonDefaults.GLOBAL_MAPPER_SAFE_ZONE_FACTOR);
    }
    return safeZone;
  }

  public storeMapAreaSelectionMode(value: MapAreaSelectionMode): void {
    this.validateInput(this.clientId, this.projectId);
    this.storageService.storeValue(this.clientId, this.projectId, CommonDefaults.MAP_AREA_SELECTION_MODE, value);
  }

  public getMapAreaSelectionMode(): MapAreaSelectionMode {
    this.validateInput(this.clientId, this.projectId);
    return (
      (this.storageService.getValue(this.clientId, this.projectId, CommonDefaults.MAP_AREA_SELECTION_MODE) as MapAreaSelectionMode) ||
      MapAreaSelectionMode.BoxSelector
    );
  }
}
