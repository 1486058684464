import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserClaim } from '@windsim/core/enums';
import { AuthorizationService } from '@windsim/core/services';

@Component({
  selector: 'app-assign-module-role',
  templateUrl: './assign-module-role.component.html',
})
export class AssignModuleRoleComponent implements OnInit {
  userClaims$ = this.authorizationService.claims;
  assignModuleRoleForm: FormGroup<{
    moduleRole: FormControl<string>;
  }>;

  constructor(private readonly authorizationService: AuthorizationService, private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  get assignModuleRoleFormControls(): { [key: string]: AbstractControl } {
    return this.assignModuleRoleForm.controls;
  }

  get moduleRoleFormControl(): FormControl {
    return this.assignModuleRoleFormControls.moduleRole as FormControl;
  }

  private initForm(): void {
    this.assignModuleRoleForm = this.formBuilder.group({
      moduleRole: [UserClaim.CeetronModuleViewer.toString(), Validators.required],
    });
  }
}
