import { Pipe, PipeTransform } from '@angular/core';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';
import memo from 'memo-decorator';

@Pipe({
  name: 'humanReadableProjectVisibility',
})
export class HumanReadableProjectVisibilityPipe implements PipeTransform {
  @memo()
  transform(isPrivate: boolean): string {
    return isPrivate
      ? translationMarker('human-readable-project-visibility.private')
      : translationMarker('human-readable-project-visibility.shared');
  }
}
