import { Injectable } from '@angular/core';
import { CommonDefaults } from '@windsim/core/configs';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  public subscriptionErrorMessageHandler(error: any): string {
    if (error.errors.message && error.errors.message.includes(CommonDefaults.NO_ACTIVE_SUBSCRIPTION_FOUND_ERROR_MESSAGE)) {
      return translationMarker('utilization.no-active-subscription-error');
    } else {
      return translationMarker('utilization.fetching-subscription-error');
    }
  }
}
