import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRole } from '@windsim/core/enums';
import { AuthorizationService } from '@windsim/core/services';

@Component({
  selector: 'app-assign-user-role',
  templateUrl: './assign-user-role.component.html',
})
export class AssignUserRoleComponent implements OnInit {
  userRoles$ = this.authorizationService.roles;
  assignUserRoleForm: FormGroup<{
    userRole: FormControl<string>;
  }>;

  constructor(private readonly formBuilder: FormBuilder, private readonly authorizationService: AuthorizationService) {}

  ngOnInit(): void {
    this.initForm();
  }

  get assignUserRoleFormControls(): { [key: string]: AbstractControl } {
    return this.assignUserRoleForm.controls;
  }

  get userRoleFormControl(): FormControl {
    return this.assignUserRoleFormControls.userRole as FormControl;
  }

  private initForm(): void {
    this.assignUserRoleForm = this.formBuilder.group({
      userRole: [UserRole.User.toString(), Validators.required],
    });
  }
}
