import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PasswordScore } from '@windsim/core/models';
import { UtilitiesService } from '@windsim/core/services/utilities.service';
import { pairwise, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private readonly translateService: TranslateService) {}
  public restorePreviousValueIfNull(formControl: FormControl): void {
    formControl.valueChanges.pipe(startWith(formControl.value as number | string), pairwise()).subscribe(([prevVal, nextVal]) => {
      if (nextVal === null || nextVal === '') {
        formControl.setValue(prevVal);
      }
    });
  }

  public validatePercentageValue(input: string): number {
    const value = input !== '' ? UtilitiesService.parseFloat(input) : 0;

    if (value < 0) {
      return 0;
    }
    if (value > 100) {
      return 100;
    }
    return value;
  }

  public markAsUntouched(formControl: FormControl): void {
    if (formControl.dirty) {
      return;
    }
    formControl.markAsUntouched();
  }

  public checkPasswordValidity(password: string, length: number): PasswordScore {
    if (!password) {
      return null;
    }
    const regex = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;
    const lowercase = /[a-z]+/.test(password);
    const uppercase = /[A-Z]+/.test(password);
    const digit = /[0-9]+/.test(password);
    const symbol = regex.test(password);
    const minLength = password.length >= length;

    const flags = [lowercase, uppercase, digit, symbol, minLength];

    return {
      matches: flags.filter(Boolean).length,
      isValid: flags.every(Boolean),
      requirementsTotalNumber: flags.length,
    };
  }

  public confirmPasswordErrorMessage(errors: ValidationErrors): string {
    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return this.translateService.instant('global.required-field-error');
      case errors.mismatch !== undefined:
        return this.translateService.instant('user-password.mismatch-error');
      default:
        return '';
    }
  }

  public emailErrorMessage(errors: ValidationErrors): string {
    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return this.translateService.instant('global.required-field-error');
      case errors.email !== undefined || errors.pattern !== undefined:
        return this.translateService.instant('user.email-format');
      default:
        return '';
    }
  }

  public newProjectErrorMessage(errors: ValidationErrors): string {
    if (!errors) {
      return '';
    }
    switch (true) {
      case errors.required !== undefined:
        return this.translateService.instant('global.required-field-error');
      case errors.duplicate !== undefined:
        return this.translateService.instant('project-creator.duplicate-error');
      default:
        return '';
    }
  }

  public errorMessage(errors: ValidationErrors): string {
    if (!errors) {
      return '';
    }

    switch (true) {
      case errors.required !== undefined:
        return this.translateService.instant('global.required-field-error');
      case errors.min !== undefined:
        return this.translateService.instant('global.min-value-error', { value: errors.min.min });
      case errors.max !== undefined:
        return this.translateService.instant('global.max-value-error', { value: errors.max.max });
      case errors.greaterThan !== undefined:
        return this.translateService.instant('global.greater-than-value-error', { value: errors.greaterThan.val });
      default:
        return '';
    }
  }

  public isRequired(formControl: FormControl): boolean {
    if (!formControl.validator) {
      return false;
    }
    const validator = formControl.validator({} as AbstractControl);
    return !!(validator && validator.required);
  }

  public getFormControlId(formControl: FormControl): string {
    const currentFormControl = Object.entries(formControl.parent.controls).find((formControlObject) => {
      return formControlObject[1] === formControl;
    });

    return currentFormControl[0];
  }
}
