import { Pipe, PipeTransform } from '@angular/core';
import { UserClaim } from '@windsim/core/enums';
import { marker as translationMarker } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'humanReadableModule',
  pure: true,
})
export class HumanReadableModulePipe implements PipeTransform {
  transform(userClaim: UserClaim): string {
    switch (userClaim) {
      case UserClaim.CeetronModuleViewer:
        return translationMarker('human-readable-module.ceetronModuleViewer');
      default:
        return '';
    }
  }
}
