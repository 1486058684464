import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class UtilitiesService {
  public static parseFloat(input: string): number {
    if (Number.isNaN(Number.parseFloat(input))) {
      throw new Error('Input cannot be parsed as a float number');
    }
    return Number.parseFloat(input);
  }

  public static generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public static parseExponential(input: any): number {
    return Number(Number.parseFloat(input).toFixed(20));
  }

  public static pad(num, size): string {
    const output = `000${num}`;
    return output.substr(output.length - size);
  }

  public static stripQuotas(input: string): string {
    let stringToTrim = input;
    if (stringToTrim) {
      if (stringToTrim[0] === '"') {
        stringToTrim = stringToTrim.substring(1);
      }

      if (stringToTrim[stringToTrim.length - 1] === '"') {
        stringToTrim = stringToTrim.slice(0, -1);
      }
    }
    return stringToTrim;
  }

  public static removeExtraWhitespaceChars(input: string): string {
    return input.replace(/\s+/g, ' ').trim();
  }

  public static isJsonString(str): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
