import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig, Token, User } from '@windsim-core/models';
import { DataService } from '@windsim-core/services/data.service';
import { StorageService } from '@windsim-core/services/storage.service';
import { CommonDefaults } from '@windsim/core/configs';
import { BasicRoutes } from '@windsim/core/enums';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private config: AppConfig;
  private token: BehaviorSubject<string>;
  public token$: Observable<string>;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly http: HttpClient,
    private readonly router: Router,
    private dataService: DataService,
    private readonly storageService: StorageService,
  ) {
    this.config = config;
    const jwtToken = storageService.getGlobalValue('token');
    this.token = new BehaviorSubject<string>(jwtToken);
    this.token$ = this.token.asObservable();
  }

  public get authTokenValue(): string {
    return this.token.value;
  }

  public get decodedToken(): Token {
    return this.decodeToken(this.token.value);
  }

  login(email: string, password: string): Observable<string> {
    const userDto: User = {
      email,
      password,
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${this.config.coreApiUrl}/${this.config.authenticationEndpoints.login}`, userDto, { headers, responseType: 'text' })
      .pipe(
        map((token) => {
          const decodedToken = this.decodeToken(token);
          const user: User = {
            userId: decodedToken.Id,
            email: decodedToken.Email,
            name: decodedToken.FirstName,
            surname: decodedToken.LastName,
            phoneNumber: decodedToken.PhoneNumber,
            accountType: decodedToken.AccountType,
          };
          this.storageService.storeGlobalValue('token', token);
          this.storageService.storeGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY, decodedToken.OrganizationId);
          this.storageService.storeGlobalValue(CommonDefaults.USER_ID_STORAGE_KEY, decodedToken.Id);
          this.storageService.storeGlobalValue(CommonDefaults.LOGGED_USER, user);
          this.token.next(token);
          return token;
        }),
      );
  }

  isTokenExpired(token?: string): boolean {
    const checkedToken = !token ? this.storageService.getGlobalValue('token') : token;

    if (!checkedToken) {
      return true;
    }

    const date = this.getTokenExpirationDate(checkedToken);
    if (date === null) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date {
    const decodedToken = this.decodeToken(token);

    if (decodedToken.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);
    return date;
  }

  public async logout(redirectToUrl?: string): Promise<void> {
    this.storageService.removeGlobalValue(CommonDefaults.TOKEN_STORAGE_KEY);
    this.storageService.removeGlobalValue(CommonDefaults.CLIENT_ID_STORAGE_KEY);
    this.storageService.removeGlobalValue(CommonDefaults.USER_ID_STORAGE_KEY);
    this.storageService.removeGlobalValue(CommonDefaults.LOGGED_USER);
    this.dataService.setSingleProjectContext(false);
    this.token.next(null);
    await this.router.navigate([redirectToUrl || BasicRoutes.Login]);
  }

  decodeToken(token: string): Token | null {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}
